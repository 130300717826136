import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import LandingAliados from './Pages/LandingAliados/LandingAliados';
import PaquetesPage from './Pages/PaquetesPage/PaquetesPage';
import HomePage from './Pages/HomePage/HomePage';
import RootTemplates from './Pages/RootTemplates/RootTemplates';
import RutaPrivada from './componentes/RutaPrivada';
import Admin from './Pages/Admin/Admin';
import MisNegocios from './Pages/MisNegocios/MisNegocios';
import Afiliarme from './Pages/Afiliarme/Afiliarme';
import ChangePasswordForm from './Pages/ChangePassword/ChangePasswordForm';
import EditarNegocio from './Pages/EditarNegocio/EditarNegocio';
import SiteDocuments from './Pages/SiteDocuments/SiteDocuments';
import RecoverPassword from './Pages/ChangePassword/RecoverPassword/RecoverPassword';
import PrivacyOptions from './Pages/SiteDocuments/PrivacyOptions/PrivacyOptions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { userHeaders } from './Utils/headerSetter';
import { userAuthError, userGetUserSuccess } from './actions/userActions';
import {
  LANGUAGE_IDS,
  PATH_LANGUAGE_MAPPING,
  useCountry,
} from './componentes/Hooks/CountryContext';
import MainPagesSitemap from './Pages/Sitemaps/pages/MainPage';
import CategoriesSitemap from './Pages/Sitemaps/pages/Categories';
import EventTypesSitemap from './Pages/Sitemaps/pages/EventTypes';
import Buscador from './Pages/Buscador/Buscador';
import SelectFilterOptionsMobile from './componentes/SelectFilter/SelectFilterOptionsMobile';
import { useMediaQuery } from '@material-ui/core';

function App() {
  const dispatch = useDispatch();
  const { countryObject, updateCountry } = useCountry();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const filterModalComponent = useSelector((state) => state.components.SelectFilterOptionsMobile);
  const mobile = useMediaQuery('(max-width:960px)');

  useEffect(() => {
    if (window.location.pathname === '/') {
      navigate(PATH_LANGUAGE_MAPPING[countryObject.languageId]);
    } else if (window.location.pathname.includes(PATH_LANGUAGE_MAPPING[LANGUAGE_IDS.SPANISH])) {
      updateCountry({ ...countryObject, languageId: LANGUAGE_IDS.SPANISH });
    } else if (window.location.pathname.includes(PATH_LANGUAGE_MAPPING[LANGUAGE_IDS.ENGLISH])) {
      updateCountry({ ...countryObject, languageId: LANGUAGE_IDS.ENGLISH });
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/get`,
        userHeaders(false)
      )
      .then((res) => {
        const { token, user } = res.data;
        dispatch(userGetUserSuccess(token, user));
      })
      .catch(() => {
        dispatch(userAuthError());
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/es" element={<HomePage />} />
        <Route exact path="/en" element={<HomePage />} />
        <Route exact path="/en/sign-up" element={<HomePage />} />
        <Route exact path="/es/sign-up" element={<HomePage />} />
        <Route exact path="/en/login" element={<HomePage />} />
        <Route exact path="/es/login" element={<HomePage />} />
        <Route exact path="/en/search" element={<Buscador />} />
        <Route exact path="/es/search" element={<Buscador />} />
        <Route exact path="/en/packages" element={<Buscador packagesPage />} />
        <Route exact path="/es/packages" element={<Buscador packagesPage />} />
        <Route exact path="/en/packages/:paqueteId" element={<PaquetesPage />} />
        <Route exact path="/es/packages/:paqueteId" element={<PaquetesPage />} />
        <Route exact path="/en/verify-email" element={<HomePage />} />
        <Route exact path="/es/verify-email" element={<HomePage />} />
        <Route exact path="/en/recover-password" element={<RecoverPassword />} />
        <Route exact path="/es/recover-password" element={<RecoverPassword />} />
        <Route element={<RutaPrivada loading={loading} />}>
          <Route element={<Admin />} path="/en/admin" exact />
          <Route element={<Admin />} path="/es/admin" exact />
          <Route element={<MisNegocios />} path="/en/my-business" exact />
          <Route element={<MisNegocios />} path="/es/my-business" exact />
          <Route element={<EditarNegocio />} path="/en/my-business/:pathName" exact />
          <Route element={<EditarNegocio />} path="/es/my-business/:pathName" exact />
          <Route element={<Afiliarme />} path="/join" exact />
          <Route element={<Afiliarme />} path="/en/join" exact />
          <Route element={<Afiliarme />} path="/es/join" exact />
          <Route element={<ChangePasswordForm />} path="/en/change-password" exact />
          <Route element={<ChangePasswordForm />} path="/es/change-password" exact />
        </Route>
        <Route exact path="/en/information" element={<LandingAliados />} />
        <Route exact path="/es/information" element={<LandingAliados />} />
        <Route exact path="/en/profile/:pathName" element={<RootTemplates />} />
        <Route exact path="/es/profile/:pathName" element={<RootTemplates />} />
        <Route exact path="/en/business/:pathName" element={<RootTemplates />} />
        <Route exact path="/es/business/:pathName" element={<RootTemplates />} />
        <Route path="/en/documents" element={<SiteDocuments />} />
        <Route path="/es/documents" element={<SiteDocuments />} />
        <Route path="/en/privacy-options" element={<PrivacyOptions />} />
        <Route path="/es/privacy-options" element={<PrivacyOptions />} />
        <Route path="/en/sitemaps" element={<MainPagesSitemap />} />
        <Route path="/es/sitemaps" element={<MainPagesSitemap />} />
        <Route path="/en/sitemaps/main-pages" element={<MainPagesSitemap />} />
        <Route path="/es/sitemaps/main-pages" element={<MainPagesSitemap />} />
        <Route path="/en/sitemaps/categories" element={<CategoriesSitemap />} />
        <Route path="/es/sitemaps/categories" element={<CategoriesSitemap />} />
        <Route path="/en/sitemaps/event-types" element={<EventTypesSitemap />} />
        <Route path="/es/sitemaps/event-types" element={<EventTypesSitemap />} />
        <Route
          path="*"
          element={<Navigate to={PATH_LANGUAGE_MAPPING[countryObject.languageId]} replace />}
        />
      </Routes>
      {/*
       * Componentes Globales
       * Debajo de este comentario irán componentes que se pueden usar en todos lados y se controlan por medio del estado global.
       *
       * SelectFilterOptionsMobile: modal en mobile para seleccionar filtros en el explore page.
       */}
      {filterModalComponent?.open && mobile && <SelectFilterOptionsMobile />}
    </>
  );
}

export default App;

