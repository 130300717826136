import React, { useEffect, useState } from 'react';
import { HiOutlineArrowLeft, HiOutlineArrowUp } from 'react-icons/hi';
import { IoIosAddCircleOutline } from 'react-icons/io';

import './PasoCreatePackages.css';
import Packages from '../../Packages/Packages';
import addButton from '../../../Assets/img/buttonAdd3.webp';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ModalCreatePackage from './ModalCreatePackage';
import PropTypes from 'prop-types';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io/index';
import { FiEdit2 } from 'react-icons/fi/index';
import ErrorMsg from '../../ErrorMsg';
import { useTranslation } from 'react-i18next';

export default function PasoCreatePackages({
  aliadoCountry,
  idAliado,
  currentPackages,
  isCollapsible,
  isSelected,
  setIsSelected,
  plan,
}) {
  const { t } = useTranslation('global');
  const [openCreatePackageModal, setOpenCreatePackageModal] = useState(false);
  const [openEditPackageModal, setOpenEditPackageModal] = useState(false);
  const [activeEditPackage, setActiveEditPackage] = useState({});
  const [packages, setPackages] = useState([]);
  const [collapse, setCollapse] = useState(!isSelected);
  const changeArrowUp = useMediaQuery('(max-width:700px)');
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(t('shared.unknownError'));

  const handleCloseCreatePackageModal = () => {
    setOpenCreatePackageModal(false);
  };

  const handleCloseEditPackageModal = () => {
    setOpenEditPackageModal(false);
  };

  const onClickEditPackage = (data) => {
    setActiveEditPackage(data);
    setOpenEditPackageModal(true);
  };

  useEffect(() => {
    if (isCollapsible && !isSelected) setCollapse(true);
  }, [isCollapsible, isSelected]);

  useEffect(() => {
    setPackages(currentPackages);
  }, [currentPackages]);

  let warningMessage = 'packageWarningMsg1';

  const isPackageLimitPlanExceeded = () => {
    switch (plan?.tier) {
      case 'free':
        if (packages.length >= 2) {
          return true;
        }
        break;
      case 'basic':
        if (packages.length >= 2) {
          return true;
        }
        break;

      case 'premium':
        if (packages.length >= 5) {
          return true;
        }
        break;
      case 'pro':
        if (packages.length >= 15) {
          warningMessage = 'packageWarningMsg2';
          return true;
        }
        break;
      default:
        if (packages.length >= 2) {
          return true;
        }
        return false;
    }
  };

  return (
    <>
      {error ? <ErrorMsg setError={setError} errorMsg={errorMsg} /> : null}
      <div className="wrp-create-packages-afil">
        <ModalCreatePackage
          aliadoCountry={aliadoCountry}
          handleClose={handleCloseCreatePackageModal}
          open={openCreatePackageModal || openEditPackageModal}
          setPackages={setPackages}
          idAliado={idAliado}
          isEdition
          modoEdicion={openEditPackageModal}
          handleCloseEdit={handleCloseEditPackageModal}
          activeEditPackage={activeEditPackage}
          packages={packages}
          setActiveEditPackage={setActiveEditPackage}
        />

        <p
          className={'url-txt-instruc-ed-neg'}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (collapse) setIsSelected(1);
            setCollapse(!collapse);
          }}
        >
          {isCollapsible && (
            <span>
              {!collapse ? (
                <IoIosArrowDown style={{ marginRight: '15px', marginBottom: '5px' }} />
              ) : (
                <IoIosArrowForward style={{ marginRight: '15px', marginBottom: '5px' }} />
              )}
            </span>
          )}
          {t('afiliadosSteps.packages.title')}
        </p>

        {(!collapse || !isCollapsible) && (
          <>
            <p className="url-txt-instruc2">{t('afiliadosSteps.packages.description')}</p>

            {packages.length ? (
              <p
                style={{
                  fontSize: '0.9rem',
                  fontWeight: 500,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {t('afiliadosSteps.packages.edit')}
              </p>
            ) : null}
            <div className="wrp-packages-afil-container">
              {isPackageLimitPlanExceeded() ? null : (
                <div
                  className="create-package-button"
                  onClick={() => setOpenCreatePackageModal(true)}
                >
                  <img src={addButton} alt="button" className="create-package-button-img" />
                  <p className="create-package-button-p">{t('afiliadosSteps.packages.add')}</p>
                  <IoIosAddCircleOutline className="create-package-button-icon" />
                </div>
              )}
              {packages.length ? (
                packages.map((item) => (
                  <Packages
                    key={item.id}
                    cardInfo={item}
                    onClickFunction={() => onClickEditPackage(item)}
                    onClickVerMas={() => onClickEditPackage(item)}
                    label={
                      <span>
                        <FiEdit2 style={{ margin: '0 5px 5px 0' }} />
                        {t('afiliadosSteps.packages.edit2')}
                      </span>
                    }
                    kindOfPrice={item?.kindOfPrice}
                    // kindOfPrice={item}
                    t={t}
                  />
                ))
              ) : (
                <div className="create-package-instructions">
                  <p>
                    {changeArrowUp ? (
                      <HiOutlineArrowUp style={{ fontSize: '1.2rem', marginRight: '5px' }} />
                    ) : (
                      <HiOutlineArrowLeft style={{ fontSize: '1.2rem', marginRight: '5px' }} />
                    )}
                    {t('afiliadosSteps.packages.add2')}
                  </p>
                </div>
              )}
            </div>
            <p className="create-package-warning-msg">
              {isPackageLimitPlanExceeded() ? t(`dashboard.${warningMessage}`) : null}
            </p>
          </>
        )}
      </div>
    </>
  );
}

PasoCreatePackages.propTypes = {
  idAliado: PropTypes.number.isRequired,
  setIsSelected: PropTypes.func,
  isCollapsible: PropTypes.bool,
  isSelected: PropTypes.bool,
  currentPackages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      aliado: PropTypes.number,
      price: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      isActive: PropTypes.number,
      allImages: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          idPackage: PropTypes.number,
          image: PropTypes.number,
          isActive: PropTypes.number,
          main: PropTypes.number,
          url: PropTypes.string,
        })
      ),
      mainImage: PropTypes.string,
    })
  ),
};

PasoCreatePackages.defaultProps = {
  setIsSelected: () => null,
  isCollapsible: false,
  isSelected: false,
  currentPackages: [],
};

