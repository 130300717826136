import './ModalContact.css';

import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { FaWhatsapp } from 'react-icons/fa';
import { MdOutlineMailOutline, MdOutlineContentCopy, MdWeb } from 'react-icons/md';
import { FiPhone } from 'react-icons/fi';
import { AiFillYoutube, AiFillInstagram, AiFillFacebook } from 'react-icons/ai';

const ModalContactInnerOption = ({
  paisIdAliado,
  contactType,
  contactData,
  handleWhatsAppContact,
  link = false,
  aliado,
  paquete,
  type,
}) => {
  const { t } = useTranslation('global');
  const mobile = useMediaQuery('(max-width:960px)');
  const [copied, setCopied] = useState('');

  let icon;
  let isWhatsApp = false;
  let countryCode = paisIdAliado === '2' ? '+1' : '+52';
  let hrefLink = contactData;

  if (contactType === 'phoneNumber') {
    icon = <FiPhone style={{ fontSize: '15px' }} />;
  } else if (contactType === 'whatsAppNumber') {
    icon = <FaWhatsapp style={{ fontSize: '15px' }} />;
    isWhatsApp = true;
    if (mobile) {
      link = true;
      hrefLink =
        type === 'package'
          ? handleWhatsAppContact(paisIdAliado, contactData, aliado, paquete)
          : handleWhatsAppContact(paisIdAliado, contactData, true);
    }
  } else if (contactType === 'email') {
    icon = <MdOutlineMailOutline style={{ fontSize: '15px' }} />;
  } else if (contactType === 'webSite') {
    icon = <MdWeb style={{ fontSize: '15px' }} />;
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(contactData);
      setCopied('shared.copied');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }

    setTimeout(() => {
      setCopied('');
    }, 1500);
  };
  return (
    <div class="modal-contact-inner-option">
      <div className="icon-text-container">
        {icon}
        <p className="modal-contact-inner-option-p text-no-wrap-3-dots-overflow">
          {copied ? (
            <span className="modal-contact-inner-option-copied">{t(copied)}</span>
          ) : link ? (
            <a href={hrefLink} target="_blank" rel="noopener noreferrer">
              {`${paisIdAliado ? countryCode : ''} ${contactData}`}
            </a>
          ) : (
            `${paisIdAliado ? countryCode : ''} ${contactData}`
          )}
        </p>
      </div>
      <div className="icon-copy-container ">
        {isWhatsApp && !mobile ? (
          <a
            href={
              type === 'package'
                ? handleWhatsAppContact(paisIdAliado, contactData, aliado, paquete)
                : handleWhatsAppContact(paisIdAliado, contactData, true)
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: '12px',
              marginRight: '10px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            <FaWhatsapp className="modal-cotact-icon-whatsapp" /> {t('temp2.sendWhatsApp')}
          </a>
        ) : null}
        <MdOutlineContentCopy className="modal-cotact-icon-copy" onClick={handleCopy} />
      </div>
    </div>
  );
};

export default function ModalContact({
  handleClose,
  open,
  paisIdAliado,
  aliado,
  phoneNumber,
  whatsAppNumber,
  handleWhatsAppContact,
  email,
  webSite,
  instagram,
  facebook,
  youtube,
  paquete,
  type,
}) {
  const { t } = useTranslation('global');
  const mobile = useMediaQuery('(max-width:960px)');
  const formatUrl = (url) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    return `http://${url}`;
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth={mobile ? 'lg' : 'sm'}
      fullScreen={false}
      style={{ minWidth: '320px' }}
      className="modal-package-wrp-gen"
    >
      <DialogContent dividers style={{ paddingBottom: '2rem' }}>
        <div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: '6px',
              top: '3px',
            }}
            className="modal-reg-titulo"
          >
            <CloseIcon />
          </IconButton>
          <p className="modal-contact-message-title">{aliado}</p>
          <p className="modal-contact-message-subtitle">{t('temp2.planodiMsg')}</p>
        </div>
        <div className="modal-contact-main-options-wrp">
          <div className="modal-contact-inner-options-wrp">
            {phoneNumber && (
              <ModalContactInnerOption
                paisIdAliado={paisIdAliado}
                contactType="phoneNumber"
                contactData={phoneNumber}
              />
            )}
            {whatsAppNumber && (
              <ModalContactInnerOption
                paisIdAliado={paisIdAliado}
                contactType="whatsAppNumber"
                contactData={whatsAppNumber}
                aliado={aliado}
                paquete={paquete}
                handleWhatsAppContact={handleWhatsAppContact}
                type={type}
              />
            )}

            {email && <ModalContactInnerOption contactType="email" contactData={email} />}
            {webSite && (
              <ModalContactInnerOption
                contactType="webSite"
                contactData={formatUrl(webSite)}
                link
              />
            )}
          </div>
          {(instagram || facebook || youtube) && mobile && (
            <>
              <p className="text-center" style={{ fontSize: '14px', margin: '10px 0 15px 0' }}>
                {t('temp2.socialMedia')}
              </p>
              <div className="temp2-sel-box-redes-s-wrp" style={{ margin: '0' }}>
                {!!instagram && (
                  <AiFillInstagram
                    className="temp2-sel-box-redes-s-wrp-icon"
                    onClick={() => {
                      window.open(instagram, '_blank');
                    }}
                  />
                )}
                {!!facebook && (
                  <AiFillFacebook
                    className="temp2-sel-box-redes-s-wrp-icon"
                    onClick={() => {
                      window.open(facebook, '_blank');
                    }}
                  />
                )}
                {!!youtube && (
                  <AiFillYoutube
                    className="temp2-sel-box-redes-s-wrp-icon"
                    onClick={() => {
                      window.open(youtube, '_blank');
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

