import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { userHeaders } from '../../Utils/headerSetter';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

/**
 * Load aliado
 */

export function useLoadAliado(pathName) {
  const { t } = useTranslation('global');
  const stateUser = useSelector((state) => state.user);
  const navigate = useNavigate();

  async function getAliadoData() {
    const res = await axios.get(
      `${
        process.env.REACT_APP_ENV === 'development'
          ? process.env.REACT_APP_API_LOCAL
          : process.env.REACT_APP_API_PROD
      }user/getAliado?pathUrl=${pathName}`,
      userHeaders(false)
    );

    if (!res?.data || !res.data?.aliadoInfoGeneral) {
      toast.error(t('toastCard.readingInfoError'), {
        position: 'bottom-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return null;
    }

    if (res.data.aliadoInfoGeneral.usuario !== stateUser.userInfo.id) {
      navigate(t('routes.myBusiness'));
    } else {
      return res.data;
    }
  }

  return useQuery(['aliadoInfoDashboard', pathName], getAliadoData, {
    refetchOnWindowFocus: false,
  });
}

