import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import img1 from '../../../Assets/img/homepage/eventos/boda_planodi.jpg';
import img2 from '../../../Assets/img/homepage/eventos/fiesta_planodi.jpg';
import img3 from '../../../Assets/img/homepage/eventos/corporativo_planodi.jpg';
import img4 from '../../../Assets/img/homepage/eventos/cumpleanos_planodi.jpg';

import './TipoEventosBlur.css';
import { useTranslation } from 'react-i18next';

export default function TipoEventosBlur() {
  const { t } = useTranslation('global');
  const ref = useRef(null);

  const navigate = useNavigate();

  return (
    <div className="tipo-eventos-blur-wrp">
      <div className="tipo-ev-blur-wrp-cont">
        <div className="tipo-ev-blur-wrp-cont-flex" ref={ref}>
          <div className="tipo-ev-blur-wrp-cont-flex-div">
            <div
              className="tipo-ev-blur-wrp-cont-flex-div2"
              style={{ margin: '0 0.5rem' }}
              onClick={() => {
                navigate(`${t('routes.explore')}?event=birthday`);
              }}
            >
              <div
                style={{ background: `url(${img2}) #FBECB8` }}
                className="tipo-ev-blur-wrp-cont-flex-div2-img2"
              />
              <p className="tipo-ev-blur-wrp-cont-flex-p">{t('events.birthday')}</p>
            </div>
          </div>
          <div className="tipo-ev-blur-wrp-cont-flex-div">
            <div
              className="tipo-ev-blur-wrp-cont-flex-div2"
              style={{ margin: '0 0.5rem' }}
              onClick={() => {
                navigate(`${t('routes.explore')}?event=wedding`);
              }}
            >
              <div
                style={{ background: `url(${img1}) #FBDAC9` }}
                className="tipo-ev-blur-wrp-cont-flex-div2-img2"
              />
              <p className="tipo-ev-blur-wrp-cont-flex-p">{t('events.wedding')}</p>
            </div>
          </div>
          <div className="tipo-ev-blur-wrp-cont-flex-div">
            <div
              className="tipo-ev-blur-wrp-cont-flex-div2"
              style={{ margin: '0 0.5rem' }}
              onClick={() => {
                navigate(`${t('routes.explore')}?event=corporate`);
              }}
            >
              <div
                style={{ background: `url(${img3}) #C9DFBF` }}
                className="tipo-ev-blur-wrp-cont-flex-div2-img2"
              />
              <p className="tipo-ev-blur-wrp-cont-flex-p">{t('events.corporate')}</p>
            </div>
          </div>
          <div className="tipo-ev-blur-wrp-cont-flex-div">
            <div
              className="tipo-ev-blur-wrp-cont-flex-div2"
              style={{ margin: '0 0.5rem' }}
              onClick={() => {
                navigate(`${t('routes.explore')}?event=kidsParty`);
              }}
            >
              <div
                style={{ background: `url(${img4}) #D3B9E8` }}
                className="tipo-ev-blur-wrp-cont-flex-div2-img2"
              />
              <p className="tipo-ev-blur-wrp-cont-flex-p">{t('events.kidsParty')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

