import { createMuiTheme } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';
import grey from '@material-ui/core/colors/grey';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: deepPurple[400],
      main: '#8c50ff',
      dark: deepPurple[800],
      contrastText: '#fff',
    },
    secondary: {
      light: grey[400],
      main: '#3b3b3b',
      dark: grey[900],
      contrastText: '#fff',
    },
  },
});

export default theme;

