import { COMPONENTS_FILTER_MODAL } from './actionTypes';

const componentsShowFilterModal = (data) => {
  return {
    type: COMPONENTS_FILTER_MODAL,
    payload: {
      open: data.open,
      data: data.data,
    },
  };
};

export { componentsShowFilterModal };

