import './BuscadorSection.css';

import React, { useEffect, useState } from 'react';
import foto1 from '../../../Assets/img/homepage/hero1.jpg';
import foto2 from '../../../Assets/img/homepage/hero2.jpg';
import foto3 from '../../../Assets/img/homepage/hero3.jpg';
import foto4 from '../../../Assets/img/homepage/hero4.jpg';
import BuscadorPlanodi from './BuscadorPlanodi';
import { useTranslation } from 'react-i18next';

function BuscadorSection({ locations, categorias, loading }) {
  const { t } = useTranslation('global');
  const images = [foto1, foto2, foto3, foto4];
  const [img, setImg] = useState(null);
  const words = [
    t('buscadorInicio.music'),
    t('buscadorInicio.venues'),
    t('buscadorInicio.desserts'),
    t('buscadorInicio.drinks'),
    t('buscadorInicio.catering'),
    t('buscadorInicio.games'),
    t('buscadorInicio.photo'),
    t('buscadorInicio.decoration'),
    t('buscadorInicio.planner'),
    t('buscadorInicio.furniture'),
    t('buscadorInicio.music'),
  ];

  useEffect(() => {
    setImg(images[Math.floor(Math.random() * 4)]);
  }, []);

  return (
    <div className="bs-sec-wrp">
      <div style={{ flexGrow: 1 }}>
        <p>{t('buscadorInicio.hero1')}</p>
        <div className="bs-sec-words-wrp">
          {words.map((word, index) => (
            <p key={`Word-${index + 1}`}>{word}</p>
          ))}
        </div>
        <p>{t('buscadorInicio.hero2')}</p>
        <div style={{ marginTop: '2rem' }}>
          <BuscadorPlanodi locations={locations} categorias={categorias} loading={loading} />
        </div>
      </div>
      {/* <div className="bs-sec-img-div">
        <div className="bs-sec-img-div-rounded" />
        {img && <img src={img} alt="party" className="bs-sec-img" />}
      </div> */}
      <div
        className="bs-sec-img-div"
        style={{
          backgroundImage: `url('${img}')`,
        }}
      >
        <div className="bs-sec-img-div-rounded" />
      </div>
    </div>
  );
}

export default BuscadorSection;

