import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import './RankingScore.css';

import AdminLayout from '../../../componentes/AdminLayout/AdminLayout';
import { userHeaders } from '../../../Utils/headerSetter';
import { botonesMoradoNegroRojo } from '../../../componentes/Packages/ModalPackage/CreatePagesInPackages';
import RankingPackages from './RankingPackages';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useUpdateScore from './useUpdateScore';

function CustomTabPanel({ children, value, index }) {
  return <div>{value === index && <div>{children}</div>}</div>;
}

export default function RankingScore({ checkIfIsAdmin }) {
  const { t } = useTranslation('global');
  const [aliadoSelected, setAliadoSelected] = useState(null);
  const [scoreInput, setScoreInput] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [tabValue, setTabValue] = useState(0);

  const botonesClasses = botonesMoradoNegroRojo();

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setScoreInput(aliadoSelected?.rankingScore?.toString());
  }, [aliadoSelected]);

  async function getAliadosData() {
    checkIfIsAdmin();
    const res = await axios.get(
      `${
        process.env.REACT_APP_ENV === 'development'
          ? process.env.REACT_APP_API_LOCAL
          : process.env.REACT_APP_API_PROD
      }admin/scoresAliados`,
      userHeaders(false)
    );

    if (!res?.data) {
      toast.error('Parece que hubo un error obteniendo scores!', {
        position: 'bottom-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return null;
    }

    return res.data;
  }

  // React query para actualizar score sin necesidad de actualizar pagina, por medio de optimisitic update.
  const { data: scoresAliados, isLoading } = useQuery('rankingAliados', getAliadosData, {
    refetchOnWindowFocus: false,
  });
  const { mutate: updateScore, isLoading: updateScoreLoading } = useUpdateScore('rankingAliados');

  return (
    <>
      <div className="admin-layout-div1" style={{ margin: '1rem 0' }}>
        <div className="admin-layout-div2">
          <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Aliados" />
            <Tab label="Paquetes" />
          </Tabs>
        </div>
      </div>
      <CustomTabPanel value={tabValue} index={0}>
        <AdminLayout loading={isLoading || updateScoreLoading}>
          <div className="admin-rank-div1">
            <p>
              En esta página se puede modificar el score del aliado, lo cual impacta directamente a
              su posición en los resultados.
            </p>
            <Autocomplete
              value={aliadoSelected}
              onChange={(event, value) => {
                setAliadoSelected(value);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={scoresAliados}
              getOptionLabel={(option) => option?.pathUrl}
              style={{ width: '300px', marginBottom: '0.5rem' }}
              renderInput={(params) => (
                <TextField {...params} label="Username de aliado" variant="outlined" />
              )}
              getOptionSelected={(option, value) => option.id === value.id}
            />
            <TextField
              variant="outlined"
              value={scoreInput}
              onChange={(e) => setScoreInput(e.target.value)}
              disabled={!aliadoSelected}
              style={{ width: '140px' }}
            />
            <Button
              className={botonesClasses.buttonPurp}
              style={{ width: '140px', marginLeft: '20px' }}
              disabled={!aliadoSelected}
              onClick={() =>
                updateScore({
                  packageId: aliadoSelected.id,
                  newScore: parseInt(scoreInput),
                  isProfile: true,
                })
              }
            >
              Actualizar
            </Button>
          </div>
          {scoresAliados ? (
            <table className="admin-rank-table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Nombre</th>
                  <th>Username</th>
                  <th>Score</th>
                  <th>Top</th>
                </tr>
              </thead>
              <tbody>
                {scoresAliados.map((item, idx) => (
                  <tr key={item.id} onClick={() => setAliadoSelected(item)}>
                    <td>{item.id}</td>
                    <td>{item.nombre}</td>
                    <td>
                      <a
                        href={`${window.location.origin}${t('routes.profile')}/${item.pathUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.pathUrl}
                      </a>
                    </td>
                    <td>{item.rankingScore}</td>
                    <td>{idx + 1}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </AdminLayout>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <RankingPackages checkIfIsAdmin={checkIfIsAdmin} />
      </CustomTabPanel>
    </>
  );
}

