import './BuscadorFiltros.css';

import { useTranslation } from 'react-i18next';
import PlanodiIcons from '../../../Utils/PlanodiIcons';

import React, { useEffect, useRef, useState } from 'react';
import SelectFilter from '../../../componentes/SelectFilter/SelectFilter';
import { useBuscadorState } from '../BuscadorContext';
import { useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

function BuscadorFiltros({ tiposEventos, categorias, locations }) {
  const { t } = useTranslation('global');
  const inputRef = useRef(null);
  const { updateFilter, locationFilter, freeFilter, categoryFilter, eventFilter, cleanFilters } =
    useBuscadorState();
  const [input, setInput] = useState(freeFilter);
  const [buscadorFocused, setBuscadorFocused] = useState(false);
  const mobile = useMediaQuery('(max-width:450px)');

  const onSubmitSelect = (filterType, newState) => {
    updateFilter(filterType, newState);
  };

  /**
   * Aqui se revisan los filtros del url y se quitan aquellos que no existen,
   * probablemnte insertados manualmente por un usuario.
   */
  useEffect(() => {
    cleanFilters(categorias, tiposEventos, locations);
  }, []);

  return (
    <div className="bus-fil-wrp">
      <div className="bus-fil-filtros-wrp-1">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (mobile && !buscadorFocused) {
              setBuscadorFocused(true);
              inputRef.current.focus();
              return;
            }
            onSubmitSelect('free', input);
            if (mobile && buscadorFocused && input === '') {
              setBuscadorFocused(false);
            }
            inputRef.current.blur();
          }}
          className={clsx('bus-fil-input-wrp', {
            open: buscadorFocused,
          })}
        >
          {freeFilter.length ? (
            <div
              className="bus-fil-search-icon"
              onClick={() => {
                setInput('');
                if (mobile) {
                  setBuscadorFocused(false);
                }
                inputRef.current.blur();
                onSubmitSelect('free', '');
              }}
              style={{ width: 'auto', marginLeft: '10px' }}
            >
              <PlanodiIcons name="close" style={{ fontSize: '1rem', opacity: 0.8 }} />
            </div>
          ) : null}
          <input
            className={clsx('bus-fil-input', {
              open: buscadorFocused,
            })}
            type="text"
            autoComplete="off"
            placeholder={t('explore.search')}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            ref={inputRef}
            onBlur={() => {
              if (mobile && buscadorFocused && input === '' && freeFilter.length === 0) {
                setBuscadorFocused(false);
              }
            }}
          />
          <button type="submit" className="bus-fil-search-icon">
            <PlanodiIcons name="search" style={{ fontSize: '1.5rem' }} />
          </button>
        </form>
        <SelectFilter
          title={
            locationFilter.length
              ? locations.find((i) => i.id === locationFilter[0])?.location
              : t('explore.filters.location')
          }
          items={locations}
          withBorder={false}
          leftIcon="location"
          darkMode
          toggleArrow={false}
          sharpEdges
          textFromTranslationKey={false}
          textKey="location"
          setOnGlobalState={(state) => onSubmitSelect('location', state)}
          lastState={locationFilter}
          hideText={buscadorFocused && mobile}
          fullWidth={!buscadorFocused && mobile}
        />
      </div>

      <div className="bus-fil-filtros-wrp">
        <div className="bus-fil-divider-filtros" />
        <SelectFilter
          title={t('explore.filters.categories')}
          items={categorias}
          sharpEdges
          multipleSelect
          textFromTranslationKey={true}
          setOnGlobalState={(state) => onSubmitSelect('category', state)}
          lastState={categoryFilter}
          showSelectedElementsNumber
          widthOptionsDesktop={3}
        />
        <SelectFilter
          title={t('explore.filters.eventTypes')}
          items={tiposEventos}
          sharpEdges
          multipleSelect
          textFromTranslationKey={true}
          setOnGlobalState={(state) => onSubmitSelect('event', state)}
          lastState={eventFilter}
          showSelectedElementsNumber
          widthOptionsDesktop={2}
        />
      </div>
    </div>
  );
}

export default BuscadorFiltros;

