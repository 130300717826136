import { COMPONENTS_FILTER_MODAL } from '../actions/actionTypes';

const initialState = {
  SelectFilterOptionsMobile: {
    open: false,
    data: {},
  },
};

export const componentsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COMPONENTS_FILTER_MODAL:
      return {
        SelectFilterOptionsMobile: {
          open: payload.open,
          data: payload.data,
        },
      };
    default:
      return state;
  }
};

