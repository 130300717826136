import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../../Pages/HomePage/Footer/Footer';
import Navbar from '../../Pages/HomePage/Navbar/Navbar';
import { getUrl } from '../../Utils/url';
import './SiteMap.css';
import { useMediaQuery } from '@material-ui/core';

const sections = [
  { sectionName: 'mainPages', mainUrl: '/main-pages' },
  { sectionName: 'categories', mainUrl: '/categories' },
  {
    sectionName: 'eventsTypes',
    mainUrl: '/event-types',
  },
];

const SiteMapOption = ({ t, name, route }) => {
  const mobile = useMediaQuery('(max-width:960px)');
  const target = mobile ? '_self' : '_blank';

  return (
    <div className="sitemap-option">
      <a
        className="sitemap-option-link"
        target={target}
        href={getUrl(t, route)}
        rel="noopener noreferrer"
      >
        {t(`siteMaps.${name}`)}
      </a>
    </div>
  );
};

export const SiteMaps = ({ title, content }) => {
  const { t } = useTranslation('global');

  document.title = 'Planodi | Sitemap';

  return (
    <div>
      <Navbar />
      <div className="sitemap-main-wrp ">
        <div className="sitemap-section-wrp">
          {sections.map((item, index) => (
            <div
              key={`sitemap-section-${index + 1}`}
              className={
                item.sectionName === title
                  ? 'sitemap-section-title-active'
                  : 'sitemap-section-title-inactive'
              }
            >
              <Link
                to={{ pathname: `${t('routes.sitemaps')}${item.mainUrl}` }}
                style={{
                  textDecoration: 'none',
                  color: item.sectionName === title ? 'black' : 'gray',
                }}
              >
                {t(`siteMaps.${item.sectionName}`)}
              </Link>
            </div>
          ))}
        </div>

        {title && (
          <div className="sitemap-option-wrp">
            <div className="sitemap-option-title-wrp">
              <p>Top {t(`siteMaps.${title}`)}</p>
            </div>
            <div className="sitemap-option-flex-wrp">
              {content.map((option, index) => (
                <SiteMapOption
                  t={t}
                  key={`sitemap-option-${index + 1}`}
                  name={option.name}
                  route={option.route}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

