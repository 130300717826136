import React, { useEffect, useState } from 'react';
import ErrorMsg from '../ErrorMsg';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from '@material-ui/core';
import { IoIosArrowDown, IoIosArrowForward, IoIosUndo } from 'react-icons/io';
import AddCardIcon from '@mui/icons-material/AddCard';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import './ManageSubscription.css';
import axios from 'axios';
import { userHeaders } from '../../Utils/headerSetter';
import moment, { locale } from 'moment';
import { Alert } from '@mui/material';
import useCancelPlan from '../../Pages/EditarNegocio/useCancelPlan';
import useUpgradePlan from '../../Pages/EditarNegocio/useUpgradePlan';
import ManageSubPlans from './ManageSubPlans';
import Loading from '../Loading/Loading';
import { useTranslation } from 'react-i18next';
import { useCountry } from '../Hooks/CountryContext';

const suscriptionModal = (
  t,
  mobile,
  openModal,
  closeModal,
  title,
  firstDescription,
  secondDescription,
  proccedTitle,
  action,
  important = false
) => {
  return (
    <Dialog
      open={openModal}
      onClose={() => closeModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t(title)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p
            dangerouslySetInnerHTML={{
              __html: t(firstDescription).replace(
                /<b>(.*?)<\/b>/g,
                '<b style="color: var(--planodi-purple);">$1</b>'
              ),
            }}
          />
        </DialogContentText>
        <DialogContentText id="alert-dialog-description" style={{ fontSize: '0.7rem' }}>
          {secondDescription ? `* ${t(secondDescription)}` : null}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={
          mobile
            ? {
                marginBottom: '10px',
              }
            : null
        }
      >
        <Button onClick={() => closeModal(false)} style={{ color: '#636363' }}>
          {t('shared.close')}
        </Button>
        <Button
          onClick={action}
          variant="contained"
          style={{
            ...(important && { backgroundColor: 'var(--planodi-purple)', color: 'white' }),
            ...(mobile && {
              fontSize: '12px',
              padding: '8px 10px',
            }),
          }}
        >
          {t(proccedTitle)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function ManageSubscription({
  isCollapsible,
  isSelected,
  setIsSelected,
  planType,
  dataPrincipal,
  username,
  stripeId,
  plan,
  newPlan,
  precios,
}) {
  const { getCurrentLanguagePath } = useCountry();
  const { t, i18n } = useTranslation('global');
  const [continuePlanScreen, setContinuePlanScreen] = useState(
    plan?.cancelAtPeriodEnd && plan?.status === 'active'
  );
  const [collapse, setCollapse] = useState(!isSelected);
  const [error, setError] = useState(false);
  const [loadingMngSubsBtn, setLoadingMngSubsBtn] = useState(false);
  const [cancelScreen, setCancelScreen] = useState(false);
  const [loadingPortalUrl, setLoadingPortalUrl] = useState(false);
  const [openConfModal, setOpenConfModal] = useState(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [newTier, setNewTier] = useState('');
  const [errorMsg, setErrorMsg] = useState(t('shared.unknownError'));

  const mobile = useMediaQuery('(max-width:960px)');

  const { mutate: cancelSub } = useCancelPlan();
  const { mutate: upgradePlan, isLoading: updatePlanLoading } = useUpgradePlan(username);

  moment.locale(i18n.language);

  useEffect(() => {
    setErrorMsg(t('shared.unknownError'));
  }, [i18n.language]);

  const cambiarMetodo = async () => {
    setLoadingMngSubsBtn(true);
    axios
      .get(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }stripe/get-customer-portal-url?customerId=${stripeId}&username=${username}&redirectPathLanguage=${getCurrentLanguagePath()}`,
        userHeaders(false)
      )
      .then((res) => {
        const { url } = res.data;
        window.location.replace(`${url}/payment-methods`);
      })
      .catch((e) => {
        setLoadingMngSubsBtn(false);
      });
  };

  const handleStopSub = async () => {
    setOpenConfModal(false);
    cancelSub({
      stripeId,
      stop: true,
      pathName: username,
    });
    setCancelScreen(true);
  };

  const handleResumeSub = async (removeSuccessMsg = false) => {
    cancelSub({
      stripeId,
      stop: false,
      pathName: username,
    });
    if (removeSuccessMsg) {
      setCancelScreen(false);
    } else {
      setContinuePlanScreen(false);
    }
  };

  const handleUpdatePlan = async (newTierParam) => {
    if (!stripeId && newTierParam) {
      setLoadingPortalUrl(true);
      axios
        .post(
          `${
            process.env.REACT_APP_ENV === 'development'
              ? process.env.REACT_APP_API_LOCAL
              : process.env.REACT_APP_API_PROD
          }stripe/create-checkout-session`,
          {
            tier: newTierParam,
            username,
            country: dataPrincipal.paisId,
            redirectPathLanguage: getCurrentLanguagePath(),
          },
          userHeaders()
        )
        .then((res) => {
          const { url } = res.data;
          window.location.replace(url);
        })
        .catch((e) => {});
    } else {
      upgradePlan({
        stripeId,
        newTier,
        country: dataPrincipal.paisId,
      });
      setOpenUpgradeModal(false);
    }
  };

  const Component = () => (
    <div style={{ position: 'relative' }}>
      {(updatePlanLoading || loadingPortalUrl) && (
        <div
          style={{
            position: 'absolute',
            background: 'rgba(255,255,255,0.7)',
            width: '100%',
            height: '100%',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              pointerEvents: 'none ',
            }}
          >
            <Loading />
          </div>
        </div>
      )}
      {mobile ? null : (
        <p className="edit-extras_titulo">{t('dashboard.paymentsAndSubscriptions')}</p>
      )}
      {/* Si es VIP y no tiene ningún plan mejor contratado, mostrar esto mensaje de agradecimiento junto con su plan actual */}
      {!stripeId && dataPrincipal.vip && (
        <>
          <Alert severity="info" style={{ margin: '1rem 0' }}>
            {t('dashboard.thanks')}
          </Alert>
          <p className="manage-sub-p">
            <span className="manage-sub-title">{t('dashboard.actualPlan')}:</span>{' '}
            {t(`afiliadosSteps.paymentPlan.${planType}.title`)}
          </p>
        </>
      )}
      {/* Mostrar Opciones de cancelacion y cambio de forma de pago solo si tiene un stripe ID */}
      {stripeId && (
        <>
          <p className="manage-sub-p">
            <span className="manage-sub-title">{t('dashboard.actualPlan')}:</span>{' '}
            {t(`afiliadosSteps.paymentPlan.${planType}.title`)}
          </p>
          {plan?.subscriptionEnd && (
            <p className="manage-sub-p">
              <span className="manage-sub-title">{t('shared.until')}:</span>{' '}
              {moment(plan.subscriptionEnd).format('DD MMMM, YYYY')}
            </p>
          )}
          {cancelScreen && (
            <Alert severity="success" style={{ marginTop: '1rem' }}>
              {t('dashboard.cancelSuccess1')} (
              {moment(plan.subscriptionEnd).format('DD MMMM, YYYY')}).{' '}
              {t('dashboard.cancelSuccess2')}{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => handleResumeSub(true)}
              >
                {t('dashboard.cancelSuccess3')}
              </span>
            </Alert>
          )}

          {planType !== 'free' && !cancelScreen && !continuePlanScreen && (
            <>
              <Button
                variant="outlined"
                style={{ margin: '20px 20px 20px 0' }}
                startIcon={<AddCardIcon />}
                onClick={cambiarMetodo}
                disabled={loadingMngSubsBtn}
              >
                {loadingMngSubsBtn ? (
                  <CircularProgress size={24} style={{ margin: '0 70px' }} />
                ) : (
                  t('dashboard.changePaymentMethod')
                )}
              </Button>
              <Button
                variant="outlined"
                style={mobile ? { margin: '10px 0' } : { margin: '20px 0' }}
                startIcon={<RemoveCircleIcon />}
                onClick={() => setOpenConfModal(true)}
              >
                {t('dashboard.cancelSubscription')}
              </Button>
            </>
          )}
          {continuePlanScreen && (
            <div style={{ marginTop: '1rem' }}>
              <p>{t('dashboard.cancelLegend')}</p>
              <Button
                variant="outlined"
                style={{ margin: '10px 0' }}
                startIcon={<IoIosUndo />}
                onClick={() => {
                  handleResumeSub();
                }}
              >
                {t('dashboard.resumeSub')}
              </Button>
            </div>
          )}
        </>
      )}

      {planType !== 'pro' && !cancelScreen && !continuePlanScreen && (
        <ManageSubPlans
          handleUpdatePlan={(newTier) => {
            if (stripeId) {
              setOpenUpgradeModal(true);
              setNewTier(newTier);
            } else {
              handleUpdatePlan(newTier);
            }
          }}
          showBasico={planType === 'free'}
          showPremium={planType === 'basic' || planType === 'free'}
          showPro={true}
          precios={precios}
        />
      )}
    </div>
  );

  if (newPlan)
    return (
      <Alert severity="info" style={{ marginTop: '0.5rem' }}>
        {t('dashboard.waitForUpdate')}
      </Alert>
    );

  return (
    <>
      {error ? <ErrorMsg setError={setError} errorMsg={errorMsg} /> : null}
      {mobile ? (
        <>
          <p
            className="edit-extras_titulo"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (collapse) setIsSelected(10);
              setCollapse(!collapse);
            }}
          >
            {isCollapsible && (
              <span>
                {!collapse ? (
                  <IoIosArrowDown style={{ marginRight: '15px', marginBottom: '5px' }} />
                ) : (
                  <IoIosArrowForward style={{ marginRight: '15px', marginBottom: '5px' }} />
                )}
              </span>
            )}
            {t('dashboard.paymentsAndSubscriptions')}
          </p>

          {(!collapse || !isCollapsible) && <Component />}
        </>
      ) : (
        <Component />
      )}

      {suscriptionModal(
        t,
        mobile,
        openConfModal,
        setOpenConfModal,
        'dashboard.cancelSubscription',
        'dashboard.cancelSubscriptionDescription',
        'dashboard.cancelSubscriptionDescription2',
        'shared.continueCancelation',
        handleStopSub
      )}

      {suscriptionModal(
        t,
        mobile,
        openUpgradeModal,
        setOpenUpgradeModal,
        'dashboard.upgradeSubscription',
        'dashboard.upgradeSubscriptionDescription',
        'dashboard.upgradeSubscriptionDescription2',
        'dashboard.upgrade',
        () => handleUpdatePlan(),
        true
      )}
    </>
  );
}

