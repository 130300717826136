import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Formik } from 'formik';
import { Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

import './PasoFAQ.css';
import { botonesAtrasYAdelante } from '../PasoForm';
import axios from 'axios';
import { userHeaders } from '../../../Utils/headerSetter';
import Loading from '../../Loading/Loading';
import ErrorMsg from '../../ErrorMsg';
import Grid from '@material-ui/core/Grid';
import MyLongText from '../../formikInputs/MyLongText/MyLongText';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const validationSchemaTerraza = (t) =>
  Yup.object({
    q1: Yup.string().max(190, t('yup.maxLength', { amount: 190 })),
    q2: Yup.string().max(190, t('yup.maxLength', { amount: 190 })),
    q3: Yup.string().max(190, t('yup.maxLength', { amount: 190 })),
    q4: Yup.string().max(190, t('yup.maxLength', { amount: 190 })),
    q5: Yup.string().max(190, t('yup.maxLength', { amount: 190 })),
    q6: Yup.string().max(190, t('yup.maxLength', { amount: 190 })),
  });

const validationSchemaProveedor = (t) =>
  Yup.object({
    q1: Yup.string().max(190, t('yup.maxLength', { amount: 190 })),
    q2: Yup.string().max(190, t('yup.maxLength', { amount: 190 })),
    q3: Yup.string().max(190, t('yup.maxLength', { amount: 190 })),
    q4: Yup.string().max(190, t('yup.maxLength', { amount: 190 })),
    q5: Yup.string().max(190, t('yup.maxLength', { amount: 190 })),
    q6: Yup.string().max(190, t('yup.maxLength', { amount: 190 })),
  });

export default function PasoFAQ({
  isTerraza,
  setStep,
  idAliado,
  dataSecundaria,
  isEdition,
  isCollapsible,
  isMainPage,
  isSelected,
  setIsSelected,
}) {
  const { t } = useTranslation('global');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(t('shared.unknownError'));
  const [collapse, setCollapse] = useState(!isSelected);

  useEffect(() => {
    if (isCollapsible && !isSelected) setCollapse(true);
  }, [isCollapsible, isSelected]);

  const initialValuesTerraza = {
    q1: isEdition && dataSecundaria?.q1 ? dataSecundaria.q1 : '',
    q2: isEdition && dataSecundaria?.q2 ? dataSecundaria.q2 : '',
    q3: isEdition && dataSecundaria?.q3 ? dataSecundaria.q3 : '',
    q4: isEdition && dataSecundaria?.q4 ? dataSecundaria.q4 : '',
    q5: isEdition && dataSecundaria?.q5 ? dataSecundaria.q5 : '',
    q6: isEdition && dataSecundaria?.q6 ? dataSecundaria.q6 : '',
  };

  const initialValuesProveedor = {
    q1: isEdition && dataSecundaria?.q1 ? dataSecundaria.q1 : '',
    q2: isEdition && dataSecundaria?.q2 ? dataSecundaria.q2 : '',
    q3: isEdition && dataSecundaria?.q3 ? dataSecundaria.q3 : '',
    q4: isEdition && dataSecundaria?.q4 ? dataSecundaria.q4 : '',
    q5: isEdition && dataSecundaria?.q5 ? dataSecundaria.q5 : '',
    q6: isEdition && dataSecundaria?.q6 ? dataSecundaria.q6 : '',
  };

  const buttonTypes = botonesAtrasYAdelante();
  const mobile = useMediaQuery('(max-width:960px)');

  const onSubmitTerraza = ({ q1, q2, q3, q4, q5, q6 }) => {
    setLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/guardarFAQTerraza`,
        {
          idAliado,
          q1,
          q2,
          q3,
          q4,
          q5,
          q6,
          isEdition,
        },
        userHeaders()
      )
      .then(() => {
        setLoading(false);
        setStep(+1);
      })
      .catch((e) => {
        const { response } = e;
        if (response && response.data && response.data.msg) {
          if (response.data.msg) setErrorMsg(response.data.msg);
        }
        setError(true);
        setLoading(false);
      });
  };

  const onSubmitProveedor = ({ q1, q2, q3, q4, q5, q6 }) => {
    setLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/guardarFAQProveedor`,
        {
          idAliado,
          q1,
          q2,
          q3,
          q4,
          q5,
          q6,
          isEdition,
        },
        userHeaders()
      )
      .then(() => {
        setLoading(false);
        setStep(+1);
      })
      .catch((e) => {
        const { response } = e;
        if (response && response.data && response.data.msg) {
          if (response.data.msg) setErrorMsg(response.data.msg);
        }
        setError(true);
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        marginBottom: '1rem',
      }}
    >
      <p
        className={isEdition ? 'url-txt-instruc-ed-neg' : 'url-txt-instruc'}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (collapse) setIsSelected(6);
          setCollapse(!collapse);
        }}
      >
        {isCollapsible && (
          <span>
            {!collapse ? (
              <IoIosArrowDown style={{ marginRight: '15px', marginBottom: '5px' }} />
            ) : (
              <IoIosArrowForward style={{ marginRight: '15px', marginBottom: '5px' }} />
            )}
          </span>
        )}
        {t('afiliadosSteps.faq.title')}
      </p>

      {(!collapse || !isCollapsible) && (
        <>
          <p
            className="url-txt-instruc2"
            style={isCollapsible ? { textAlign: 'left' } : { marginLeft: '10px' }}
          >
            {t('afiliadosSteps.faq.description')}
          </p>
          {loading ? (
            <div
              style={{
                height: '400px',
                position: 'relative',
                width: '100%',
              }}
            >
              <Loading helperText={t('shared.loading')} />
            </div>
          ) : (
            <div>
              <Formik
                initialValues={isTerraza ? initialValuesTerraza : initialValuesProveedor}
                onSubmit={isTerraza ? onSubmitTerraza : onSubmitProveedor}
                validationSchema={
                  isTerraza ? validationSchemaTerraza(t) : validationSchemaProveedor(t)
                }
              >
                <FormikForm style={{ width: '100%' }}>
                  {error ? <ErrorMsg setError={setError} errorMsg={errorMsg} /> : null}
                  {isTerraza ? (
                    <div style={{ marginTop: '2rem' }}>
                      <Grid style={mobile ? { marginTop: '0.3rem' } : { padding: '1rem 0 0 1rem' }}>
                        <Grid item xs={12}>
                          <p className="afil-faq-pregunta-p">
                            {t('afiliadosSteps.faq.q1')}{' '}
                            <span style={{ color: '#a9a9a9', fontSize: '0.7rem' }}>
                              {t('shared.optional')}
                            </span>
                          </p>
                          <div>
                            <MyLongText
                              name="q1"
                              rows={mobile ? 3 : 2}
                              style={{ width: '100%', marginTop: '0.5rem' }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid style={mobile ? { marginTop: '1rem' } : { padding: '1rem 0 0 1rem' }}>
                        <Grid item xs={12}>
                          <p className="afil-faq-pregunta-p">
                            {t('afiliadosSteps.faq.q2')}{' '}
                            <span style={{ color: '#a9a9a9', fontSize: '0.7rem' }}>
                              {t('shared.optional')}
                            </span>
                          </p>
                          <div>
                            <MyLongText
                              name="q2"
                              rows={mobile ? 3 : 2}
                              style={{ width: '100%', marginTop: '0.5rem' }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid style={mobile ? { marginTop: '1rem' } : { padding: '1rem 0 0 1rem' }}>
                        <Grid item xs={12}>
                          <p className="afil-faq-pregunta-p">
                            {t('afiliadosSteps.faq.q3')}{' '}
                            <span style={{ color: '#a9a9a9', fontSize: '0.7rem' }}>
                              {t('shared.optional')}
                            </span>
                          </p>
                          <div>
                            <MyLongText
                              name="q3"
                              rows={mobile ? 3 : 2}
                              style={{ width: '100%', marginTop: '0.5rem' }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid style={mobile ? { marginTop: '1rem' } : { padding: '1rem 0 0 1rem' }}>
                        <Grid item xs={12}>
                          <p className="afil-faq-pregunta-p">
                            {t('afiliadosSteps.faq.q4')}{' '}
                            <span style={{ color: '#a9a9a9', fontSize: '0.7rem' }}>
                              {t('shared.optional')}
                            </span>
                          </p>
                          <div>
                            <MyLongText
                              name="q4"
                              rows={mobile ? 3 : 2}
                              style={{ width: '100%', marginTop: '0.5rem' }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid style={mobile ? { marginTop: '1rem' } : { padding: '1rem 0 0 1rem' }}>
                        <Grid item xs={12}>
                          <p className="afil-faq-pregunta-p">
                            {t('afiliadosSteps.faq.q5')}{' '}
                            <span style={{ color: '#a9a9a9', fontSize: '0.7rem' }}>
                              {t('shared.optional')}
                            </span>
                          </p>
                          <div>
                            <MyLongText
                              name="q5"
                              rows={mobile ? 3 : 2}
                              style={{ width: '100%', marginTop: '0.5rem' }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid style={mobile ? { marginTop: '1rem' } : { padding: '1rem 0 0 1rem' }}>
                        <Grid item xs={12}>
                          <p className="afil-faq-pregunta-p">
                            {t('afiliadosSteps.faq.q6')}{' '}
                            <span style={{ color: '#a9a9a9', fontSize: '0.7rem' }}>
                              {t('shared.optional')}
                            </span>
                          </p>
                          <div>
                            <MyLongText
                              name="q6"
                              rows={mobile ? 3 : 2}
                              style={{ width: '100%', marginTop: '0.5rem' }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <div style={{ marginTop: '2rem' }}>
                      <Grid
                        style={mobile ? { marginTop: '0.3rem' } : { padding: '0.3rem 0 0 1rem' }}
                      >
                        <Grid item xs={12}>
                          <p className="afil-faq-pregunta-p">
                            {t('afiliadosSteps.faq.proveedor.q1')}{' '}
                            <span style={{ color: '#a9a9a9', fontSize: '0.7rem' }}>
                              {t('shared.optional')}
                            </span>
                          </p>
                          <div>
                            <MyLongText
                              name="q1"
                              rows={mobile ? 3 : 2}
                              style={{ width: '100%', marginTop: '0.5rem' }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid style={mobile ? { marginTop: '1rem' } : { padding: '1rem 0 0 1rem' }}>
                        <Grid item xs={12}>
                          <p className="afil-faq-pregunta-p">
                            {t('afiliadosSteps.faq.proveedor.q2')}{' '}
                            <span style={{ color: '#a9a9a9', fontSize: '0.7rem' }}>
                              {t('shared.optional')}
                            </span>
                          </p>
                          <div>
                            <MyLongText
                              name="q2"
                              rows={mobile ? 3 : 2}
                              style={{ width: '100%', marginTop: '0.5rem' }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid style={mobile ? { marginTop: '1rem' } : { padding: '1rem 0 0 1rem' }}>
                        <Grid item xs={12}>
                          <p className="afil-faq-pregunta-p">
                            {t('afiliadosSteps.faq.proveedor.q3')}{' '}
                            <span style={{ color: '#a9a9a9', fontSize: '0.7rem' }}>
                              {t('shared.optional')}
                            </span>
                          </p>
                          <div>
                            <MyLongText
                              name="q3"
                              rows={mobile ? 3 : 2}
                              style={{ width: '100%', marginTop: '0.5rem' }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid style={mobile ? { marginTop: '1rem' } : { padding: '1rem 0 0 1rem' }}>
                        <Grid item xs={12}>
                          <p className="afil-faq-pregunta-p">
                            {t('afiliadosSteps.faq.proveedor.q4')}{' '}
                            <span style={{ color: '#a9a9a9', fontSize: '0.7rem' }}>
                              {t('shared.optional')}
                            </span>
                          </p>
                          <div>
                            <MyLongText
                              name="q4"
                              rows={mobile ? 3 : 2}
                              style={{ width: '100%', marginTop: '0.5rem' }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid style={mobile ? { marginTop: '1rem' } : { padding: '1rem 0 0 1rem' }}>
                        <Grid item xs={12}>
                          <p className="afil-faq-pregunta-p">
                            {t('afiliadosSteps.faq.proveedor.q5')}{' '}
                            <span style={{ color: '#a9a9a9', fontSize: '0.7rem' }}>
                              {t('shared.optional')}
                            </span>
                          </p>
                          <div>
                            <MyLongText
                              name="q5"
                              rows={mobile ? 3 : 2}
                              style={{ width: '100%', marginTop: '0.5rem' }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {isEdition ? (
                    <div
                      style={
                        mobile
                          ? {
                              width: '100%',
                              textAlign: 'center',
                              marginTop: '1.5rem',
                            }
                          : {
                              width: '100%',
                              textAlign: 'right',
                              marginTop: '1.5rem',
                            }
                      }
                    >
                      <Button
                        className={buttonTypes.cancelButton}
                        style={mobile ? { width: '100%' } : { width: '30%' }}
                        onClick={() =>
                          isMainPage ? setStep(-1) : navigate(t('routes.myBusiness'))
                        }
                      >
                        {isMainPage ? t('shared.back') : t('shared.cancel')}
                      </Button>
                      <Button
                        type="submit"
                        className={buttonTypes.saveButton}
                        style={
                          mobile
                            ? { width: '100%', marginTop: '1.5rem' }
                            : { width: '30%', marginLeft: '1.5rem' }
                        }
                      >
                        {isMainPage ? t('shared.saveAndContinue') : t('shared.save')}
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        marginTop: '1rem',
                      }}
                    >
                      <Button
                        className={buttonTypes.cancelButton}
                        style={
                          mobile
                            ? {
                                width: '100%',
                                marginTop: '1.5rem',
                              }
                            : {
                                width: '30%',
                                marginRight: '1rem',
                                marginTop: '1.5rem',
                              }
                        }
                        onClick={() => setStep(-1)}
                      >
                        {t('shared.back')}
                      </Button>

                      <Button
                        type="submit"
                        className={buttonTypes.button}
                        style={
                          mobile
                            ? { width: '100%', marginTop: '1rem' }
                            : { width: '30%', marginTop: '1.5rem' }
                        }
                      >
                        {t('shared.continue')}
                      </Button>
                    </div>
                  )}
                </FormikForm>
              </Formik>
            </div>
          )}
        </>
      )}
    </div>
  );
}

PasoFAQ.propTypes = {
  setStep: PropTypes.func.isRequired,
  idAliado: PropTypes.number.isRequired,
  isTerraza: PropTypes.bool.isRequired,
  setIsSelected: PropTypes.func,
  dataSecundaria: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      aliado: PropTypes.number,
      capacidad: PropTypes.string,
      descripcion: PropTypes.string,
      servicios: PropTypes.string,
      espacios: PropTypes.string,
      direccion: PropTypes.string,
      alcohol: PropTypes.number,
      eventosDia: PropTypes.number,
      eventosNoche: PropTypes.number,
      estacionamiento: PropTypes.number,
      alberca: PropTypes.number,
      q1: PropTypes.string,
      q2: PropTypes.string,
      q3: PropTypes.string,
      q4: PropTypes.string,
      q5: PropTypes.string,
      q6: PropTypes.string,
    }),
    PropTypes.shape({
      id: PropTypes.number,
      aliado: PropTypes.number,
      descripcion: PropTypes.string,
      servicios: PropTypes.string,
      experiencia: PropTypes.string,
      dondeOfrecen: PropTypes.string,
      q1: PropTypes.string,
      q2: PropTypes.string,
      q3: PropTypes.string,
      q4: PropTypes.string,
      q5: PropTypes.string,
      q6: PropTypes.string,
    }),
  ]),
  isEdition: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  isMainPage: PropTypes.bool,
  isSelected: PropTypes.bool,
};

PasoFAQ.defaultProps = {
  setIsSelected: () => null,
  isEdition: false,
  isCollapsible: false,
  isMainPage: false,
  isSelected: false,
  dataPrincipal: null,
  dataSecundaria: null,
};

