import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import PlanodiIcons from '../../Utils/PlanodiIcons';
import { componentsShowFilterModal } from '../../actions/componentsActions';
import { useDispatch, useSelector } from 'react-redux';

function SelectFilterMobile({
  title,
  setOnGlobalState,
  withBorder,
  darkMode,
  lastState,
  sharpEdges,
  leftIcon,
  items,
  multipleSelect,
  textFromTranslationKey = false,
  textKey = '',
  showSelectedElementsNumber,
  hideText,
  fullWidth,
}) {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.components.SelectFilterOptionsMobile);

  useEffect(() => {
    dispatch(componentsShowFilterModal({ open: false, data: {} }));
  }, []);

  useEffect(() => {
    if (data?.submit && data?.title === title) {
      setOnGlobalState(data.submit);
      dispatch(componentsShowFilterModal({ open: false, data: {} }));
    }
  }, [data]);

  return (
    <div
      className={clsx('noselect sel-fil-wrp', {
        'with-border': withBorder,
        dark: darkMode,
        'sharp-edges': sharpEdges,
        'filters-active': lastState.length > 0 && showSelectedElementsNumber,
        'full-width': fullWidth,
      })}
      onClick={() => {
        dispatch(
          componentsShowFilterModal({
            open: true,
            data: { title, items, multipleSelect, textFromTranslationKey, textKey, lastState },
          })
        );
      }}
    >
      {lastState.length > 0 && showSelectedElementsNumber && (
        <div className="sel-fil-notification">
          <p>{lastState.length}</p>
        </div>
      )}
      {leftIcon && (
        <div className="flex-centered">
          <PlanodiIcons name={leftIcon} style={{ fontSize: '1.1rem' }} />
        </div>
      )}
      {!hideText && <p className="sel-fil-p text-no-wrap-3-dots-overflow">{title}</p>}
    </div>
  );
}

export default SelectFilterMobile;

