import { SiteMaps } from '../../../componentes/SiteMap/SiteMap';

const categoriesContent = [
  { name: 'catering', route: 'search?category=catering' },
  { name: 'drinks', route: 'search?category=drinks' },
  { name: 'venues', route: 'search?category=venues' },
  { name: 'liveMusic', route: 'search?category=liveMusic' },
  { name: 'photographyVideography', route: 'search?category=photographyVideography' },
  { name: 'furnitureRentals', route: 'search?category=furnitureRentals' },
  { name: 'entertainment', route: 'search?category=entertainment' },
  { name: 'eventDecoration', route: 'search?category=eventDecoration' },
  { name: 'eventPlanners', route: 'search?category=eventPlanners' },
  { name: 'eventWaitstaff', route: 'search?category=eventWaitstaff' },
  { name: 'invitations', route: 'search?category=invitations' },
  { name: 'candyBars', route: 'search?category=candyBars' },
  { name: 'cakesAndDesserts', route: 'search?category=cakesAndDesserts' },
  { name: 'piñatasAndSweets', route: 'search?category=piñatasAndSweets' },
  { name: 'beautyAndHealth', route: 'search?category=beautyAndHealth' },
  { name: 'weddingCarRentals', route: 'search?category=weddingCarRentals' },
];

const CategoriesSitemap = () => {
  return (
    <>
      <SiteMaps title={'categories'} content={categoriesContent} />
    </>
  );
};

export default CategoriesSitemap;

