import './CardPackages.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { GoVerified } from 'react-icons/go/index';
import {
  getCurrencyFromCountry,
  mostrarPrimerosDosObjetosDeArray,
} from '../../Utils/utilFunctions';
import { BiMap } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { kindOfPrices } from '../pasos/PasoPaquetes/ModalCreatePackage';

const CardPackages = ({
  urlId,
  imgUrl,
  aliadoName,
  title,
  aliadoPathUrl,
  insignia,
  price,
  estados,
  country,
  kindOfPrice = 4,
  descuento = 0,
}) => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const mobile = useMediaQuery('(max-width:960px)');
  return (
    <div
      className="cardPackages-wrp"
      onClick={() => {
        if (mobile) {
          navigate(`${t('routes.packages')}/${urlId}`);
        } else {
          window.open(`${window.location.origin}${t('routes.packages')}/${urlId}`, '_blank');
        }
      }}
    >
      {Boolean(descuento) && (
        <div className="cardPackages-discount">
          <p>-{descuento}%</p>
        </div>
      )}
      <div className="cardPackages-img-wrp">
        <img src={imgUrl} className="cardPackages-img" alt={`${aliadoName}-${title}`} />
      </div>
      <div className="cardPackages-aliado-package-description">
        <p className="cardPackages-package-name">
          {title.length >= 45 ? `${title.substring(0, 45)}...` : title}
        </p>
        <p className="cardPackages-package-publisedBy">
          {t('packageCard.published')}{' '}
          <span
            className="cardPackages-aliado-name"
            onClick={(e) => {
              e.stopPropagation();
              if (mobile) {
                navigate(`${t('routes.profile')}/${aliadoPathUrl}`);
              } else {
                window.open(
                  `${window.location.origin}${t('routes.profile')}/${aliadoPathUrl}`,
                  '_blank'
                );
              }
            }}
          >
            <span>{aliadoName}</span>
            {Boolean(insignia) ? (
              <span
                style={{
                  color: `var(--planodi-${insignia})`,
                  marginLeft: '0.3rem',
                }}
              >
                <GoVerified
                  style={{
                    marginBottom: '0.2rem',
                  }}
                />
              </span>
            ) : null}
          </span>
        </p>

        <p className="cardPackages-package-entity-category">
          <BiMap style={{ fontSize: '17px', margin: '0 5px 0 0' }} />{' '}
          {mostrarPrimerosDosObjetosDeArray(estados, 'estado')}
        </p>

        {Boolean(price) && (
          <p className="cardPackages-price">
            {t('packageCard.from')}
            {' $'}
            {price.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}{' '}
            {getCurrencyFromCountry(country)}{' '}
            {kindOfPrice && kindOfPrice < 5 ? t(`packages.${kindOfPrices[kindOfPrice]}`) : null}
          </p>
        )}
      </div>
    </div>
  );
};

export default CardPackages;

