import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import axios from 'axios';

import './RankingScore.css';

import AdminLayout from '../../../componentes/AdminLayout/AdminLayout';
import { userHeaders } from '../../../Utils/headerSetter';
import { botonesMoradoNegroRojo } from '../../../componentes/Packages/ModalPackage/CreatePagesInPackages';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useUpdateScore from './useUpdateScore';

function RankingPackages({ checkIfIsAdmin }) {
  const { t } = useTranslation('global');
  const [packageSelected, setPackageSelected] = useState(null);
  const [scoreInput, setScoreInput] = useState('');
  const [inputValue, setInputValue] = useState('');

  const botonesClasses = botonesMoradoNegroRojo();

  async function getPackagesData() {
    checkIfIsAdmin();
    const res = await axios.get(
      `${
        process.env.REACT_APP_ENV === 'development'
          ? process.env.REACT_APP_API_LOCAL
          : process.env.REACT_APP_API_PROD
      }admin/scoresPackages`,
      userHeaders(false)
    );

    if (!res?.data) {
      toast.error('Parece que hubo un error obteniendo scores!', {
        position: 'bottom-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return null;
    }

    return res.data;
  }

  // React query para actualizar score sin necesidad de actualizar pagina, por medio de optimisitic update.
  const { data: scoresPackages, isLoading } = useQuery('rankingPackages', getPackagesData, {
    refetchOnWindowFocus: false,
  });
  const { mutate: updateScore, isLoading: updateScoreLoading } = useUpdateScore('rankingPackages');

  useEffect(() => {
    setScoreInput(packageSelected?.rankingScore?.toString());
  }, [packageSelected]);

  return (
    <AdminLayout loading={isLoading || updateScoreLoading}>
      <div className="admin-rank-div1">
        <p>
          En esta página se puede modificar el score de paquetes, lo cual impacta directamente a su
          posición en los resultados.
        </p>
        <Autocomplete
          value={packageSelected}
          onChange={(event, value) => {
            setPackageSelected(value);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={scoresPackages}
          getOptionLabel={(option) => option?.name}
          style={{ width: '300px', marginBottom: '0.5rem' }}
          renderInput={(params) => (
            <TextField {...params} label="Nombre del paquete" variant="outlined" />
          )}
          getOptionSelected={(option, value) => option.id === value.id}
        />
        <TextField
          variant="outlined"
          value={scoreInput}
          onChange={(e) => setScoreInput(e.target.value)}
          disabled={!packageSelected}
          style={{ width: '140px' }}
        />
        <Button
          className={botonesClasses.buttonPurp}
          style={{ width: '140px', marginLeft: '20px' }}
          disabled={!packageSelected}
          onClick={() =>
            updateScore({
              packageId: packageSelected.id,
              newScore: parseInt(scoreInput),
              isProfile: false,
            })
          }
        >
          Actualizar
        </Button>
      </div>
      {scoresPackages ? (
        <table className="admin-rank-table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Nombre</th>
              <th>Score</th>
              <th>Top</th>
            </tr>
          </thead>
          <tbody>
            {scoresPackages.map((item, idx) => (
              <tr key={item.id} onClick={() => setPackageSelected(item)}>
                <td>{item.id}</td>
                <td>
                  <a
                    href={`${window.location.origin}${t('routes.packages')}/${item.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.name}
                  </a>
                </td>
                <td>{item.rankingScore}</td>
                <td>{idx + 1}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </AdminLayout>
  );
}

export default RankingPackages;

