import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import axios from 'axios';
import { fade } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

import './PasoCreatePackages.css';
import MyTextField from '../../formikInputs/MyTextField/MyTextField';
import { userHeaders } from '../../../Utils/headerSetter';
import MyLongText from '../../formikInputs/MyLongText/MyLongText';
import MyMoneyInput from '../../formikInputs/MyMoneyInput/MyMoneyInput';
import PublishIcon from '@material-ui/icons/Publish';
import { filterImageSize, imageCompressorAndFilter } from '../../../Utils/filterSize';
import ClearIcon from '@material-ui/icons/Clear';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Loading from '../../Loading/Loading';
import DotLoading from '../../DotLoading/DotLoading';
import MyRadio from '../../formikInputs/MyRadio/MyRadio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { botonesMoradoNegroRojo } from '../../Packages/ModalPackage/CreatePagesInPackages';
import { AiFillDelete } from 'react-icons/ai/index';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';

export const kindOfPrices = {
  1: 'pp',
  2: 'ph',
  3: 'pa',
  4: 'total',
};
export const kindOfPricesDiccionario = {
  porPersona: '1',
  porHora: '2',
  porArticulo: '3',
  total: '4',
};

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: '10px',
    fontSize: '1rem',
    backgroundColor: '#8c50ff',
    color: 'white',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: fade('#8c50ff', 0.9),
      color: 'white',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  tabsSinFocus: {
    color: 'red',
    '&:hover': {
      outline: 'none',
    },
  },
}));

export default function ModalCreatePackage({
  aliadoCountry,
  handleClose,
  open,
  setPackages,
  idAliado,
  isEdition,
  modoEdicion,
  handleCloseEdit,
  activeEditPackage,
  packages,
  setActiveEditPackage,
}) {
  const { t } = useTranslation('global');
  const [loading, setLoading] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  const [stepForm, setStepForm] = useState(1);
  const [lastStep, setLastStep] = useState(1);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(t('shared.unknownError'));
  const [imagenes, setImagenes] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [imagenesPreviewUrl, setImagenesPreviewUrl] = useState([]);
  const [imagesUrl, setImagesUrl] = useState([]);
  const [mainImage, setMainImage] = useState('');

  const classes = useStyles();
  const classesBotones = botonesMoradoNegroRojo();
  const botones = botonesMoradoNegroRojo();
  const mobile = useMediaQuery('(max-width:960px)');
  const mobileFullScreen = useMediaQuery('(max-width:560px)');

  useEffect(() => {
    if (modoEdicion) {
      const main = activeEditPackage.allImages
        .filter((item) => item.main)
        .map((item) => {
          return item.url;
        });
      setMainImage(main[0]);
      setImagesUrl(
        activeEditPackage.allImages.map((item) => {
          return item.url;
        })
      );
    }
  }, [activeEditPackage]);

  const validationSchema = yup.object({
    name: yup.string().required(t('yup.required')),
    description: yup.string(),
    kindOfPrice: yup.string().required(t('yup.required')),
    price: yup.string().when('kindOfPrice', {
      is: (kindOfPrice) => kindOfPrice !== '5',
      then: () => yup.string().required(t('yup.required')),
    }),
  });

  const deleteImage = (e, fotoName) => {
    const newFiles = imagenes.filter((fotos) => fotos.name !== fotoName);
    setImagenes(newFiles);
    if (fotoName === mainImage && newFiles.length > 0) {
      setMainImage(newFiles[0].name);
    }
    e.stopPropagation();
  };

  const deleteImageEdit = (e, fotoName) => {
    const newFiles = imagenes.filter((fotos) => fotos.name !== fotoName);
    setImagenes(newFiles);
    const newFilesUrl = imagesUrl.filter((fotos) => fotos !== fotoName);
    setImagesUrl(newFilesUrl);
    const imgToDelete = imagesUrl.filter((fotos) => fotos === fotoName);
    if (imgToDelete.length) {
      setImagesToDelete((prevState) => {
        return [...prevState, ...imgToDelete];
      });
    }
    if (fotoName === mainImage && newFiles.length > 0) {
      setMainImage(newFiles[0].name);
    }
    if (fotoName === mainImage && newFilesUrl.length > 0) {
      setMainImage(newFilesUrl[0]);
    }
    e.stopPropagation();
  };

  useEffect(() => {
    if (modoEdicion) {
      const urlsPreview = imagenes.map((item) => {
        const objectUrl = URL.createObjectURL(item);
        // URL.revokeObjectURL(objectUrl);
        return objectUrl;
      });
      setImagenesPreviewUrl(urlsPreview);
    }
  }, [imagenes]);

  const handleChangeEdit = async (event) => {
    setLoadingImages(true);
    let filtroSizeRes;
    try {
      filtroSizeRes = await imageCompressorAndFilter(10000000, [...event.target.files]);
    } catch (e) {
      filtroSizeRes = filterImageSize(10000000, [...event.target.files]);
    }
    setLoadingImages(false);
    let files = filtroSizeRes.filteredImages;
    if (filtroSizeRes.deleted) {
      setError(true);
      setErrorMsg(t('afiliadosSteps.images.error3'));
    }
    let fileNamesPrevImages = [];
    if (imagenes.length) {
      fileNamesPrevImages = imagenes.map((item) => {
        return item.name;
      });
    }
    files = files.filter((item) => !fileNamesPrevImages.includes(item.name));
    setImagenes([...imagenes, ...files]);

    if (files.length && !imagenes.length && !imagesUrl.length) {
      setMainImage(files[0].name);
    }
  };

  const deletePackage = () => {
    setLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/deletePackage`,
        {
          idPackage: activeEditPackage.id,
          idAliado,
        },
        userHeaders()
      )
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        const { response } = e;
        if (response && response.data && response.data.msg) {
          if (response.data.msg) setErrorMsg(response.data.msg);
        }
        setLoading(false);
        setError(true);
      });
  };

  const handleChange = async (event) => {
    setLoadingImages(true);
    let filtroSizeRes;
    try {
      filtroSizeRes = await imageCompressorAndFilter(10000000, [...event.target.files]);
    } catch (e) {
      filtroSizeRes = filterImageSize(10000000, [...event.target.files]);
    }
    setLoadingImages(false);

    let files = filtroSizeRes.filteredImages;
    if (filtroSizeRes.deleted) {
      setError(true);
      setErrorMsg(t('afiliadosSteps.images.error3'));
    }
    let fileNamesPrevImages = [];
    if (imagenes.length) {
      fileNamesPrevImages = imagenes.map((item) => {
        return item.name;
      });
    }
    files = files.filter((item) => !fileNamesPrevImages.includes(item.name));

    files = [...imagenes, ...files];
    // Esto podría volver en un futuro, por eso se comenta
    // if (files.length > 15) {
    //   files = files.slice(0, 15);
    // }
    setImagenes([...files]);
    if (files.length && !imagenes.length) {
      setMainImage(files[0].name);
    }
  };
  const closeModal = () => {
    setError(false);
    setStepForm(1);
    setImagenes([]);
    setMainImage('');
    handleClose();
  };
  const closeModalEdit = () => {
    setMainImage('');
    setImagenes([]);
    setImagesUrl([]);
    setImagesToDelete([]);
    setError(false);
    setStepForm(1);
    setActiveEditPackage({});
    handleCloseEdit();
  };

  const handleSubmitImages = async (data) => {
    setLoading(true);
    const mainImageFile = imagenes.filter((item) => item.name === mainImage);
    const notMainImages = imagenes.filter((item) => item.name !== mainImage);
    let base64Encodedimages = [];
    if (notMainImages.length) {
      base64Encodedimages = await Promise.all(
        notMainImages.map((imagen) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(imagen);
            reader.onloadend = () => {
              resolve(reader.result);
            };
          });
        })
      );
      base64Encodedimages = base64Encodedimages.map((item) => {
        return { main: false, url: false, img: item };
      });
    }
    let base64EncodedMainImage = [];
    if (mainImageFile.length) {
      base64EncodedMainImage = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(mainImageFile[0]);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
      base64EncodedMainImage = [
        {
          main: true,
          url: false,
          img: base64EncodedMainImage,
        },
      ];
    }
    let allImagesArr = [];
    if (imagesUrl.length) {
      allImagesArr = imagesUrl.map((item) => {
        return { main: false, url: true, img: item };
      });
    }
    allImagesArr = [...allImagesArr, ...base64Encodedimages, ...base64EncodedMainImage];
    allImagesArr = allImagesArr.map((item) => {
      if (item.img === mainImage) {
        item.main = true;
      }
      return item;
    });
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/updatePackageImages`,
        {
          id: activeEditPackage.id,
          imagesToDelete,
          idAliado,
          images: allImagesArr,
        },
        userHeaders()
      )
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        const { response } = e;
        if (response && response.data && response.data.msg) {
          if (response.data.msg) setErrorMsg(response.data.msg);
        }
        setLoading(false);
        setError(true);
      });
  };

  const handleSubmit = async (data, { setSubmitting }) => {
    setSubmitting(true);
    setLoading(true);
    const mainImageFile = imagenes.filter((item) => item.name === mainImage);
    const allImages = imagenes.filter((item) => item.name !== mainImage);
    let base64Encodedimages = [];
    if (allImages.length) {
      base64Encodedimages = await Promise.all(
        allImages.map((imagen) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(imagen);
            reader.onloadend = () => {
              resolve(reader.result);
            };
          });
        })
      );
    }
    const base64EncodedMainImage = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(mainImageFile[0]);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/savePackage`,
        {
          imagenes: base64Encodedimages,
          mainImage: base64EncodedMainImage,
          data,
          idAliado,
          isEdition,
        },
        userHeaders()
      )
      .then((res) => {
        const { id, name, price, description, imgUrl, allImages, kindOfPrice } = res.data;
        setMainImage('');
        setImagenes([]);
        setStepForm(1);
        setLoading(false);
        setPackages((prevState) => {
          return [
            ...prevState,
            {
              id,
              name,
              description,
              mainImage: imgUrl,
              price,
              allImages,
              kindOfPrice,
            },
          ];
        });
        handleClose();
      })
      .catch((e) => {
        const { response } = e;
        if (response && response.data && response.data.msg) {
          if (response.data.msg) setErrorMsg(response.data.msg);
        }
        setError(true);
        setLoading(false);
      });
  };

  const handleChangeTab = (event, newValue) => {
    setStepForm(newValue + 1);
  };

  const handleSubmitUpdateInfoGeneral = async (data) => {
    setLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/updateGeneralInfoPackage`,
        {
          data: { ...data, id: activeEditPackage.id },
          idAliado,
        },
        userHeaders()
      )
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        const { response } = e;
        if (response && response.data && response.data.msg) {
          if (response.data.msg) setErrorMsg(response.data.msg);
        }
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    if (open || modoEdicion) {
      const myDiv = document.getElementById('containerDialogPaquetes');
      myDiv.scrollTop = 0;
    }
  }, [stepForm]);

  return (
    <Dialog
      onClose={
        loading
          ? () => {}
          : () => {
              if (modoEdicion) {
                closeModalEdit();
                // window.location.reload();
              } else {
                setLastStep(stepForm);
                setStepForm(4);
              }
            }
      }
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={modoEdicion ? 'md' : 'lg'}
      fullScreen={mobileFullScreen}
      style={{ minWidth: '340px' }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={
          loading
            ? () => {}
            : () => {
                if (modoEdicion) {
                  closeModalEdit();
                  // window.location.reload();
                } else {
                  setLastStep(stepForm);
                  setStepForm(4);
                }
              }
        }
        style={
          modoEdicion
            ? { padding: '0.8rem 0.8rem 0 0.8rem', position: 'relative' }
            : {
                padding: '0.8rem',
                position: 'relative',
              }
        }
      >
        {loading || !modoEdicion ? null : (
          <div className="packages-edit-delete-button" onClick={deletePackage}>
            <p className="packages-edit-delete-button-p">
              <span>
                <AiFillDelete style={{ fontSize: '0.9rem', margin: '0 0.3rem 0.3rem 0' }} />
              </span>
              {t('newPackage.delete')}
            </p>
          </div>
        )}
        <p className="modal-reg-titulo" style={{ minHeight: '30px' }}>
          {modoEdicion ? (mobile ? '' : t('newPackage.edit')) : t('newPackage.title')}
        </p>
        <IconButton
          aria-label="close"
          onClick={
            loading
              ? () => {}
              : () => {
                  if (modoEdicion) {
                    closeModalEdit();
                    // window.location.reload();
                  } else {
                    setLastStep(stepForm);
                    setStepForm(4);
                  }
                }
          }
          style={{
            position: 'absolute',
            right: '0',
            top: '0',
            marginRight: '0.5rem',
            marginTop: '0.4rem',
          }}
          className="modal-reg-titulo"
        >
          <CloseIcon />
        </IconButton>
        {modoEdicion && (
          <Box sx={{ width: '100%', bgcolor: 'background.paper' }} style={{ margin: '1rem 0 0 0' }}>
            <Tabs value={stepForm - 1} onChange={handleChangeTab} centered>
              <Tab
                label={
                  mobileFullScreen
                    ? t('afiliadosSteps.packages.info')
                    : t('afiliadosSteps.packages.generalInfo')
                }
                style={
                  mobileFullScreen ? { fontSize: '0.8rem', outline: 'none' } : { outline: 'none' }
                }
              />
              <Tab
                label={t('afiliadosSteps.images.title')}
                style={
                  mobileFullScreen ? { fontSize: '0.8rem', outline: 'none' } : { outline: 'none' }
                }
              />
            </Tabs>
          </Box>
        )}
      </DialogTitle>
      <DialogContent
        dividers
        className="modal-create-package-wrp-scroll"
        style={modoEdicion ? { height: '600px' } : null}
        id="containerDialogPaquetes"
      >
        <Formik
          initialValues={
            modoEdicion
              ? {
                  name: activeEditPackage?.name ? activeEditPackage.name : '',
                  price: activeEditPackage?.price ? `${activeEditPackage.price}` : '',
                  description: activeEditPackage?.description ? activeEditPackage.description : '',
                  kindOfPrice: activeEditPackage?.kindOfPrice
                    ? `${activeEditPackage.kindOfPrice}`
                    : '',
                }
              : {
                  name: '',
                  price: '',
                  description: '',
                  kindOfPrice: '4',
                }
          }
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, validateForm, setTouched, values, setFieldValue, errors }) => (
            <Form>
              <Grid container>
                {loading ? (
                  <div style={{ height: '300px' }}>
                    <Loading helperText={t('shared.loading')} />
                  </div>
                ) : (
                  <>
                    {error ? (
                      <Grid item xs={12}>
                        <Alert severity="error" style={{ margin: '0 0.5rem 1.3rem 0.5rem' }}>
                          {errorMsg}
                        </Alert>
                      </Grid>
                    ) : null}
                    {(() => {
                      switch (stepForm) {
                        case 1:
                          return (
                            <>
                              {modoEdicion ? null : (
                                <Grid item xs={12}>
                                  <p
                                    style={{
                                      fontSize: '0.9rem',
                                      margin: '1rem',
                                    }}
                                  >
                                    {t('newPackage.description')}
                                  </p>
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <MyTextField
                                  name="name"
                                  type="input"
                                  placeholder={t('newPackage.name')}
                                />
                              </Grid>
                              <Grid item xs={12} style={{ padding: '0 0.5rem' }}>
                                <MyLongText
                                  name="description"
                                  rows={mobileFullScreen ? 4 : 5}
                                  placeholder={t('newPackage.descriptionPackage')}
                                  style={{ width: '100%' }}
                                />
                              </Grid>
                              <div className="create-package-section-div">
                                <p className="create-package-section-div-p">
                                  {t('newPackage.price')}
                                  {!modoEdicion && (
                                    <>
                                      -{' '}
                                      <span style={{ fontWeight: 300 }}>
                                        {t('newPackage.price2')}
                                      </span>
                                    </>
                                  )}
                                </p>
                              </div>
                              {!modoEdicion && (
                                <MyRadio name="kindOfPrice">
                                  <FormControlLabel
                                    value="1"
                                    checked={values.kindOfPrice === '1'}
                                    control={<Radio />}
                                    label={t('newPackage.priceOpt1')}
                                    name="kindOfPrice"
                                  />
                                  <FormControlLabel
                                    value="2"
                                    checked={values.kindOfPrice === '2'}
                                    control={<Radio />}
                                    label={t('newPackage.priceOpt2')}
                                    name="kindOfPrice"
                                  />
                                  <FormControlLabel
                                    value="3"
                                    checked={values.kindOfPrice === '3'}
                                    control={<Radio />}
                                    label={t('newPackage.priceOpt3')}
                                    name="kindOfPrice"
                                  />
                                  <FormControlLabel
                                    value="4"
                                    checked={values.kindOfPrice === '4'}
                                    control={<Radio />}
                                    label={t('newPackage.priceOpt4')}
                                    name="kindOfPrice"
                                  />
                                  <FormControlLabel
                                    value="5"
                                    checked={values.kindOfPrice === '5'}
                                    control={<Radio />}
                                    label={t('newPackage.priceOpt5')}
                                    name="kindOfPrice"
                                  />
                                </MyRadio>
                              )}
                              {values.kindOfPrice === '5' ? null : (
                                <div className="create-package-section-price-input">
                                  <MyMoneyInput
                                    name="price"
                                    placeholder="$"
                                    style={{
                                      width: '145px',
                                    }}
                                  />
                                  <p
                                    style={
                                      mobileFullScreen
                                        ? {
                                            margin: '1rem 0 0 0',
                                            fontSize: '0.8rem',
                                          }
                                        : { margin: '1rem 0 0 0' }
                                    }
                                  >
                                    <span
                                      style={
                                        mobileFullScreen
                                          ? {
                                              fontWeight: 500,
                                              fontSize: '1rem',
                                              letterSpacing: '-1px',
                                            }
                                          : {
                                              fontWeight: 500,
                                              fontSize: '1.2rem',
                                              letterSpacing: '-1px',
                                            }
                                      }
                                    >
                                      {aliadoCountry === 1 ? 'MXN' : 'USD'}
                                    </span>{' '}
                                    {t(`newPackage.${kindOfPrices[values.kindOfPrice]}`)}
                                  </p>
                                </div>
                              )}

                              {modoEdicion ? (
                                <div
                                  style={{
                                    textAlign: 'center',
                                    marginTop: '2rem',
                                    marginBottom: '2rem',
                                    width: '100%',
                                  }}
                                >
                                  <Button
                                    className={classesBotones.buttonBlack}
                                    style={
                                      mobile ? { width: '100%' } : { width: '40%', margin: '8px' }
                                    }
                                    onClick={closeModalEdit}
                                  >
                                    {t('shared.cancel')}
                                  </Button>
                                  <Button
                                    className={classesBotones.buttonPurp}
                                    style={
                                      mobile
                                        ? { width: '100%', marginTop: '0.8rem' }
                                        : { width: '40%', margin: '8px' }
                                    }
                                    onClick={async () => {
                                      const validation = await validateForm();
                                      setTouched({
                                        name: true,
                                        price: true,
                                      });
                                      if (Object.keys(validation).length === 0) {
                                        handleSubmitUpdateInfoGeneral(values);
                                      } else {
                                        setTouched({
                                          name: true,
                                          price: true,
                                          description: true,
                                        });
                                      }
                                    }}
                                  >
                                    {t('shared.save')}
                                  </Button>
                                </div>
                              ) : (
                                <Grid item xs={12}>
                                  <Button
                                    className={classes.button}
                                    disabled={isSubmitting}
                                    style={{
                                      width: '100%',
                                      margin: '1.5rem 0',
                                    }}
                                    onClick={async () => {
                                      const validation = await validateForm();
                                      setTouched({
                                        name: true,
                                        price: true,
                                      });
                                      if (Object.keys(validation).length === 0) {
                                        setStepForm(2);
                                      } else {
                                        setTouched({
                                          name: true,
                                          price: true,
                                          description: true,
                                        });
                                      }
                                    }}
                                  >
                                    {t('shared.next')}
                                  </Button>
                                </Grid>
                              )}
                            </>
                          );
                        case 2:
                          return modoEdicion ? (
                            <>
                              <div className="img-buttons-packages-modal">
                                <p
                                  style={{
                                    fontSize: '0.9rem',
                                    fontWeight: 500,
                                  }}
                                >
                                  {t('afiliadosSteps.packages.mainImageText')}
                                </p>
                                <div className="package-mod-prin-img-success">
                                  {loadingImages ? (
                                    <div style={{ marginBottom: '2rem' }}>
                                      <p
                                        style={{
                                          color: '#0D3B66',
                                          opacity: 0.4,
                                          fontSize: '0.8rem',
                                          textAlign: 'center',
                                        }}
                                      >
                                        Optimizando imágenes, espere un momento...
                                      </p>
                                      <div
                                        style={{
                                          position: 'relative',
                                          width: '100%',
                                        }}
                                      >
                                        <DotLoading />
                                      </div>
                                    </div>
                                  ) : imagesUrl.length ? (
                                    imagesUrl.map((file) => (
                                      <div
                                        className={`afil-div-preview-pckg ${
                                          mainImage === file ? 'active' : null
                                        }`}
                                        key={Math.random()}
                                        onClick={() => setMainImage(file)}
                                      >
                                        <div
                                          style={{
                                            width: '100%',
                                            maxHeight: '150px',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          <img
                                            src={file}
                                            className="afil-img-preview-pckg"
                                            alt="Mi paquete"
                                          />
                                        </div>
                                        <div
                                          className="afil-img-preview-delete"
                                          onClick={(e) => {
                                            deleteImageEdit(e, file);
                                          }}
                                        >
                                          <ClearIcon style={{ fontSize: '0.8rem' }} />
                                        </div>
                                      </div>
                                    ))
                                  ) : null}
                                  {loadingImages
                                    ? null
                                    : imagenes.length
                                    ? imagenes.map((file, idx) => (
                                        <div
                                          className={`afil-div-preview-pckg ${
                                            mainImage === file.name ? 'active' : null
                                          }`}
                                          key={Math.random()}
                                          onClick={() => setMainImage(file.name)}
                                        >
                                          <div
                                            style={{
                                              width: '100%',
                                              maxHeight: '150px',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            <img
                                              src={imagenesPreviewUrl[idx]}
                                              className="afil-img-preview-pckg"
                                              alt="Mi paquete"
                                            />
                                          </div>
                                          <div
                                            className="afil-img-preview-delete"
                                            onClick={(e) => {
                                              deleteImageEdit(e, file.name);
                                            }}
                                          >
                                            <ClearIcon style={{ fontSize: '0.8rem' }} />
                                          </div>
                                        </div>
                                      ))
                                    : null}
                                </div>
                                <div className="img-button-principal" style={{ marginTop: '2rem' }}>
                                  <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="contained-button-img-pckg-mas"
                                    type="file"
                                    multiple
                                    name="imagenes"
                                    onChange={handleChangeEdit}
                                  />
                                  <label
                                    htmlFor="contained-button-img-pckg-mas"
                                    className="principal-btn-upload"
                                    style={
                                      loadingImages
                                        ? {
                                            pointerEvents: 'none',
                                            background: '#a7a7a7',
                                            opacity: 0.8,
                                          }
                                        : null
                                    }
                                  >
                                    <div component="span">
                                      {t('afiliadosSteps.packages.addMoreImages')} <PublishIcon />
                                    </div>
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  textAlign: 'center',
                                  marginTop: '2rem',
                                  marginBottom: '2rem',
                                  width: '100%',
                                }}
                              >
                                <Button
                                  className={botones.buttonBlack}
                                  onClick={closeModalEdit}
                                  style={
                                    mobile ? { width: '100%' } : { width: '40%', margin: '8px' }
                                  }
                                >
                                  {t('shared.cancel')}
                                </Button>
                                <Button
                                  className={botones.buttonPurp}
                                  disabled={isSubmitting || (!imagenes.length && !imagesUrl.length)}
                                  style={
                                    mobile
                                      ? { width: '100%', marginTop: '0.6rem' }
                                      : { width: '40%', margin: '8px' }
                                  }
                                  onClick={handleSubmitImages}
                                >
                                  {t('shared.save')}
                                </Button>
                              </div>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12}>
                                <p style={{ fontSize: '0.9rem', margin: '1rem' }}>
                                  {t('afiliadosSteps.packages.imageImgText')}
                                </p>
                              </Grid>
                              {imagenes.length ? null : (
                                <p
                                  style={{
                                    fontSize: '0.9rem',
                                    fontWeight: 500,
                                    margin: '1rem',
                                  }}
                                >
                                  {t('afiliadosSteps.packages.imgInstructions')}
                                </p>
                              )}
                              <div className="img-buttons-packages-modal">
                                {loadingImages ? (
                                  <div style={{ marginBottom: '2rem' }}>
                                    <p
                                      style={{
                                        color: '#0D3B66',
                                        opacity: 0.4,
                                        fontSize: '0.8rem',
                                        textAlign: 'center',
                                      }}
                                    >
                                      Optimizando imágenes, espere un momento...
                                    </p>
                                    <div
                                      style={{
                                        position: 'relative',
                                        width: '100%',
                                      }}
                                    >
                                      <DotLoading />
                                    </div>
                                  </div>
                                ) : imagenes.length ? (
                                  <>
                                    <p
                                      style={{
                                        fontSize: '0.9rem',
                                        fontWeight: 500,
                                      }}
                                    >
                                      {t('afiliadosSteps.packages.mainImageText')}
                                    </p>
                                    <div className="package-mod-prin-img-success">
                                      {imagenes.length
                                        ? imagenes.map((file) => (
                                            <div
                                              className={`afil-div-preview-pckg ${
                                                mainImage === file.name ? 'active' : null
                                              }`}
                                              key={Math.random()}
                                              onClick={() => setMainImage(file.name)}
                                            >
                                              <div
                                                style={{
                                                  width: '100%',
                                                  maxHeight: '150px',
                                                  overflow: 'hidden',
                                                }}
                                              >
                                                <img
                                                  src={URL.createObjectURL(file)}
                                                  className="afil-img-preview-pckg"
                                                  alt={'Mi paquete'}
                                                />
                                              </div>
                                              <div
                                                className="afil-img-preview-delete"
                                                onClick={(e) => {
                                                  deleteImage(e, file.name);
                                                }}
                                              >
                                                <ClearIcon style={{ fontSize: '0.8rem' }} />
                                              </div>
                                            </div>
                                          ))
                                        : null}
                                    </div>

                                    <div
                                      className="img-button-principal"
                                      style={{ marginTop: '1rem' }}
                                    >
                                      <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="contained-button-img-pckg-mas"
                                        type="file"
                                        multiple
                                        name="imagenes"
                                        onChange={handleChange}
                                      />
                                      <label
                                        htmlFor="contained-button-img-pckg-mas"
                                        className="principal-btn-upload"
                                        style={
                                          loadingImages
                                            ? {
                                                pointerEvents: 'none',
                                                background: '#a7a7a7',
                                                opacity: 0.8,
                                              }
                                            : null
                                        }
                                      >
                                        <div component="span">
                                          {t('afiliadosSteps.packages.addMoreImages')}
                                          <PublishIcon />
                                        </div>
                                      </label>
                                    </div>
                                  </>
                                ) : (
                                  <div className="img-button-principal">
                                    <input
                                      accept="image/*"
                                      style={{ display: 'none' }}
                                      id="contained-button-img-pckg"
                                      type="file"
                                      multiple
                                      name="imagenes"
                                      onChange={handleChange}
                                    />
                                    <label
                                      htmlFor="contained-button-img-pckg"
                                      className="principal-btn-upload"
                                      style={
                                        loadingImages
                                          ? {
                                              pointerEvents: 'none',
                                              background: '#a7a7a7',
                                              opacity: 0.8,
                                            }
                                          : null
                                      }
                                    >
                                      <div component="span">
                                        {t('afiliadosSteps.packages.addImages')}
                                        <PublishIcon />
                                      </div>
                                    </label>
                                  </div>
                                )}
                              </div>
                              <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button
                                  className={classes.button}
                                  type="submit"
                                  disabled={isSubmitting || !imagenes.length || loadingImages}
                                  style={
                                    mobile
                                      ? {
                                          width: '100%',
                                          margin: '4rem 0 2rem 0',
                                          padding: '0.8rem 0',
                                        }
                                      : {
                                          width: '50%',
                                          margin: '4rem 0 2rem 0',
                                          padding: '0.8rem 0',
                                        }
                                  }
                                >
                                  {modoEdicion
                                    ? t('shared.save')
                                    : t('afiliadosSteps.packages.createPackage')}
                                </Button>
                              </Grid>
                            </>
                          );
                        default:
                          return (
                            <div className="packages-delete-confirmation-step4">
                              <p>{t('afiliadosSteps.packages.exitProgress')}</p>
                              <div
                                style={{
                                  textAlign: 'center',
                                  marginTop: '2rem',
                                  marginBottom: '2rem',
                                  width: '100%',
                                }}
                              >
                                <Button
                                  className={botones.buttonBlack}
                                  style={
                                    mobile ? { width: '100%' } : { width: '40%', margin: '8px' }
                                  }
                                  onClick={closeModal}
                                >
                                  {t('afiliadosSteps.packages.confirmExitProgress')}
                                </Button>
                                <Button
                                  className={botones.buttonPurp}
                                  style={
                                    mobile
                                      ? { width: '100%', marginTop: '0.8rem' }
                                      : { width: '40%', margin: '8px' }
                                  }
                                  onClick={() => {
                                    setStepForm(lastStep);
                                  }}
                                >
                                  {t('shared.cancel')}
                                </Button>
                              </div>
                            </div>
                          );
                      }
                    })()}
                  </>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

