import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import logo from '../../../Assets/img/homepage/logoFooterNegro.webp';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Footer.css';
import ModalRegistro from '../Navbar/ModalRegistro/ModalRegistro';
import ModalLogin from '../Navbar/ModalLogin/ModalLogin';
import { useAuthModals } from '../Navbar/Navbar';
import { AiFillInstagram } from 'react-icons/ai/index';
import { getUrl } from '../../../Utils/url';
import { useCountry } from '../../../componentes/Hooks/CountryContext';
import { userHeaders } from '../../../Utils/headerSetter';
import axios from 'axios';

export default function Footer() {
  const mobile = useMediaQuery('(max-width:960px)');
  const stateUser = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { openRegistroModal, openLoginModal, setOpenRegistroModal, setOpenLoginModal } =
    useAuthModals();
  const { t } = useTranslation('global');
  const { countryObject } = useCountry();
  const target = mobile ? '_self' : '_blank';

  const handleAfiliarmeButton = () => {
    if (stateUser.isAuthenticated) {
      if (stateUser.userInfo?.isVerified) {
        axios
          .get(
            `${
              process.env.REACT_APP_ENV === 'development'
                ? process.env.REACT_APP_API_LOCAL
                : process.env.REACT_APP_API_PROD
            }user/afiliar?userId=${stateUser.userInfo?.id}`,
            userHeaders(false)
          )
          .then((res) => {
            const { registroPendiente } = res?.data;
            if (registroPendiente) {
              navigate(`${t('routes.join')}?step=${parseInt(registroPendiente.step) + 1}`);
            } else {
              navigate(t('routes.join'));
            }
          })
          .catch(() => {
            navigate(t('routes.join'));
          });
      } else {
        toast.dismiss();
        toast.warn(t('login.userNotValidatedYet'), {
          position: 'bottom-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      setOpenRegistroModal(true);
    }
  };

  const contactData = {
    email: countryObject.countryId === '1' ? 'hola@planodi.com' : 'hello@planodi.com',
    whatsAppMsg: countryObject.countryId === '1' ? '¡Hola%20Planodi!' : 'Hi%20Planodi!',
  };

  return (
    <div className="footer-background-solid">
      <ModalRegistro
        handleClose={() => setOpenRegistroModal(false)}
        open={openRegistroModal}
        titleModal={t('register.firstRegister')}
        afterRegister={() => {
          navigate(t('routes.join'));
        }}
        openLogin={() => setOpenLoginModal(true)}
      />
      <ModalLogin
        handleClose={() => {
          setOpenLoginModal(false);
        }}
        open={openLoginModal}
        openRegister={() => setOpenRegistroModal(true)}
        afterLogin={() => {
          navigate(t('routes.join'));
        }}
      />
      <div
        style={{
          position: 'relative',
          padding: '0 6%',
        }}
      >
        <Grid container style={{ marginTop: '2rem' }}>
          <Grid item xs={12} md={4}>
            <p className="footer-titulo">{t(`footer.information`)}</p>
            <p style={{ cursor: 'pointer' }}>
              <a href={getUrl(t, 'information')} className="footer-link">
                {t(`footer.infoForBusinesses`)}
              </a>
            </p>
            <p onClick={handleAfiliarmeButton} style={{ cursor: 'pointer' }}>
              <a className="footer-link">{t(`footer.promoteYourBusiness`)}</a>
            </p>
            <p>
              <a
                href={`https://api.whatsapp.com/send?phone=521${process.env.REACT_APP_WHATSAPP}&text=${contactData.whatsAppMsg}`}
                target="_blank"
                className="footer-link"
                rel="noopener noreferrer"
              >
                {t(`footer.contactUs`)}
              </a>
            </p>
            <p>
              <a
                href={getUrl(t, 'documents?id=termsAndConditions')}
                target={target}
                rel="noopener noreferrer"
                className="footer-link"
              >
                {t(`footer.termsAndConditions`)}
              </a>
            </p>
            <p>
              <a
                href={getUrl(t, 'documents?id=privacyPolicy')}
                target={target}
                rel="noopener noreferrer"
                className="footer-link"
              >
                {t(`footer.privacyPolicy`)}
              </a>
            </p>
            {countryObject.countryId === '2' ? (
              <p>
                <a
                  href={getUrl(t, 'privacy-options')}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  {t(`footer.privacyChoices`)}
                </a>
              </p>
            ) : null}
            <p>
              <a
                href={getUrl(t, 'sitemaps')}
                target={target}
                rel="noopener noreferrer"
                className="footer-link"
              >
                Sitemap
              </a>
            </p>
          </Grid>
          {mobile ? null : (
            <Grid item xs={12} md={4} className="footer-logo-wrp">
              <img src={logo} alt="Logo Planodi" className="footer-logo" />
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <p
              className="footer-titulo footer-titulo-right"
              style={!mobile ? { width: '100%' } : { width: '100%' }}
            >
              {t(`footer.followUs`)}
            </p>
            <p style={{ textAlign: 'right' }}>
              <AiFillInstagram
                className="footer-redes-s-wrp-icon"
                onClick={() => {
                  window.open('https://www.instagram.com/planodimx/', '_blank');
                }}
              />
            </p>
            <p
              className="footer-titulo footer-titulo-right"
              style={
                !mobile
                  ? { width: '100%', marginTop: '3rem' }
                  : { width: '100%', marginTop: '3rem' }
              }
            >
              {t(`footer.emailContact`)}
            </p>
            <p style={{ textAlign: 'right' }}>
              <a href={`mailto: ${contactData.email}`} className="footer-link footer-link-right">
                {contactData.email}
              </a>
            </p>
          </Grid>
        </Grid>
      </div>
      <div
        style={
          mobile
            ? { margin: '3rem 6% 0 6%', paddingBottom: '1rem' }
            : { margin: '1rem 6% 0 6%', paddingBottom: '1rem' }
        }
      >
        <p style={{ fontSize: '0.75rem', textAlign: 'center' }}>
          Copyright © {moment().year()} {t(`footer.copyright`)}.
        </p>
      </div>
    </div>
  );
}

