import React from 'react';
import { useTranslation } from 'react-i18next';

function SelectFilterButtons({ clear, submit, multipleSelect, submitDisabled, borrarDisabled }) {
  const { t } = useTranslation('global');

  return (
    <div className="sel-fil-options-actions-wrp">
      {multipleSelect ? (
        <button
          className="button-clean sel-fil-options-borrar"
          onClick={clear}
          disabled={borrarDisabled}
        >
          {t('shared.clear')}
        </button>
      ) : (
        <div />
      )}
      <button
        className="button-clean sel-fil-options-guardar"
        onClick={submit}
        disabled={submitDisabled}
      >
        {t('shared.onlySave')}
      </button>
    </div>
  );
}

export default SelectFilterButtons;

