/* eslint-disable react-hooks/exhaustive-deps */
import './EditarNegocio.css';
import '../../componentes/pasos/PasoUrl/PasoUrl.css';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { HiOutlinePencil } from 'react-icons/hi';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import { AiFillCaretLeft } from 'react-icons/ai';
import { MdPhotoCamera } from 'react-icons/md';
import 'moment/locale/es';

import PublishIcon from '@material-ui/icons/Publish';
import Loading from '../../componentes/Loading/Loading';
import PasoCategoria from '../../componentes/pasos/PasoCategoria/PasoCategoria';
import PasoTypeOfEvents from '../../componentes/pasos/PasoTypeOfEvents/PasoTypeOfEvents';
import Navbar from '../HomePage/Navbar/Navbar';
import PasoCreatePackages from '../../componentes/pasos/PasoPaquetes/PasoCreatePackages';
import PasoImagenes from '../../componentes/pasos/PasoImagenes/PasoImagenes';
import { userHeaders } from '../../Utils/headerSetter';
import { filterImageSize, imageCompressorAndFilter } from '../../Utils/filterSize';
import EditarRedesSociales from './EditarRedesSociales/EditarRedesSociales';
import EditarExtras from './EditarExtras/EditarExtras';
import { precioToNumber } from '../../componentes/pasos/PasoUrl/PasoUrl';
import EditarVideos from './EditarVideos/EditarVideos';
import ManageSubscription from '../../componentes/ManageSubscription/ManageSubscription';
import Paywall from '../../componentes/Paywall';
import { useLoadAliado } from './useLoadAliado';
import { useTranslation } from 'react-i18next';
import EditarInfoGeneralProveedores from './InfoGeneral/EditarInfoGeneralProveedores';
import EditarFAQ from './FAQ/EditarFAQ';
import EditarInfoGeneralTerrazas from './InfoGeneral/EditarInfoGeneralTerrazas';
const menu = [
  'dashboard.generalInfo',
  'dashboard.packages',
  'dashboard.images',
  'dashboard.addVideos',
  'dashboard.tellUsAboutYourBusiness',
  'dashboard.eventsType',
  'dashboard.frecuentQuestions',
  'dashboard.socialMedia',
  'dashboard.extras',
  'dashboard.paymentsAndSubscriptions',
];

const renderStepFormComponent = ({
  userID,
  setStep,
  aliadoInfoGeneral,
  aliadoInfoSecundaria,
  isTerraza,
  selectedOption,
  eventosActivos,
  currentPackages,
  currentImages,
  videos,
  plan,
  username,
  stripeSessionId,
  precios,
}) => {
  switch (selectedOption) {
    case 1:
      return (
        <PasoCreatePackages
          aliadoCountry={aliadoInfoGeneral.paisId}
          setStep={setStep}
          idAliado={aliadoInfoGeneral.id}
          isEdition
          currentPackages={currentPackages}
          plan={plan}
        />
      );
    case 2:
      return (
        <PasoImagenes
          plan={plan}
          setStep={setStep}
          idAliado={aliadoInfoGeneral.id}
          isEdition
          currentImages={currentImages}
          premium={aliadoInfoGeneral.membresia === 2}
          initialMain={currentImages.filter((img) => img.main)[0]?.id.toString()}
        />
      );
    case 3:
      return <EditarVideos idAliado={aliadoInfoGeneral.id} videos={videos} plan={plan} />;
    case 4:
      return isTerraza ? (
        <EditarInfoGeneralTerrazas
          content={aliadoInfoGeneral.perfilContent}
          idAliado={aliadoInfoGeneral.id}
          dataSecundaria={aliadoInfoSecundaria}
        />
      ) : (
        <EditarInfoGeneralProveedores
          content={aliadoInfoGeneral.perfilContent}
          idAliado={aliadoInfoGeneral.id}
        />
      );
    case 5:
      return (
        <PasoTypeOfEvents
          setStep={setStep}
          idAliado={aliadoInfoGeneral.id}
          isEdition
          eventosActivos={eventosActivos}
        />
      );
    case 6:
      return (
        <EditarFAQ content={aliadoInfoGeneral.perfilPreguntas} idAliado={aliadoInfoGeneral.id} />
      );
    case 7:
      return (
        <EditarRedesSociales
          idAliado={aliadoInfoGeneral.id}
          instagram={aliadoInfoGeneral.instagram}
          facebook={aliadoInfoGeneral.facebook}
          youtube={aliadoInfoGeneral.youtube}
        />
      );
    case 8:
      return (
        <EditarExtras
          idAliado={aliadoInfoGeneral.id}
          initialPrecio={aliadoInfoGeneral.precio ? precioToNumber[aliadoInfoGeneral.precio] : 5}
        />
      );

    case 9:
      return (
        <Paywall
          plan={plan}
          basic={
            <ManageSubscription
              dataPrincipal={aliadoInfoGeneral}
              planType="basic"
              username={username}
              stripeId={plan.stripeCustomerId}
              plan={plan}
              newPlan={stripeSessionId ? true : false}
              precios={precios}
            />
          }
          premium={
            <ManageSubscription
              dataPrincipal={aliadoInfoGeneral}
              planType="premium"
              username={username}
              stripeId={plan.stripeCustomerId}
              plan={plan}
              newPlan={stripeSessionId ? true : false}
              precios={precios}
            />
          }
          pro={
            <ManageSubscription
              dataPrincipal={aliadoInfoGeneral}
              planType="pro"
              username={username}
              stripeId={plan.stripeCustomerId}
              plan={plan}
              newPlan={stripeSessionId ? true : false}
              precios={precios}
            />
          }
          free={
            <ManageSubscription
              dataPrincipal={aliadoInfoGeneral}
              planType="free"
              username={username}
              stripeId={null}
              plan={plan}
              newPlan={stripeSessionId ? true : false}
              precios={precios}
            />
          }
        />
      );
    case 0:
    default:
      return (
        <PasoCategoria
          setStep={setStep}
          userID={userID}
          dataPrincipal={aliadoInfoGeneral}
          isEdition
          isTerraza={isTerraza}
        />
      );
  }
};

const renderStepForms = ({
  userID,
  setStep,
  aliadoInfoGeneral,
  aliadoInfoSecundaria,
  isTerraza,
  selectedOption,
  setSelectedOption,
  eventosActivos,
  currentPackages,
  currentImages,
  videos,
  plan,
  username,
  stripeSessionId,
  precios,
}) => (
  <>
    <div className="step-mobile-wrapper-ed-nego">
      <PasoCategoria
        setStep={setStep}
        userID={userID}
        dataPrincipal={aliadoInfoGeneral}
        isEdition
        isCollapsible
        isSelected={selectedOption === 0}
        setIsSelected={setSelectedOption}
        isTerraza={isTerraza}
      />
    </div>
    <div className="step-mobile-wrapper-ed-nego">
      <PasoCreatePackages
        aliadoCountry={aliadoInfoGeneral.paisId}
        setStep={setStep}
        idAliado={aliadoInfoGeneral.id}
        currentPackages={currentPackages}
        isEdition
        isCollapsible
        setIsSelected={setSelectedOption}
        isSelected={selectedOption === 1}
        plan={plan}
      />
    </div>
    <div className="step-mobile-wrapper-ed-nego">
      <PasoImagenes
        plan={plan}
        setStep={setStep}
        idAliado={aliadoInfoGeneral.id}
        isEdition
        currentImages={currentImages}
        premium={aliadoInfoGeneral.membresia === 2}
        isCollapsible
        setIsSelected={setSelectedOption}
        isSelected={selectedOption === 2}
        initialMain={currentImages.filter((img) => img.main)[0].id.toString()}
      />
    </div>
    <div className="step-mobile-wrapper-ed-nego">
      <EditarVideos
        isCollapsible
        isSelected={selectedOption === 3}
        setIsSelected={setSelectedOption}
        idAliado={aliadoInfoGeneral.id}
        videos={videos}
        plan={plan}
      />
    </div>
    <div className="step-mobile-wrapper-ed-nego">
      {isTerraza ? (
        <EditarInfoGeneralTerrazas
          content={aliadoInfoGeneral.perfilContent}
          idAliado={aliadoInfoGeneral.id}
          dataSecundaria={aliadoInfoSecundaria}
          isCollapsible
          setIsSelected={setSelectedOption}
          isSelected={selectedOption === 4}
        />
      ) : (
        <EditarInfoGeneralProveedores
          content={aliadoInfoGeneral.perfilContent}
          isCollapsible
          setIsSelected={setSelectedOption}
          isSelected={selectedOption === 4}
          idAliado={aliadoInfoGeneral.id}
        />
      )}
    </div>
    <div className="step-mobile-wrapper-ed-nego">
      <PasoTypeOfEvents
        setStep={setStep}
        idAliado={aliadoInfoGeneral.id}
        isEdition
        isSelected={selectedOption === 5}
        isCollapsible
        setIsSelected={setSelectedOption}
        eventosActivos={eventosActivos}
      />
    </div>
    <div className="step-mobile-wrapper-ed-nego">
      <EditarFAQ
        content={aliadoInfoGeneral.perfilPreguntas}
        idAliado={aliadoInfoGeneral.id}
        isCollapsible
        setIsSelected={setSelectedOption}
        isSelected={selectedOption === 6}
      />
    </div>
    <div className="step-mobile-wrapper-ed-nego">
      <EditarRedesSociales
        isCollapsible
        isSelected={selectedOption === 8}
        setIsSelected={setSelectedOption}
        idAliado={aliadoInfoGeneral.id}
        instagram={aliadoInfoGeneral.instagram}
        facebook={aliadoInfoGeneral.facebook}
        youtube={aliadoInfoGeneral.youtube}
      />
    </div>
    <div className="step-mobile-wrapper-ed-nego">
      <EditarExtras
        isCollapsible
        isSelected={selectedOption === 9}
        setIsSelected={setSelectedOption}
        idAliado={aliadoInfoGeneral.id}
        initialPrecio={aliadoInfoGeneral.precio ? precioToNumber[aliadoInfoGeneral.precio] : 5}
      />
    </div>
    <div className="step-mobile-wrapper-ed-nego">
      <Paywall
        plan={plan}
        basic={
          <ManageSubscription
            isCollapsible
            isSelected={selectedOption === 10}
            setIsSelected={setSelectedOption}
            dataPrincipal={aliadoInfoGeneral}
            planType="basic"
            username={username}
            stripeId={plan.stripeCustomerId}
            plan={plan}
            newPlan={stripeSessionId ? true : false}
            precios={precios}
          />
        }
        premium={
          <ManageSubscription
            isCollapsible
            isSelected={selectedOption === 10}
            setIsSelected={setSelectedOption}
            dataPrincipal={aliadoInfoGeneral}
            planType="premium"
            username={username}
            stripeId={plan.stripeCustomerId}
            plan={plan}
            newPlan={stripeSessionId ? true : false}
            precios={precios}
          />
        }
        pro={
          <ManageSubscription
            isCollapsible
            isSelected={selectedOption === 10}
            setIsSelected={setSelectedOption}
            dataPrincipal={aliadoInfoGeneral}
            planType="pro"
            username={username}
            stripeId={plan.stripeCustomerId}
            plan={plan}
            newPlan={stripeSessionId ? true : false}
            precios={precios}
          />
        }
        free={
          <ManageSubscription
            isCollapsible
            isSelected={selectedOption === 10}
            setIsSelected={setSelectedOption}
            dataPrincipal={aliadoInfoGeneral}
            planType="free"
            username={username}
            stripeId={null}
            plan={plan}
            newPlan={stripeSessionId ? true : false}
            precios={precios}
          />
        }
      />
    </div>
  </>
);

const updateLogo = (handleChange, logoUrl, loadingLogo, pathName, nombre, t, mobile) => {
  return (
    <div
      className="title-name-ed-nego"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          position: 'relative',
          padding: '0',
        }}
      >
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file-logo"
          type="file"
          name="imagenesLogo"
          onChange={handleChange}
        />
        <label htmlFor="contained-button-file-logo" style={{ margin: '0' }}>
          {logoUrl && !loadingLogo ? (
            <div style={{ background: `url(${logoUrl})` }} className="content-ed-nego-logo-img" />
          ) : (
            <div className="content-ed-nego-logo-subir">
              <p
                style={{
                  fontSize: '0.8rem',
                  color: 'white',
                  textAlign: 'center',
                  lineHeight: '13px',
                  marginTop: '12px',
                  fontWeight: 400,
                }}
              >
                {loadingLogo ? (
                  t('shared.loading')
                ) : (
                  <div>
                    <PublishIcon />
                  </div>
                )}
              </p>
            </div>
          )}
          <MdPhotoCamera
            className="content-ed-nego-logo-icon"
            style={logoUrl && !loadingLogo ? { bottom: '10px' } : { bottom: '0' }}
          />
        </label>
      </div>
      <a
        href={`${window.location.origin}${t('routes.profile')}/${pathName}`}
        target={mobile ? '_self' : '_blank'}
        rel="noopener noreferrer"
        style={{ margin: '0' }}
      >
        {nombre}
      </a>
    </div>
  );
};

export default function EditarNegocio() {
  const mobile = useMediaQuery('(max-width:960px)');
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('global');
  moment.locale(i18n.language);
  const { pathName } = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const [loading, setLoading] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [stripeSessionId, setStripeSessionId] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    parseInt(params?.option) <= 10 ? parseInt(params?.option) : 0
  );
  const { data = {}, isLoading, refetch } = useLoadAliado(pathName);

  const {
    aliadoInfoGeneral,
    infoSecundaria: aliadoInfoSecundaria,
    images: fotos,
    videos,
    packages: paquetes,
    eventosActivos,
    isTerraza,
    plan,
    precios,
  } = data;

  useEffect(() => {
    if (params?.session_id) {
      setStripeSessionId(params?.session_id);
    }
  }, [params?.session_id]);

  useEffect(() => {
    if (stripeSessionId && aliadoInfoGeneral?.id) {
      axios.post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }stripe/verify-success-session`,
        { stripeSessionId: stripeSessionId, idAliado: aliadoInfoGeneral.id },
        userHeaders()
      );
    }
  }, [stripeSessionId, aliadoInfoGeneral?.id]);

  useEffect(() => {
    navigate(`?option=${selectedOption}`);
  }, [navigate, selectedOption]);

  useEffect(() => {
    if (params?.option && parseInt(params?.option) <= 9) {
      setSelectedOption(parseInt(params?.option));
    }
  }, [params?.option]);

  const handleChange = async (event) => {
    setLoadingLogo(true);
    let filtroSizeRes;
    try {
      filtroSizeRes = await imageCompressorAndFilter(10000000, [...event.target.files]);
    } catch (e) {
      filtroSizeRes = filterImageSize(10000000, [...event.target.files]);
    }
    let file = filtroSizeRes.filteredImages;
    if (file.length) {
      const base64EncodedMainImage = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
      axios
        .post(
          `${
            process.env.REACT_APP_ENV === 'development'
              ? process.env.REACT_APP_API_LOCAL
              : process.env.REACT_APP_API_PROD
          }user/changeLogo`,
          {
            image: base64EncodedMainImage,
            idAliado: aliadoInfoGeneral.id,
          },
          userHeaders()
        )
        .then((res) => {
          refetch();
          setLoadingLogo(false);
          aliadoInfoGeneral.logoUrl = res.data;
        })
        .catch(() => {
          setLoadingLogo(false);
        });
    }
  };

  const Header = ({ style = {} }) => (
    <div style={style}>
      <p className="title-ed-nego">
        <span>
          <HiOutlinePencil style={{ marginRight: '15px', marginBottom: '5px' }} />
        </span>
        {t('dashboard.edition')}
      </p>
      {updateLogo(
        handleChange,
        aliadoInfoGeneral?.logoUrl,
        loadingLogo,
        pathName,
        aliadoInfoGeneral.nombre,
        t,
        mobile
      )}
    </div>
  );

  return !loading && !isLoading ? (
    <div className="main-wrapper-ed-nego">
      <div className="second-wrapper-ed-nego">
        <div style={{ width: '100%', minWidth: '320px' }}>
          <Navbar type="black" shadow />
        </div>

        <div
          style={{
            width: '100%',
            position: 'relative',
            minHeight: '400px',
            marginBottom: '5rem',
            marginTop: '2rem',
          }}
        >
          {mobile ? (
            <>
              <div className="back-button-ed-neg" onClick={() => navigate(t('routes.myBusiness'))}>
                <AiFillCaretLeft style={{ marginBottom: '4px' }} /> {t('shared.back')}
              </div>
              <Header style={{ padding: '0 2rem' }} />

              {renderStepForms({
                userID: aliadoInfoGeneral.usuario,
                aliadoInfoGeneral,
                aliadoInfoSecundaria,
                setStep: () => {
                  setLoading(true);
                  window.location.reload();
                },
                isTerraza,
                selectedOption,
                setSelectedOption,
                eventosActivos,
                currentPackages: paquetes,
                currentImages: fotos,
                videos,
                plan,
                username: pathName,
                stripeSessionId,
                precios,
              })}
            </>
          ) : (
            <div className="grid-wrapper-ed-nego">
              <div className="menu-wrapper-ed-nego">
                <ul className="ul-ed-nego">
                  <div
                    className="back-button-ed-neg"
                    onClick={() => navigate(t('routes.myBusiness'))}
                  >
                    <AiFillCaretLeft style={{ marginBottom: '4px' }} /> {t('shared.back')}
                  </div>
                  {menu.map((option, index) => (
                    <li
                      style={
                        index === selectedOption
                          ? {
                              fontWeight: 'bold',
                              listStyleType: 'disc',
                              color: '#8c50ff',
                            }
                          : {}
                      }
                      key={option}
                      onClick={() => setSelectedOption(index)}
                      className="li-ed-nego"
                    >
                      {t(option)}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="content-wrapper-ed-nego">
                <Header />
                <div className="steps-wrapper-ed-nego">
                  {renderStepFormComponent({
                    userID: aliadoInfoGeneral.usuario,
                    aliadoInfoGeneral,
                    aliadoInfoSecundaria,
                    setStep: () => {
                      setLoading(true);
                      window.location.reload();
                    },
                    isTerraza,
                    selectedOption,
                    eventosActivos,
                    currentPackages: paquetes,
                    currentImages: fotos,
                    videos,
                    plan,
                    username: pathName,
                    stripeSessionId,
                    precios,
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <Loading helperText={t('shared.loading')} />
  );
}

