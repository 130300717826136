/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form as FormikForm } from 'formik';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import MyTextField from '../../formikInputs/MyTextField/MyTextField';
import MyCelInput from '../../formikInputs/MyCelInput/MyCelInput';
import Button from '@material-ui/core/Button';
import { botonesAtrasYAdelante } from '../PasoForm';

import '../../../Pages/Afiliarme/Afiliarme.css';
import Dialog from '@material-ui/core/Dialog';
import { Checkbox, ListItemText, MenuItem } from '@material-ui/core';
import MyMultipleSelect from '../../formikInputs/MyMultipleSelect/MyMultipleSelect';
import { useTranslation } from 'react-i18next';
import { useCountry } from '../../Hooks/CountryContext';

export default function FormCategoria({
  values,
  setFieldValue,
  categorias,
  locations,
  isEdition,
  isTerraza,
  isMainPage,
  comenzarDeNuevo,
  isAdminPage,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const buttonTypes = botonesAtrasYAdelante();
  const movil = useMediaQuery('(max-width:960px)');
  const [nombreHelp, setNombreHelp] = useState(false);
  const [phoneNumberHelp, setPhoneNumberHelp] = useState(false);
  const [whatsHelp, setWhatsHelp] = useState(false);
  const [confModal, setConfModal] = useState(false);

  let country = parseInt(values.pais);

  return (
    <FormikForm
      style={movil ? { width: '100%', marginTop: '2rem' } : { width: '100%', marginTop: '3rem' }}
    >
      <Dialog
        onClose={() => {
          setConfModal(false);
        }}
        open={confModal}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
          }}
        >
          <p style={{ color: '#3b3b3b', fontSize: '1rem', fontWeight: 500 }}>
            {t('afiliadosSteps.category.startOverTitle')}
          </p>
          <p
            style={{
              color: '#3b3b3b',
              fontSize: '0.9rem',
              textAlign: 'left',
              width: '100%',
            }}
          >
            {t('afiliadosSteps.category.startOverModal')}
          </p>
          <div style={{ width: '100%' }}>
            <Button
              className={buttonTypes.saveButton}
              style={{ width: '100%', marginTop: '0.5rem' }}
              onClick={() => setConfModal(false)}
            >
              {t('shared.cancel')}
            </Button>
            <Button
              className={buttonTypes.cancelButton}
              style={{ width: '100%', marginTop: '0.5rem' }}
              onClick={comenzarDeNuevo}
            >
              {t('afiliadosSteps.category.startAgain')}
            </Button>
          </div>
        </div>
      </Dialog>
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          className="postion-rel"
          style={
            movil
              ? {
                  margin: '1rem 0 1rem 0',
                }
              : {
                  margin: '-0.5rem 0 1.5rem 0',
                }
          }
        >
          <MyTextField
            name="nombre"
            placeholder={t('afiliadosSteps.category.name')}
            customHandleBlur={() => {
              setNombreHelp(false);
            }}
            onFocus={() => {
              setNombreHelp(true);
            }}
          />
          {nombreHelp ? (
            <p className="helper-text-input">💡 {t('afiliadosSteps.category.nameDesc')}</p>
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={
            movil
              ? {
                  margin: '0.5rem 0',
                }
              : null
          }
        >
          <MyMultipleSelect
            name="categoria"
            renderValue={(selected) =>
              selected
                .map((id) => {
                  const matchingObject = categorias.find((obj) => obj.id === id);
                  return t(matchingObject.key);
                })
                .join(', ')
            }
          >
            {categorias.map((categoria) => (
              <MenuItem
                key={categoria.id}
                value={categoria.id}
                disabled={
                  isEdition && !isTerraza
                    ? categoria.id === 3 || categoria.id === -1
                    : categoria.id === -1
                }
              >
                {categoria.id !== -1 && (
                  <Checkbox checked={values.categoria.indexOf(categoria.id) > -1} />
                )}
                <ListItemText primary={t(categoria.key)} />
              </MenuItem>
            ))}
          </MyMultipleSelect>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={
            movil
              ? {
                  margin: '1.5rem 0',
                }
              : {
                  margin: '0 0 1.5rem 0',
                }
          }
        >
          <MyMultipleSelect
            name="selectedLocations"
            renderValue={(selected) =>
              selected
                .map((id) => {
                  const matchingObject = locations.find((obj) => obj.id === id);
                  return matchingObject?.name;
                })
                .join(', ')
            }
          >
            {locations.map((location) => (
              <MenuItem key={location.id} value={location.id} disabled={location.id === -1}>
                {location.id !== -1 && (
                  <Checkbox checked={values.selectedLocations.indexOf(location.id) > -1} />
                )}
                <ListItemText primary={location.name} />
              </MenuItem>
            ))}
          </MyMultipleSelect>
        </Grid>

        <p style={{ fontSize: '0.9rem', margin: '1rem 0 1rem 10px', width: '100%' }}>
          {t('afiliadosSteps.category.contact')}
        </p>
        <Grid
          item
          xs={12}
          md={6}
          className="postion-rel"
          style={
            movil
              ? {
                  margin: '0.5rem 0',
                }
              : {
                  margin: '0 0 1.5rem 0',
                }
          }
        >
          <MyCelInput
            name="phoneNumber"
            label=""
            placeholder={t('afiliadosSteps.category.phone')}
            customHandleBlur={() => {
              setPhoneNumberHelp(false);
            }}
            onFocus={() => {
              setPhoneNumberHelp(true);
            }}
            phoneCode={country === 2 ? '+1' : '+52'}
          />
          {phoneNumberHelp ? (
            <p className="helper-text-input" style={{ maxWidth: '20rem' }}>
              💡 {t('afiliadosSteps.category.phoneDesc')}
            </p>
          ) : null}
        </Grid>
        {values.pais === 2 ? null : (
          <Grid
            item
            xs={12}
            md={6}
            className="postion-rel"
            style={
              movil
                ? {
                    margin: '0.5rem 0',
                  }
                : {
                    margin: '0 0 1.5rem 0',
                  }
            }
          >
            <MyCelInput
              name="whatsapp"
              label=""
              placeholder={t('afiliadosSteps.category.whatsAppNumber')}
              customHandleBlur={() => {
                setWhatsHelp(false);
              }}
              onFocus={() => {
                setWhatsHelp(true);
              }}
              phoneCode={country == 2 ? '+1' : '+52'}
            />

            {whatsHelp ? (
              <p className="helper-text-input" style={{ maxWidth: '20rem' }}>
                💡 {t('afiliadosSteps.category.whatsAppDesc')}
              </p>
            ) : null}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={6}
          className="postion-rel"
          style={
            movil
              ? {
                  margin: '0.5rem 0',
                }
              : {
                  margin: '0 0 1.5rem 0',
                }
          }
        >
          <MyTextField name="email" label="" placeholder={t('afiliadosSteps.category.email')} />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          className="postion-rel"
          style={
            movil
              ? {
                  margin: '0.5rem 0',
                }
              : {
                  margin: '0 0 1.5rem 0',
                }
          }
        >
          <MyTextField name="webSite" label="" placeholder={t('afiliadosSteps.category.webSite')} />
        </Grid>

        <Grid
          item
          container
          style={
            isEdition
              ? { marginTop: '1.5rem', marginBottom: '1.5rem' }
              : { marginTop: '2rem', marginBottom: '3rem' }
          }
        >
          {isEdition ? (
            <Grid item xs={12} style={movil ? { textAlign: 'center' } : { textAlign: 'right' }}>
              {isMainPage || isAdminPage ? (
                <Button
                  className={buttonTypes.cancelButton}
                  style={
                    movil ? { width: '100%', color: 'red' } : { width: '30%', color: '#d72424' }
                  }
                  onClick={() => setConfModal(true)}
                >
                  {t('afiliadosSteps.category.startAgain')}
                </Button>
              ) : (
                <Button
                  className={buttonTypes.cancelButton}
                  style={movil ? { width: '100%' } : { width: '30%' }}
                  onClick={() => navigate(t('routes.myBusiness'))}
                >
                  {t('shared.cancel')}
                </Button>
              )}

              <Button
                type="submit"
                className={buttonTypes.saveButton}
                style={
                  movil
                    ? { width: '100%', marginTop: '1.5rem' }
                    : { width: '30%', marginLeft: '1.5rem' }
                }
              >
                {isMainPage ? t('shared.saveAndContinue') : t('shared.save')}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                type="submit"
                className={buttonTypes.button}
                style={movil ? { width: '100%' } : { width: '30%' }}
              >
                {t('shared.continue')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormikForm>
  );
}

