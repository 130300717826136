import './BuscadorGrid.css';

import InfiniteScroll from 'react-infinite-scroll-component';
import CardGeneral from '../../../componentes/CardGeneral/CardGeneral';
import React from 'react';
import BuscadorGridSkeleton from './BuscadorGridSkeleton';
import CardPackages from '../../../componentes/CardPackages/CardPackages';
import clsx from 'clsx';
import { useBuscadorState } from '../BuscadorContext';
import { useTranslation } from 'react-i18next';

function BuscadorGrid({
  isError,
  data,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  isLoadingCards,
  isPackagesPage = false,
}) {
  const { clearAllFilters } = useBuscadorState();
  const { t } = useTranslation('global');
  return (
    <>
      {!isLoadingCards ? (
        data.length ? (
          <InfiniteScroll
            dataLength={data.length}
            next={() => fetchNextPage()}
            scrollThreshold={0.7}
            hasMore={Boolean(hasNextPage)}
            loader={
              <>
                {isFetchingNextPage ? (
                  <div>
                    <BuscadorGridSkeleton numberOfCards={30} />
                  </div>
                ) : null}
              </>
            }
          >
            <div
              className={clsx('buscador-grid-wrp', {
                'buscador-packages-grid-wrp': isPackagesPage,
              })}
            >
              {data.map((item) => {
                if (isPackagesPage) {
                  return (
                    <CardPackages
                      urlId={item.idPackage}
                      imgUrl={item.images[0].url}
                      aliadoName={item.aliadoName.trim()}
                      title={item.name}
                      aliadoPathUrl={item.pathUrl}
                      insignia={item.insignia}
                      price={item.price}
                      estados={item.locations}
                      key={item.id}
                      country={item.country}
                      kindOfPrice={item.kindOfPrice}
                      descuento={item.descuento}
                    />
                  );
                }
                return <CardGeneral info={item} key={item.id} />;
              })}
            </div>
          </InfiniteScroll>
        ) : (
          <div
            style={{
              width: '100%',
              height: '10rem',
              marginTop: '6rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#3b3b3b',
              flexDirection: 'column',
            }}
          >
            <p>{t('explore.noResults')}</p>
            <p onClick={clearAllFilters} className="buscador-grid-clear-filters">
              {t('explore.clean')}
            </p>
          </div>
        )
      ) : (
        <div>
          <BuscadorGridSkeleton />
        </div>
      )}
    </>
  );
}

export default BuscadorGrid;

