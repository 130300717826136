import { clsx } from 'clsx';
import React, { useEffect, useState } from 'react';
import PlanodiIcons from '../../Utils/PlanodiIcons';
import SelectFilterOptionsDesktop from './SelectFilterOptionsDesktop';
import { useRef } from 'react';

function SelectFilterDesktop({
  title,
  setOnGlobalState,
  withBorder,
  darkMode,
  sharpEdges,
  toggleArrow,
  leftIcon,
  items,
  multipleSelect,
  lastState,
  textFromTranslationKey = false,
  textKey = '',
  showSelectedElementsNumber,
  hideText,
  fullWidth,
  widthOptionsDesktop,
}) {
  const ref = useRef(null);
  const botonRef = useRef(null);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (showFilters) {
      setSelectedItems(lastState);
    }
  }, [showFilters]);

  useEffect(() => {
    function handleClickOutside(event) {
      /**
       *  DESKTOP - Al dar click se cerrará el modal si se cumple:
       *  1. que el modal esté abierto
       *  2. que el click sea fuera del modal
       *  3. que el click sea fuera del botón
       */
      if (
        showFilters &&
        ref.current &&
        !ref.current.contains(event.target) &&
        botonRef.current &&
        !botonRef.current.contains(event.target)
      ) {
        setShowFilters(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, botonRef, showFilters]);

  // Función para manejar la logica de click sobre un item
  const selectItem = (item) => {
    // Si el item ya esta seleccionado, se des-selecciona
    if (selectedItems.includes(item.id)) {
      removeItem(item);
      return;
    }
    if (multipleSelect) {
      setSelectedItems((prevState) => [...prevState, item.id]);
    } else {
      setSelectedItems([item.id]);
    }
  };

  // Función para des-seleccionar un item
  const removeItem = (item) => {
    setSelectedItems((prevState) => prevState.filter((itemId) => itemId !== item.id));
  };

  const clearSelections = () => {
    setSelectedItems([]);
  };

  const handleSubmit = () => {
    setOnGlobalState(selectedItems);
    setShowFilters(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={clsx('noselect sel-fil-wrp', {
          active: showFilters,
          'with-border': withBorder,
          dark: darkMode,
          'sharp-edges': sharpEdges,
          'filters-active': lastState.length > 0 && showSelectedElementsNumber,
          'full-width': fullWidth,
        })}
        ref={botonRef}
        onClick={() => {
          setShowFilters((prevState) => !prevState);
        }}
      >
        {lastState.length > 0 && showSelectedElementsNumber && (
          <div className="sel-fil-notification">
            <p>{lastState.length}</p>
          </div>
        )}
        {leftIcon && (
          <div className="flex-centered">
            <PlanodiIcons name={leftIcon} style={{ fontSize: '1.1rem' }} />
          </div>
        )}
        {!hideText && <p className="sel-fil-p text-no-wrap-3-dots-overflow">{title}</p>}
        {toggleArrow && (
          <div className="flex-centered">
            <PlanodiIcons
              name={showFilters ? 'arrow-up' : 'arrow-down'}
              style={{ fontSize: '1.1rem' }}
            />
          </div>
        )}
      </div>
      {showFilters && (
        <SelectFilterOptionsDesktop
          items={items}
          multipleSelect={multipleSelect}
          selectedItems={selectedItems}
          refMenu={ref}
          selectItem={selectItem}
          clearSelections={clearSelections}
          textFromTranslationKey={textFromTranslationKey}
          textKey={textKey}
          handleSubmit={handleSubmit}
          lastState={lastState}
          widthOptionsDesktop={widthOptionsDesktop}
        />
      )}
    </div>
  );
}

export default SelectFilterDesktop;

