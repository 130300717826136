import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import { userHeaders } from '../../../Utils/headerSetter';

// Hook for stopping or resuming subscriptions
export default function useUpdateScore(queryKey) {
  const queryClient = useQueryClient();

  const updateScore = async ({ packageId, newScore, isProfile }) => {
    try {
      await axios.post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }admin/updateScore`,
        { id: packageId, newScore, isProfile },
        userHeaders()
      );
    } catch (error) {
      toast.error('Parece que hubo un error', {
        position: 'bottom-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // optimistic update
  return useMutation(updateScore, {
    // When mutate is called:
    onMutate: async ({ packageId, newScore }) => {
      await queryClient.cancelQueries(queryKey);
      const previousData = queryClient.getQueryData(queryKey);

      if (previousData) {
        // Deep clone the object, otherwise the objects inside will have the same reference in memory
        let newData = JSON.parse(JSON.stringify(previousData));

        // actualizar al score nuevo
        newData = newData.map((item) => {
          if (item.id !== packageId) {
            return item;
          }

          return { ...item, rankingScore: newScore };
        });
        queryClient.setQueryData(queryKey, newData);
      }
      return { previousData };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_error, { _args }, context) => {
      queryClient.setQueryData(queryKey, context?.previousData);
    },
    // Always refetch after error or success:
    onSettled: (_data, _error, { _args }) => {
      queryClient.invalidateQueries(queryKey);
    },
  });
}

