import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from './Loading/Loading';

export default function RutaPrivada({ loading }) {
  const { t } = useTranslation('global');
  const location = useLocation();
  const user = useSelector((state) => state?.user);
  const authUser = user?.isAuthenticated;
  const isAdmin = user?.userInfo?.isAdmin;
  const rootRoute = location.pathname;
  let authorized = false;

  if (rootRoute.includes('join') && user?.userInfo?.isVerified) {
    authorized = authUser;
  } else if (rootRoute.includes('my-business')) {
    authorized = authUser;
  } else if (rootRoute.includes('admin')) {
    authorized = isAdmin;
  } else if (rootRoute.includes('change-password')) {
    authorized = authUser;
  } else {
    authorized = false;
  }

  if (loading) return <Loading helperText={t('shared.loading')} />;

  return authorized ? (
    <Outlet />
  ) : (
    <Navigate to={t('routes.home')} state={{ referrer: location }} />
  );
}

