import './Buscador.css';

import React from 'react';
import Navbar from '../HomePage/Navbar/Navbar';
import { BuscadorProvider } from './BuscadorContext';
import BuscadorPerfiles from './BuscadorPerfiles';
import Footer from '../HomePage/Footer/Footer';
import { useTranslation } from 'react-i18next';
import BuscadorTabs from './BuscadorTabs';
import BuscadorPackages from './BuscadorPackages';

function BuscadorPage({ packagesPage }) {
  return (
    <div>
      <Navbar type="black" />
      <div style={{ margin: '0 4%' }}>
        <BuscadorTabs packagesPage={packagesPage} />
        {packagesPage ? <BuscadorPackages /> : <BuscadorPerfiles />}
      </div>
      <Footer />
    </div>
  );
}
export default function Buscador({ packagesPage = false }) {
  const { t } = useTranslation('global');
  document.title = t('tabTitles.explore');

  return (
    <BuscadorProvider>
      <BuscadorPage packagesPage={packagesPage} />
    </BuscadorProvider>
  );
}

