import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IoIosArrowDown } from 'react-icons/io';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useCountry } from '../../componentes/Hooks/CountryContext';
import { useNavigate } from 'react-router-dom';
import Check from '@mui/icons-material/Check';

import pantallas from '../../Assets/img/landing_aliados/pantallas.png';
import circuloBlanco from '../../Assets/img/landing_aliados/circulo_blanco.png';
import circuloMorado from '../../Assets/img/landing_aliados/circulo.png';
import laptop from '../../Assets/img/landing_aliados/catalogo_lap.png';
import dots1 from '../../Assets/img/landing_aliados/04.png';
import dots2 from '../../Assets/img/landing_aliados/03.png';
import logo from '../../Assets/img/LogoBlanco.webp';
import logoGris from '../../Assets/img/PLANODI_PNG_gris.webp';
import { GoVerified } from 'react-icons/go/index';

import './LandingAliados.css';
import axios from 'axios';

const preguntasInit = [
  {
    id: 1,
    pregunta: 'landing.q1',
    respuesta: 'landing.r1',
    open: true,
  },
  {
    id: 2,
    pregunta: 'landing.q2',
    respuesta: 'landing.r2',
    open: false,
  },
  {
    id: 3,
    pregunta: 'landing.q3',
    respuesta: 'landing.r3',
    open: false,
  },
  {
    id: 4,
    pregunta: 'landing.q4',
    respuesta: 'landing.r4',
    open: false,
  },
];

export default function LandingAliados() {
  const { t } = useTranslation('global');
  const [preguntas, setPreguntas] = useState(preguntasInit);
  const [changeNav, setChangeNav] = useState(false);
  const [offSetY, setOffsetY] = useState(0);
  const [prices, setPrices] = useState();
  const [currency, setCurrency] = useState();
  const { countryObject } = useCountry();
  const navigate = useNavigate();

  const mobile2 = useMediaQuery('(max-width:560px)');
  const target = mobile2 ? '_self' : '_blank';

  const handleScroll = () => {
    if (window.pageYOffset >= 20) {
      setChangeNav(true);
    } else {
      setChangeNav(false);
    }
    setOffsetY(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    document.title = 'Afiliate a Planodi - Información para proveedores y terrazas';

    if (countryObject?.countryId !== '1') {
      setCurrency('usd');
    } else {
      setCurrency('mxn');
    }
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }aliado/getPlanPrices`,
        {
          currency: currency,
          env: process.env.REACT_APP_ENV === 'development' ? 'dev' : 'prod',
        }
      )
      .then((res) => {
        setPrices(res.data.res);
      });
  }, [currency]);

  return (
    <div className="landing-aliados-wrp-all">
      <div className="landing-aliados-wrp">
        <div className={`landing-aliados-navbar ${changeNav ? 'active' : ''}`}>
          <img
            src={changeNav ? logoGris : logo}
            alt="logo"
            className={`landing-aliados-logo ${changeNav ? 'active' : ''}`}
            onClick={() => {
              navigate(t('routes.home'));
            }}
          />
          {/*<div className="landing-aliados-boton">Registrar ahora</div>*/}
          <button
            className={`landing-aliados-boton ${changeNav ? 'active' : ''}`}
            onPointerMove={(e) => {
              const x = e.pageX - e.target.offsetLeft;
              const y = e.pageY - e.target.offsetTop;

              e.target.style.setProperty('--x', `${x}px`);
              e.target.style.setProperty('--y', `${y}px`);
            }}
            onClick={() => {
              window.open(`https://planodi.com${t('routes.signUp')}`, target);
            }}
          >
            {t('landing.registerBtn')}
          </button>
        </div>
        <div className="landing-aliados-img-fondo" />
        <div className="landing-aliados-img-morado" />
        <img src={pantallas} alt="pantallas planodi" className="landing-aliados-pantallas" />
        <div className="landing-aliados-texto">
          <h1 className="landing-aliados-h1">{t('landing.catalog')}</h1>
          <h2 className="landing-aliados-h2">{t('landing.visibility')}</h2>
        </div>
        <img src={circuloBlanco} alt="circulo" className="landing-aliados-circulo-b" />
        <img src={circuloBlanco} alt="circulo2" className="landing-aliados-circulo-b2" />
        <img src={dots1} alt="dots" className="landing-aliados-dots" />
      </div>
      <div className="landing-aliados-como-f-wrp">
        <p className="landing-aliados-como-f-title">{t('landing.worksTitle')}</p>
        <div className="landing-aliados-como-f-title-line" />
        <div className="landing-aliados-como-f-cards-flex">
          <div className="landing-aliados-como-f-cards landing-aliados-como-f-cards1">
            <p>{t('landing.card1')}</p>
            <div className="landing-aliados-como-f-emoji1">💻</div>
          </div>
          <div className="landing-aliados-como-f-cards landing-aliados-como-f-cards2">
            <p>{t('landing.card2')}</p>
            <div className="landing-aliados-como-f-emoji2">🚀</div>
          </div>
          <div className="landing-aliados-como-f-cards landing-aliados-como-f-cards3">
            <p style={mobile2 ? { fontSize: '0.95rem' } : { fontSize: '1.1rem' }}>
              {t('landing.card3')}
            </p>
            <div className="landing-aliados-como-f-emoji3">📲</div>
          </div>
        </div>
      </div>
      <div
        className="landing-aliados-como-f-wrp"
        style={mobile2 ? { marginTop: '1rem' } : { marginTop: '6rem' }}
      >
        <p className="landing-aliados-como-f-title">{t('landing.frecuentQuestions')}</p>
        <div className="landing-aliados-como-f-title-line" />
        <div className="landing-aliados-faq-box">
          {preguntas.map((item) => (
            <div key={item.id}>
              <div
                className="landing-aliados-faq-pre"
                onClick={() => {
                  setPreguntas((prevState) => {
                    return prevState.map((item2) => {
                      if (item2.id === item.id) {
                        return { ...item2, open: !item.open };
                      } else {
                        return item2;
                      }
                    });
                  });
                }}
              >
                <p>{t(`${item.pregunta}`)}</p>
                <IoIosArrowDown className={`landing-aliados-faq-pre-icon ${item.open && 'open'}`} />
              </div>
              <div className={`landing-aliados-faq-res ${!item.open && 'close'}`}>
                <p>{t(`${item.respuesta}`)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="landing-aliados-como-f-wrp"
        style={mobile2 ? { marginTop: '1rem' } : { marginTop: '6rem' }}
      >
        <p className="landing-aliados-como-f-title">{t('landing.packagesPrices')}</p>
        {/* <p className="landing-aliados-como-f-subtitle">
          Por tiempo limitado: membresía premium gratis de por vida a los primeros 150 negocios que
          se registren en Planodi
        </p> */}
        <div className="landing-aliados-como-f-title-line" style={{ marginBottom: 0 }} />
        <div className="landing-aliados-pasos-wrp">
          <div className="landing-aliados-planes-box">
            <div className="landing-aliados-planes-card">
              <div className="landing-aliados-plan-free-selection">
                <p className="landing-aliados-plan-title">
                  {t('afiliadosSteps.paymentPlan.basic.title')}
                </p>
                <p style={{ margin: 0 }}>
                  <span style={{ fontSize: '25px', fontWeight: '600' }}>
                    ${prices && prices[0]?.price} {currency && currency.toUpperCase()}{' '}
                  </span>
                  /{t('shared.month')}
                </p>
              </div>
              <div className="landing-aliados-plan-info-wrp">
                <div className="landing-aliados-plan-info-content">
                  <p>
                    <span style={{ color: '#51d364' }}>
                      <Check style={{ marginRight: '5px' }} />
                    </span>{' '}
                    {t('afiliadosSteps.paymentPlan.basic.details1')}
                  </p>
                  <p>
                    <span style={{ color: '#51d364' }}>
                      {' '}
                      <Check style={{ marginRight: '5px' }} />{' '}
                    </span>
                    {t('afiliadosSteps.paymentPlan.basic.details2')}
                  </p>
                  <p>
                    <span style={{ color: '#51d364' }}>
                      {' '}
                      <Check style={{ marginRight: '5px' }} />{' '}
                    </span>

                    {t('afiliadosSteps.paymentPlan.basic.details3')}
                  </p>
                  <p>
                    <span style={{ color: '#51d364' }}>
                      <Check style={{ marginRight: '5px' }} />
                    </span>{' '}
                    {t('afiliadosSteps.paymentPlan.basic.details4')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="landing-aliados-planes-box">
            <div className="landing-aliados-planes-card">
              <div className="landing-aliados-plan-premium-selection">
                <p className="landing-aliados-plan-title">
                  {t('afiliadosSteps.paymentPlan.premium.title')}
                </p>
                <p style={{ margin: 0 }}>
                  <span style={{ fontSize: '25px', fontWeight: '600' }}>
                    ${prices && prices[1]?.price} {currency && currency.toUpperCase()}{' '}
                  </span>
                  /{t('shared.month')}
                </p>
              </div>
              <div className="landing-aliados-plan-info-wrp">
                <div className="landing-aliados-plan-info-content">
                  <p>
                    <span style={{ color: '#51d364' }}>
                      <Check style={{ marginRight: '5px' }} />
                    </span>{' '}
                    {t('afiliadosSteps.paymentPlan.premium.details1')}
                  </p>
                  <p>
                    <span style={{ color: '#51d364' }}>
                      {' '}
                      <Check style={{ marginRight: '5px' }} />
                    </span>
                    {t('afiliadosSteps.paymentPlan.premium.details2')}
                  </p>
                  <p>
                    <span style={{ color: '#51d364' }}>
                      {' '}
                      <Check style={{ marginRight: '5px' }} />{' '}
                    </span>

                    {t('afiliadosSteps.paymentPlan.premium.details3')}
                  </p>
                  <p>
                    <span style={{ color: '#51d364' }}>
                      <Check style={{ marginRight: '5px' }} />
                    </span>{' '}
                    {t('afiliadosSteps.paymentPlan.premium.details4')}
                  </p>
                  <p>
                    <span style={{ color: '#51d364' }}>
                      <Check style={{ marginRight: '5px' }} />
                    </span>{' '}
                    {t('afiliadosSteps.paymentPlan.premium.details5')}
                  </p>
                  <p>
                    <span style={{ color: '#51d364' }}>
                      <GoVerified
                        style={{ fontSize: '19px', color: '#8c50ff', marginRight: '5px' }}
                      />
                    </span>{' '}
                    {t('afiliadosSteps.paymentPlan.premium.details6')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="landing-aliados-planes-box">
            <div className="landing-aliados-planes-card">
              <div className="landing-aliados-plan-plus-selection">
                <p className="landing-aliados-plan-title">
                  {t('afiliadosSteps.paymentPlan.pro.title')}
                </p>
                <p style={{ margin: 0 }}>
                  <span style={{ fontSize: '25px', fontWeight: '600' }}>
                    ${prices && prices[2]?.price} {currency && currency.toUpperCase()}{' '}
                  </span>
                  /{t('shared.month')}
                  {/* <span style={{ textDecoration: 'line-through' }}>Solo $1799 al año</span>{' '} */}
                  {/* <span
                    style={{
                      fontWeight: 500,
                      marginLeft: '0.5rem',
                      fontSize: '1.1rem',
                      background: 'red',
                      color: 'white',
                      padding: '3px',
                      borderRadius: '5px',
                    }}
                  >
                    GRATIS
                  </span> */}
                </p>
              </div>
              <div className="landing-aliados-plan-info-wrp">
                <div className="landing-aliados-plan-info-content">
                  <p>
                    <span style={{ color: '#51d364' }}>
                      <Check style={{ marginRight: '5px' }} />
                    </span>{' '}
                    {t('afiliadosSteps.paymentPlan.pro.details1')}
                  </p>
                  <p>
                    <span style={{ color: '#51d364' }}>
                      <Check style={{ marginRight: '5px' }} />{' '}
                    </span>
                    {t('afiliadosSteps.paymentPlan.pro.details2')}
                  </p>
                  <p>
                    <span style={{ color: '#51d364' }}>
                      <Check style={{ marginRight: '5px' }} />{' '}
                    </span>
                    {t('afiliadosSteps.paymentPlan.pro.details3')}
                  </p>
                  <p>
                    <span style={{ color: '#51d364' }}>
                      <Check style={{ marginRight: '5px' }} />
                    </span>{' '}
                    {t('afiliadosSteps.paymentPlan.pro.details4')}{' '}
                  </p>
                  <p>
                    <span style={{ color: '#51d364' }}>
                      <Check style={{ marginRight: '5px' }} />
                    </span>{' '}
                    {t('afiliadosSteps.paymentPlan.pro.details5')}{' '}
                  </p>
                  <p>
                    <span style={{ color: '#51d364' }}>
                      <GoVerified
                        style={{ fontSize: '19px', color: '#FFBC1D', marginRight: '5px' }}
                      />
                    </span>{' '}
                    {t('afiliadosSteps.paymentPlan.pro.details6')}{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="landing-aliados-como-f-wrp"
        style={mobile2 ? { marginTop: '1rem' } : { marginTop: '6rem' }}
      >
        <p className="landing-aliados-como-f-title">{t('landing.howRegister.title')}</p>
        <div className="landing-aliados-como-f-title-line" style={{ marginBottom: 0 }} />
        <div className="landing-aliados-pasos-wrp">
          <div className="landing-aliados-pasos-box">
            <img src={laptop} alt="laptop planodi" className="landing-aliados-pasos-box-img" />
            <img
              src={circuloMorado}
              alt="circulo morado"
              className="landing-aliados-pasos-box-circulo"
            />
            <img
              src={dots1}
              alt="dots"
              className="landing-aliados-pasos-box-dots1"
              style={{ transform: `translateX(${offSetY * -0.1}px)` }}
            />
            <img
              src={dots2}
              alt="dots2"
              className="landing-aliados-pasos-box-dots2"
              style={{ transform: `translateY(${offSetY * -0.15}px)` }}
            />
          </div>
          <div className="landing-aliados-pasos-box">
            <div className="landing-aliados-pasos-box-content">
              <p className="landing-aliados-pasos-box-title">{t('landing.howRegister.steps')}</p>
              <p>
                <b>{t('landing.howRegister.step')} 1: </b>
                {t('landing.howRegister.step1Content')}
                <a
                  href={`https://planodi.com/${t('routes.signUp')}`}
                  target={target}
                  rel="noopener noreferrer"
                >
                  {t('landing.howRegister.step1Link')}.
                </a>{' '}
              </p>
              <p>
                <b>{t('landing.howRegister.step')} 2:</b> {t('landing.howRegister.step2Content')}.
              </p>
              <p>
                <b>{t('landing.howRegister.step')} 3:</b> {t('landing.howRegister.step3Content')}.
              </p>
              <p style={{ marginTop: '2rem' }}>{t('landing.howRegister.finalStep')}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="landing-aliados-como-f-wrp landing-aliados-footer"
        style={{ marginBottom: '0' }}
      >
        <div className="landing-aliados-footer-block" />
        <p className="landing-aliados-footer-p">
          {t('landing.whatsAppQuestion')}{' '}
          <a
            href={`https://api.whatsapp.com/send?phone=521${process.env.REACT_APP_WHATSAPP}&text=¡Hola%20Planodi!%20Tengo%20una%20duda.`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            {t('landing.whatsAppAnswear')}
          </a>
        </p>
        <p className="landing-aliados-footer-p2">
          Copyright © {moment().year()} Planodi. {t('footer.copyright')}.
        </p>
      </div>
    </div>
  );
}

