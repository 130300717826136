import './Afiliarme.css';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Loading from '../../componentes/Loading/Loading';
import { userHeaders } from '../../Utils/headerSetter';
import Navbar from '../HomePage/Navbar/Navbar';
import PasoCategoria from '../../componentes/pasos/PasoCategoria/PasoCategoria';
import { useDispatch, useSelector } from 'react-redux';
import PasoInfoGeneral from '../../componentes/pasos/PasoInfoGeneral/PasoInfoGeneral';
import PasoImagenes from '../../componentes/pasos/PasoImagenes/PasoImagenes';
import PasoUrl from '../../componentes/pasos/PasoUrl/PasoUrl';
import PasoConfirmacion from '../../componentes/pasos/PasoConfirmacion/PasoConfirmacion';
import Stepper2 from '../../componentes/pasos/Stepper/Stepper2';
import PasoSelectPlan from '../../componentes/pasos/PasoPlan/PasoSelectPlan';
import PasoTypeOfEvents from '../../componentes/pasos/PasoTypeOfEvents/PasoTypeOfEvents';
import PasoWelcome from '../../componentes/pasos/PasoWelcome/PasoWelcome';
import PasoFAQ from '../../componentes/pasos/PasoFAQ/PasoFAQ';
import { useCountry, COUNTRY_IDS } from '../../componentes/Hooks/CountryContext';
import { useTranslation } from 'react-i18next';
import { userAuthError, userGetUserSuccess } from '../../actions/userActions';

export default function Afiliarme() {
  const { countryObject } = useCountry();
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const stateUser = useSelector((state) => state.user);

  const [stripeSessionId, setStripeSessionId] = useState(params?.session_id);
  const [idAliado, setIdAliado] = useState(stateUser.userInfo?.allyPendingRegister?.idAliado);
  const [username, setUsername] = useState('');
  const [isTerraza, setIsTerraza] = useState(true);
  const [infoPlan, setInfoPlan] = useState({ cantidadDeFotos: 3 });
  const userID = useSelector((state) => state?.user?.userInfo?.id);

  const [loading, setLoading] = useState(true);
  const [aliadoInfoGeneral, setAliadoInfoGeneral] = useState(null);
  const [aliadoInfoSecundaria, setAliadoInfoSecundaria] = useState(null);
  const [fotos, setFotos] = useState([]);
  const [eventosActivos, setEventosActivos] = useState([]);
  const [stripeData, setStripeData] = useState(null);
  const [pais, setPais] = useState(0);
  const [precios, setPrecios] = useState(null);

  const [step, setStep] = useState(null);

  const currentStep = stateUser?.userInfo?.allyPendingRegister?.step
    ? stateUser?.userInfo?.allyPendingRegister?.step + 1
    : 0;

  const [firstRender, setFirstRender] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (stateUser.userInfo?.isVerified) {
        const pendingStep = stateUser.userInfo?.allyPendingRegister;
        if (pendingStep) {
          let pathStep = parseInt(params?.step);

          let initialStep = 0;

          if (pathStep && pathStep <= pendingStep.step + 1) {
            initialStep = pathStep;
          } else {
            initialStep = pendingStep.step + 1;
          }

          setStep(initialStep);

          window.history.replaceState(
            null,
            document.title,
            t('routes.join') + '?step=' + initialStep
          );

          setIdAliado(pendingStep.idAliado);

          const fetchData = async () => {
            try {
              await axios
                .get(
                  `${
                    process.env.REACT_APP_ENV === 'development'
                      ? process.env.REACT_APP_API_LOCAL
                      : process.env.REACT_APP_API_PROD
                  }user/getAliadoById?idAliado=${pendingStep?.idAliado}`,
                  userHeaders(false)
                )
                .then((res) => {
                  const {
                    aliadoInfoGeneral,
                    infoSecundaria,
                    images,
                    eventosActivos,
                    isTerraza,
                    precios,
                  } = res.data;
                  setPais(aliadoInfoGeneral?.paisId);
                  setAliadoInfoGeneral(aliadoInfoGeneral);
                  setAliadoInfoSecundaria(infoSecundaria);
                  setFotos(images);
                  setIsTerraza(isTerraza);
                  setEventosActivos(eventosActivos);
                  setPrecios(precios);
                });
            } catch (error) {
              dispatch(userAuthError());
              setLoading(false);
            }
          };

          await fetchData();
        } else {
          setStep(0);
          window.history.replaceState(null, document.title, t('routes.join'));
        }
        setLoading(false);
      }
      setFirstRender(false);
    })();
  }, []);

  useEffect(() => {
    if (params?.session_id) {
      setStripeSessionId(params.session_id);
    }

    if (!firstRender && params && params?.step) {
      let paramStep = parseInt(params?.step);
      setStep(paramStep);
    }

    if (Object.keys(params).length === 0 && currentStep && step === 1) {
      navigate(t('routes.home'));
    }
  }, [params]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firstResponse = await axios.get(
          `${
            process.env.REACT_APP_ENV === 'development'
              ? process.env.REACT_APP_API_LOCAL
              : process.env.REACT_APP_API_PROD
          }user/get`,
          userHeaders(false)
        );

        const { token, user } = firstResponse.data;
        dispatch(userGetUserSuccess(token, user));
        const pendingStep = user?.allyPendingRegister;

        if (pendingStep?.idAliado) {
          const secondResponse = await axios.get(
            `${
              process.env.REACT_APP_ENV === 'development'
                ? process.env.REACT_APP_API_LOCAL
                : process.env.REACT_APP_API_PROD
            }user/getAliadoById?idAliado=${pendingStep?.idAliado}`,
            userHeaders(false)
          );
          const { aliadoInfoGeneral, infoSecundaria, images, eventosActivos, isTerraza, precios } =
            secondResponse.data;
          setPais(aliadoInfoGeneral.paisId);
          setAliadoInfoGeneral(aliadoInfoGeneral);
          setAliadoInfoSecundaria(infoSecundaria);
          setFotos(images);
          setIsTerraza(isTerraza);
          setEventosActivos(eventosActivos);
          setPrecios(precios);
        }
      } catch (error) {
        dispatch(userAuthError());
        setLoading(false);
      }
    };

    fetchData();
  }, [step]);

  return loading ? (
    <Loading helperText={t('shared.loading')} />
  ) : (
    <div className="afil-wrapper">
      <div style={{ width: '100%', minWidth: '320px' }}>
        <Navbar type="black" shadow hideLocation />
      </div>
      <div className="afil-content-wrp">
        {step === 8 || step === 0 ? null : <Stepper2 currentStep={step} numSteps={7} />}
        {(() => {
          switch (step) {
            case 0:
              return (
                <PasoWelcome
                  setStep={(newStep) => {
                    navigate(t('routes.join') + '?step=' + newStep);
                  }}
                  setPais={setPais}
                  currentPais={pais}
                />
              );
            case 1:
              return (
                <PasoCategoria
                  setStep={(newStep) => {
                    // setLoading(true);
                    navigate(t('routes.join') + '?step=' + newStep);
                  }}
                  userID={userID}
                  setIsTerraza={setIsTerraza}
                  isTerraza={isTerraza}
                  setIdAliado={setIdAliado}
                  isEdition={!!idAliado && !!aliadoInfoGeneral}
                  dataPrincipal={aliadoInfoGeneral}
                  isMainPage
                  paisPaso0={pais}
                />
              );
            case 2:
              return (
                <PasoInfoGeneral
                  isTerraza={isTerraza}
                  setStep={(direction) => {
                    // setLoading(true);
                    navigate(t('routes.join') + '?step=' + (step + direction));
                  }}
                  idAliado={idAliado}
                  isEdition={step < currentStep}
                  dataSecundaria={aliadoInfoSecundaria}
                  isMainPage
                />
              );
            case 3:
              return (
                <PasoTypeOfEvents
                  setStep={(direction) => {
                    navigate(t('routes.join') + '?step=' + (step + direction));
                  }}
                  idAliado={idAliado}
                  isEdition={step < currentStep}
                  eventosActivos={eventosActivos}
                  isMainPage
                />
              );
            case 4:
              return (
                <PasoFAQ
                  isTerraza={isTerraza}
                  setStep={(direction) => {
                    navigate(t('routes.join') + '?step=' + (step + direction));
                  }}
                  idAliado={idAliado}
                  isEdition={step < currentStep}
                  dataSecundaria={aliadoInfoSecundaria}
                  isMainPage
                />
              );
            case 5:
              return (
                <PasoImagenes
                  premium
                  infoPlan={infoPlan}
                  setStep={(direction) => {
                    navigate(t('routes.join') + '?step=' + (step + direction));
                  }}
                  idAliado={idAliado}
                  isEdition={step < currentStep}
                  currentImages={fotos}
                  initialMain={fotos?.filter((img) => img.main)[0]?.id.toString()}
                  isMainPage
                />
              );
            case 6:
              return (
                <PasoUrl
                  setStep={(direction) => {
                    navigate(t('routes.join') + '?step=' + (step + direction));
                  }}
                  idAliado={idAliado}
                  setUsername={setUsername}
                  dataInicial={aliadoInfoGeneral}
                />
              );
            case 7:
              return (
                <PasoSelectPlan
                  setStep={(direction) => {
                    navigate(t('routes.join') + '?step=' + (step + direction));
                  }}
                  idAliado={idAliado}
                  setInfoPlan={setInfoPlan}
                  isEdition={step < currentStep}
                  stripeSessionId={stripeSessionId}
                  setStripeData={setStripeData}
                  precios={precios}
                  pais={pais}
                  setUsername={setUsername}
                />
              );
            case 8:
              return <PasoConfirmacion username={username} stripeData={stripeData} />;
            default:
              return <h1>Error</h1>;
          }
        })()}
        {step === 8 ||
        step === 0 ||
        countryObject?.countryId === COUNTRY_IDS.USA ||
        pais === COUNTRY_IDS.USA ? null : (
          <p className="afil-help-text">
            {t('afiliadosSteps.paymentPlan.needHelp')}{' '}
            <a
              href={`https://api.whatsapp.com/send?phone=521${process.env.REACT_APP_WHATSAPP}&text=¡Hola!%20Estoy%20registr%C3%A1ndome%20en%20Planodi,%20voy%20en%20el%20paso%20${step}%20y%20tengo%20una%20duda.`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('afiliadosSteps.paymentPlan.clickHelp')}
            </a>
          </p>
        )}
      </div>
    </div>
  );
}

