import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import './ModalApproval.css';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: '1.6rem',
    marginBottom: '1rem',
    borderRadius: '10px',
    fontSize: '1rem',
    backgroundColor: '#8c50ff',
    color: 'white',
    transition: '0.2s',
    padding: '0.5rem 3rem',
    '&:hover': {
      backgroundColor: fade('#8c50ff', 0.9),
      color: 'white',
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

export default function ModalApproval({
  open,
  handleClose,
  handleRejectConfirm,
  halago,
  aliadoId,
}) {
  const mobile = useMediaQuery('(max-width:460px)');

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="xs"
      fullScreen={mobile}
      style={{ minWidth: '320px' }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        style={{
          padding: '1.5rem 2rem',
          position: 'relative',
        }}
      >
        <div className="modal-admin-cancel-icon">
          <CancelOutlinedIcon
            sx={{ fontSize: 70, color: 'rgb(241, 94, 94)' }}
            className="modal-admin-cancel-icon"
          />
        </div>
        <p className="modal-reg-titulo">
          ¿Estás seguro de querer eliminar este posible <b>Aliado</b>?
        </p>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: '0',
            top: '0',
            marginRight: '0.5rem',
            marginTop: '0.4rem',
          }}
        >
          <CloseIcon />
        </IconButton>
        <br />
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1.5rem' }}>
          <button onClick={handleClose} className="modal-admin-cancel-btn">
            Cancelar
          </button>
          <button
            onClick={() => handleRejectConfirm(aliadoId)}
            className="modal-admin-delete-confirmation-btn"
          >
            Eliminar
          </button>
        </div>

        <div className="modal-reg-oauth-wrp">
          <hr style={{ width: '80%', marginBottom: '0.2rem' }} />
          <p className="modal-reg-oauth-O" style={{ marginTop: '0px' }}>
            No podrás rehacer este cambio {halago}.
          </p>
        </div>
      </DialogTitle>
    </Dialog>
  );
}

