import React, { useMemo, useState } from 'react';

import './EditarVideos.css';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io/index';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Form, Formik } from 'formik';
import MyTextField from '../../../componentes/formikInputs/MyTextField/MyTextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { userHeaders } from '../../../Utils/headerSetter';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ErrorMsg from '../../../componentes/ErrorMsg';
import Loading from '../../../componentes/Loading/Loading';
import { MdDeleteForever } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const buttonAdd = makeStyles(() => ({
  button: {
    height: '61px',
    backgroundColor: '#3b3b3b',
    color: 'white',
    padding: '8px 12px',
    cursor: 'pointer',
    borderRadius: '0 10px 10px 0',
    marginLeft: '-1rem',
    marginTop: '-5px',
    '&:hover': {
      backgroundColor: '#464646',
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

export default function EditarVideos({
  isCollapsible,
  isSelected,
  setIsSelected,
  idAliado,
  videos,
  plan,
}) {
  const initialValues = {
    urlVideo: '',
  };
  const [collapse, setCollapse] = useState(!isSelected);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Error subiendo video');
  const { t } = useTranslation('global');

  const classAddVideoButton = buttonAdd();
  const mobile = useMediaQuery('(max-width:960px)');
  const navigate = useNavigate();

  let warningMessage = 'videosWarningMsg1';

  const youtubeValidator = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? true : false;
  };

  const isVideoLimitPlanExceeded = () => {
    switch (plan?.tier) {
      case 'free':
        if (videos.length >= 1) {
          return true;
        }
        break;
      case 'basic':
        if (videos.length >= 1) {
          return true;
        }
        break;

      case 'premium':
        if (videos.length >= 10) {
          return true;
        }
        break;
      case 'pro':
        if (videos.length >= 20) {
          warningMessage = 'videoWarningMsg2';
          return true;
        }
        break;
      default:
        if (videos.length >= 1) {
          return true;
        }
        return false;
    }
  };

  const DisplayVideos = useMemo(
    () =>
      ({
        initialValues,
        handleSubmit,
        handleDelete,
        classAddVideoButton,
        error,
        setError,
        errorMsg,
        videos,
      }) =>
        (
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form className="agregar_videos_container">
              {isVideoLimitPlanExceeded() ? null : (
                <>
                  <p className="editar_red_soc_desc">{t('dashboard.videoLink')}:</p>
                  {error ? (
                    <ErrorMsg
                      setError={setError}
                      errorMsg={errorMsg}
                      style={{ margin: '0 0.2rem 1rem 0.2rem' }}
                    />
                  ) : null}
                  <div className="editar-vid-wrp-inputs">
                    <MyTextField
                      name="urlVideo"
                      type="input"
                      placeholder="https://www.youtube..."
                      style={{ margin: 0, width: mobile ? '100%' : 'calc(100% - 160px)' }}
                    />
                    <Button className={classAddVideoButton.button} type="submit">
                      {t('dashboard.addVideo')}
                    </Button>
                  </div>
                  {videos.length ? (
                    <p className="editar_red_soc_desc" style={{ marginTop: '2rem' }}>
                      {t('dashboard.addedVideos')}:
                    </p>
                  ) : null}
                </>
              )}
              {mobile && isVideoLimitPlanExceeded() ? (
                <p className="agregar-vid-warning-msg">{t(`dashboard.${warningMessage}`)}</p>
              ) : null}
              <div className="editar-vid-videos">
                {videos.map((item) => (
                  <div className="editar-vid-videos-section" key={item}>
                    <div className="editar-vid-videos-section-cover" />
                    <div
                      className="editar-vid-videos-section-delete"
                      onClick={() => handleDelete(item)}
                    >
                      <MdDeleteForever />
                    </div>
                    <iframe
                      width="200"
                      height="150"
                      src={`https://www.youtube.com/embed/${item}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                ))}
              </div>
              {!mobile && isVideoLimitPlanExceeded() ? (
                <p className="agregar-vid-warning-msg">{t(`dashboard.${warningMessage}`)}</p>
              ) : null}
            </Form>
          </Formik>
        ),
    [mobile]
  );

  const handleSubmit = (data) => {
    if (!data.urlVideo) {
      setError(true);
      setErrorMsg(t('dashboard.noVideoAddedWarning'));
      return;
    }
    if (!youtubeValidator(data.urlVideo)) {
      setError(true);
      setErrorMsg(t('dashboard.invalidUrl'));
      return;
    }

    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/updateVideos`,
        {
          idAliado,
          urlVideo: data.urlVideo,
          action: 'upload',
        },
        userHeaders()
      )
      .then(() => {
        setLoading(true);
        navigate(0);
      })
      .catch((e) => {
        setError(true);
        if (e?.response?.data?.msg) {
          setErrorMsg(e?.response?.data?.msg);
        }
      });
  };

  const handleDelete = (urlCode) => {
    setLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/updateVideos`,
        {
          idAliado,
          urlVideo: urlCode,
          action: 'delete',
        },
        userHeaders()
      )
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        setError(true);
        if (e?.response?.data?.msg) {
          setErrorMsg(e?.response?.data?.msg);
        }
      });
  };

  if (loading) {
    return <Loading helperText={t('shared.loading')} />;
  }

  return (
    <>
      {mobile ? (
        <>
          <p
            className="editar_red_soc_titulo"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (collapse) setIsSelected(3);
              setCollapse(!collapse);
            }}
          >
            {isCollapsible && (
              <span>
                {!collapse ? (
                  <IoIosArrowDown style={{ marginRight: '15px', marginBottom: '5px' }} />
                ) : (
                  <IoIosArrowForward style={{ marginRight: '15px', marginBottom: '5px' }} />
                )}
              </span>
            )}
            {t('dashboard.addVideos')}
          </p>

          {(!collapse || !isCollapsible) && (
            <DisplayVideos
              handleSubmit={handleSubmit}
              handleDelete={handleDelete}
              initialValues={initialValues}
              classAddVideoButton={classAddVideoButton}
              error={error}
              setError={setError}
              errorMsg={errorMsg}
              videos={videos}
            />
          )}
        </>
      ) : (
        <>
          <p className="editar_red_soc_titulo">
            {isVideoLimitPlanExceeded() ? t('dashboard.addedVideos') : t('dashboard.addVideos')}:
          </p>
          <DisplayVideos
            handleSubmit={handleSubmit}
            handleDelete={handleDelete}
            initialValues={initialValues}
            classAddVideoButton={classAddVideoButton}
            error={error}
            setError={setError}
            errorMsg={errorMsg}
            videos={videos}
          />
        </>
      )}
    </>
  );
}

