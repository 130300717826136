import './PasoSelectPlan.css';

import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Loading from '../../Loading/Loading';
import Check from '@mui/icons-material/Check';

import { botonesAtrasYAdelante } from '../PasoForm';
import { userHeaders } from '../../../Utils/headerSetter';
import { Grid } from '@material-ui/core';
import { GoVerified } from 'react-icons/go/index';
import { useTranslation } from 'react-i18next';
import { useCountry } from '../../Hooks/CountryContext';

export const planBeneficios = {
  basico: ['details1', 'details2', 'details3', 'details4'],
  premium: ['details1', 'details2', 'details3', 'details4', 'details5', 'details6'],
  pro: ['details1', 'details2', 'details3', 'details4', 'details5', 'details6'],
};

export default function PasoSelectPlan({
  setStep,
  idAliado,
  stripeSessionId,
  setStripeData,
  precios,
  pais,
  setUsername,
}) {
  const { t } = useTranslation('global');
  const buttonTypes = botonesAtrasYAdelante();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Error desconocido en el servidor, inténtalo más tarde');
  const [selectedPlan, setSelectedPlan] = useState(precios?.premium);
  const mobile = useMediaQuery('(max-width:960px)');
  const { countryObject } = useCountry();
  const { countryId, languageId } = countryObject;

  useEffect(() => {
    if (stripeSessionId) {
      axios
        .post(
          `${
            process.env.REACT_APP_ENV === 'development'
              ? process.env.REACT_APP_API_LOCAL
              : process.env.REACT_APP_API_PROD
          }stripe/verify-success-session`,
          { stripeSessionId, idAliado, nuevoAliado: true, precios, languageId },
          userHeaders()
        )
        .then((response) => {
          const { res } = response.data;
          setStripeData(res.stripeData);
          setUsername(res.pathUrl);
          setStep(+1);
        })
        .catch((e) => {
          setLoading(false);
          const { response } = e;
          if (response && response.data && response.data.msg) {
            if (response.data.msg) setErrorMsg(response.data.msg);
          }
          setError(true);
        });
    } else {
      setLoading(false);
    }
  }, []);

  const handleSelectPlan = async () => {
    setLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }stripe/create-checkout-session`,
        { tier: selectedPlan.tier, country: pais },
        userHeaders()
      )
      .then((res) => {
        const { url } = res.data;
        window.location.href = url;
      })
      .catch((e) => {
        setLoading(false);
        const { response } = e;
        if (response && response.data && response.data.msg) {
          if (response.data.msg) setErrorMsg(response.data.msg);
        }
        setError(true);
      });
  };

  return (
    <div style={{ width: '100%' }}>
      {loading ? (
        <div
          style={{
            height: '400px',
            position: 'relative',
            width: '100%',
          }}
        >
          <Loading helperText={t('shared.loading')} />
        </div>
      ) : (
        <div>
          <p className="url-txt-instruc">{t('afiliadosSteps.paymentPlan.title')}</p>
          <p className="url-txt-instruc2">{t('afiliadosSteps.paymentPlan.description')}</p>
          <div className="sel-plan-wrapper">
            <div
              className={`sel-plan-card ${selectedPlan?.tier === 'basic' ? 'active' : null}`}
              onClick={() => setSelectedPlan(precios.basic)}
            >
              <div>
                <div className="sel-plan-header sel-plan-header-gratis">
                  <p className="sel-plan-header-p">{t('afiliadosSteps.paymentPlan.basic.title')}</p>
                </div>
                <div className="sel-plan-contenido">
                  <p className="sel-plan-contenido-periodo">{t('shared.monthly')}</p>
                  <p className="sel-plan-contenido-precio" style={{ marginBottom: '12px' }}>
                    ${precios?.basic?.price} {precios?.basic?.currency.toUpperCase()}
                    <span style={{ fontWeight: 300, fontSize: '0.9rem' }}>
                      {' '}
                      /{t('shared.month')}
                    </span>
                  </p>
                  {planBeneficios.basico.map((item) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'start',
                        justifyContent: 'start',
                        marginBottom: '6px',
                      }}
                      key={item}
                    >
                      <span
                        style={{
                          marginRight: '8px',
                          color: '#7CDE2A',
                          fontSize: '10px',
                        }}
                      >
                        <Check
                          style={{
                            fontSize: '19px',
                          }}
                        />
                      </span>
                      <p style={{ margin: 0 }}>{t(`afiliadosSteps.paymentPlan.basic.${item}`)}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div
              className={`sel-plan-card ${selectedPlan?.tier === 'premium' ? 'active' : null}`}
              onClick={() => setSelectedPlan(precios?.premium)}
            >
              <div>
                <div className="sel-plan-header sel-plan-header-premium">
                  <p className="sel-plan-header-p">
                    {t('afiliadosSteps.paymentPlan.premium.title')}
                  </p>
                </div>
                <div className="sel-plan-contenido">
                  <p className="sel-plan-contenido-periodo">{t('shared.monthly')}</p>
                  <p className="sel-plan-contenido-precio" style={{ marginBottom: '12px' }}>
                    ${precios?.premium?.price} {precios?.premium?.currency.toUpperCase()}
                    <span style={{ fontWeight: 300, fontSize: '0.9rem' }}>
                      {' '}
                      /{t('shared.month')}
                    </span>
                  </p>
                  {planBeneficios.premium.map((item, index) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'start',
                        justifyContent: 'start',
                        marginBottom: '6px',
                      }}
                      key={item}
                    >
                      <span
                        style={{
                          marginRight: '8px',
                          color: '#51d364',
                          fontSize: '10px',
                        }}
                      >
                        {index === planBeneficios.premium.length - 1 ? (
                          <GoVerified style={{ fontSize: '19px', color: '#8c50ff' }} />
                        ) : (
                          <Check style={{ fontSize: '19px' }} />
                        )}
                      </span>
                      <p style={{ margin: 0 }}>{t(`afiliadosSteps.paymentPlan.premium.${item}`)}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div
              className={`sel-plan-card ${selectedPlan?.tier === 'pro' ? 'active' : null}`}
              onClick={() => setSelectedPlan(precios?.pro)}
            >
              <div>
                <div className="sel-plan-header sel-plan-header-pro">
                  <p className="sel-plan-header-p">{t('afiliadosSteps.paymentPlan.pro.title')}</p>
                </div>
                <div className="sel-plan-contenido">
                  <p className="sel-plan-contenido-periodo">{t('shared.monthly')}</p>
                  <p className="sel-plan-contenido-precio" style={{ marginBottom: '12px' }}>
                    ${precios?.pro?.price} {precios?.pro?.currency.toUpperCase()}
                    <span style={{ fontWeight: 300, fontSize: '0.9rem' }}>
                      {' '}
                      /{t('shared.month')}
                    </span>
                  </p>
                  {planBeneficios.pro.map((item, index) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'start',
                        justifyContent: 'start',
                        marginBottom: '6px',
                      }}
                      key={item}
                    >
                      <span
                        style={{
                          marginRight: '8px',
                          color: '#51d364',
                          fontSize: '10px',
                        }}
                      >
                        {index === planBeneficios.pro.length - 1 ? (
                          <GoVerified style={{ fontSize: '19px', color: '#FFBC1D' }} />
                        ) : (
                          <Check style={{ fontSize: '19px' }} />
                        )}
                      </span>
                      <p style={{ margin: 0 }}>{t(`afiliadosSteps.paymentPlan.pro.${item}`)}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <p className="sel-plan-curr-sel">
            {t('afiliadosSteps.paymentPlan.selectedPlan')}:{' '}
            <span style={{ color: '#8c50ff' }}>{selectedPlan?.tier.toUpperCase()}</span>
          </p>
          <Grid item container style={{ marginTop: '1.5rem', marginBottom: '2rem' }}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                className={buttonTypes.cancelButton}
                style={
                  mobile
                    ? { width: '100%', marginBottom: '1rem' }
                    : { width: '30%', marginRight: '1rem' }
                }
                onClick={() => setStep(-1)}
              >
                {t('shared.back')}
              </Button>
              <Button
                className={buttonTypes.button}
                style={mobile ? { width: '100%' } : { width: '30%' }}
                onClick={handleSelectPlan}
              >
                {t('shared.continue')}
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

