import './ManageSubscription.css';
import { GoVerified } from 'react-icons/go/index';
import { Check } from '@material-ui/icons';
import { planBeneficios } from '../pasos/PasoPlan/PasoSelectPlan';
import { useTranslation } from 'react-i18next';

function ManageSubPlans({
  handleUpdatePlan,
  showBasico = true,
  showPremium = true,
  showPro = true,
  precios,
}) {
  const { t } = useTranslation('global');

  return (
    <>
      <p className="sel-plan-mejora">{t('dashboard.planMoreVisibility')}:</p>

      <div className="manage-sub-wrp">
        {showBasico && (
          <div className="manage-sub-sel-plan-card no-pointer">
            <div className="sel-plan-header sel-plan-header-gratis">
              <p className="sel-plan-header-p">{t('afiliadosSteps.paymentPlan.basic.title')}</p>
            </div>
            <div className="sel-plan-contenido">
              <div>
                {planBeneficios.basico.map((item) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'start',
                      justifyContent: 'start',
                      marginBottom: '6px',
                    }}
                    key={item}
                  >
                    <span
                      style={{
                        marginRight: '8px',
                        color: '#51d364',
                        fontSize: '10px',
                      }}
                    >
                      <Check
                        style={{
                          fontSize: '19px',
                        }}
                      />
                    </span>
                    <p style={{ margin: 0 }}>{t(`afiliadosSteps.paymentPlan.basic.${item}`)}</p>
                  </div>
                ))}
              </div>
              <div
                onClick={() => {
                  handleUpdatePlan(precios.basic.tier);
                }}
              >
                <hr style={{ opacity: 0.1 }} />
                <div className="manage-sub-mejorar-plan">
                  {t('afiliadosSteps.paymentPlan.upgradePlan')} ${precios.basic.price}{' '}
                  {precios.basic.currency.toUpperCase()} / {t('shared.month')}
                </div>
              </div>
            </div>
          </div>
        )}
        {showPremium && (
          <div className="manage-sub-sel-plan-card no-pointer">
            <div className="sel-plan-header sel-plan-header-premium">
              <p className="sel-plan-header-p">{t('afiliadosSteps.paymentPlan.premium.title')}</p>
            </div>
            <div className="sel-plan-contenido">
              <div>
                {planBeneficios.premium.map((item, index) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'start',
                      justifyContent: 'start',
                      marginBottom: '6px',
                    }}
                    key={item}
                  >
                    <span
                      style={{
                        marginRight: '8px',
                        color: '#51d364',
                        fontSize: '10px',
                      }}
                    >
                      {index === planBeneficios.premium.length - 1 ? (
                        <GoVerified style={{ fontSize: '19px', color: '#8c50ff' }} />
                      ) : (
                        <Check style={{ fontSize: '19px' }} />
                      )}
                    </span>
                    <p style={{ margin: 0 }}>{t(`afiliadosSteps.paymentPlan.premium.${item}`)}</p>
                  </div>
                ))}
              </div>
              <div
                onClick={() => {
                  handleUpdatePlan(precios.premium.tier);
                }}
              >
                <hr style={{ opacity: 0.1 }} />
                <div className="manage-sub-mejorar-plan">
                  {t('afiliadosSteps.paymentPlan.upgradePlan')} ${precios.premium.price}{' '}
                  {precios.premium.currency.toUpperCase()} / {t('shared.month')}
                </div>
              </div>
            </div>
          </div>
        )}

        {showPro && (
          <div className="manage-sub-sel-plan-card no-pointer">
            <div className="sel-plan-header sel-plan-header-pro">
              <p className="sel-plan-header-p">{t('afiliadosSteps.paymentPlan.pro.title')}</p>
            </div>
            <div className="sel-plan-contenido">
              <p className="manage-sub-reocmendado">
                {t('afiliadosSteps.paymentPlan.recommended')}
              </p>
              {planBeneficios.pro.map((item, index) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'start',
                    marginBottom: '6px',
                  }}
                  key={item}
                >
                  <span
                    style={{
                      marginRight: '8px',
                      color: '#51d364',
                      fontSize: '10px',
                    }}
                  >
                    {index === planBeneficios.pro.length - 1 ? (
                      <GoVerified style={{ fontSize: '19px', color: '#FFBC1D' }} />
                    ) : (
                      <Check style={{ fontSize: '19px' }} />
                    )}
                  </span>
                  <p style={{ margin: 0 }}>{t(`afiliadosSteps.paymentPlan.pro.${item}`)}</p>
                </div>
              ))}
              <hr style={{ opacity: 0.1 }} />
              <div
                className="manage-sub-mejorar-plan"
                onClick={() => {
                  handleUpdatePlan(precios.pro.tier);
                }}
              >
                {t('afiliadosSteps.paymentPlan.upgradePlan')} ${precios.pro.price}{' '}
                {precios.pro.currency.toUpperCase()} / {t('shared.month')}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ManageSubPlans;
