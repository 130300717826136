import { useMutation, useQueryClient } from 'react-query';
import { userHeaders } from '../../Utils/headerSetter';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

// Hook for upgradin plan
export default function useUpgradePlan(pathName) {
  const queryClient = useQueryClient();
  const { t } = useTranslation('global');
  const upgradePlan = async ({ stripeId, newTier, country }) => {
    const { data } = await axios.post(
      `${
        process.env.REACT_APP_ENV === 'development'
          ? process.env.REACT_APP_API_LOCAL
          : process.env.REACT_APP_API_PROD
      }stripe/upgrade-plan`,
      { customerId: stripeId, newTier, country },
      userHeaders()
    );
    const { success, msg } = data;
    if (success) {
      toast.success(t('toastCard.upgradedPlan'), {
        position: 'bottom-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return success;
    } else {
      throw new Error(msg);
    }
  };

  return useMutation(upgradePlan, {
    // When mutate is called:
    onMutate: async ({ newTier }) => {
      await queryClient.cancelQueries(['aliadoInfoDashboard', pathName]);
      const previousData = queryClient.getQueryData(['aliadoInfoDashboard', pathName]);

      if (previousData) {
        // Deep clone the object, otherwise the objects inside will have the same reference in memory
        const newData = JSON.parse(JSON.stringify(previousData));
        newData.plan.tier = newTier;
        queryClient.setQueryData(['aliadoInfoDashboard', pathName], newData);
      }
      return { previousData };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_error, _values, context) => {
      queryClient.setQueryData(['aliadoInfoDashboard', pathName], context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['aliadoInfoDashboard', pathName]);
    },
  });
}

