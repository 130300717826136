import React from 'react';
import './Stepper2.css';
import { useTranslation } from 'react-i18next';

export default function Stepper2({ numSteps, currentStep }) {
  const { t } = useTranslation('global');
  return (
    <div className="div-wrp-stepper-afil">
      <p className="afil-stepper-text-progress">{`${t('afiliadosSteps.step')} ${currentStep} ${t(
        'afiliadosSteps.of'
      )} ${numSteps}`}</p>
      <div className="afil-stepper-wrp">
        {[...Array(numSteps).keys()].map((item) => (
          <div
            key={item}
            className={`${currentStep === item + 1 ? 'afil-active-step' : null} afil-generic-step`}
          />
        ))}
      </div>
    </div>
  );
}

