import { SiteMaps } from '../../../componentes/SiteMap/SiteMap';

const mainPageContent = [
  { name: 'mainPage', route: '' },
  { name: 'registerPage', route: 'sign-up' },
  { name: 'loginPage', route: 'login' },
  { name: 'explorePage', route: 'search' },
  { name: 'affiliatePage', route: 'join' },
  { name: 'informationPage', route: 'information' },
  { name: 'termsAndConditionsPage', route: 'documents?id=termsAndConditions' },
  { name: 'privacyPolicyPage', route: 'documents?id=privacyPolicy' },
];

const MainPagesSitemap = () => {
  return (
    <>
      <SiteMaps title={'mainPages'} content={mainPageContent} />
    </>
  );
};

export default MainPagesSitemap;
