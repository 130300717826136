import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import MyTextField from '../../../../componentes/formikInputs/MyTextField/MyTextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import axios from 'axios';
import { userAuthError, userLoginSuccess } from '../../../../actions/userActions';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import './ModalLogin.css';
import { userHeaders } from '../../../../Utils/headerSetter';
import ErrorMsg from '../../../../componentes/ErrorMsg';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../../../Utils/regexValidators';
import { useCountry } from '../../../../componentes/Hooks/CountryContext';
import { useGoogleLogin } from '@react-oauth/google';
import Loading from '../../../../componentes/Loading/Loading';
import GoogleButton from '../../../../componentes/GoogleButton/GoogleButton';

export const useStyles = makeStyles(() => ({
  button: {
    marginTop: '1.6rem',
    marginBottom: '1rem',
    borderRadius: '10px',
    fontSize: '1rem',
    backgroundColor: '#8c50ff',
    color: 'white',
    transition: '0.2s',
    padding: '0.5rem 3rem',
    '&:hover': {
      backgroundColor: fade('#8c50ff', 0.9),
    },
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      backgroundColor: fade('#8c50ff', 0.5),
    },
  },
}));

export default function ModalLogin({ handleClose, open, afterLogin, openRegister }) {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const { countryObject } = useCountry();
  const [error, setError] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('login.unknownError');
  const [booleanRecoverPsw, setBooleanRecoverPsw] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const mobile = useMediaQuery('(max-width:460px)');
  const { languageId } = countryObject;

  const validationSchema = yup.object({
    email: yup.string().required(t('yup.required')),
    password: yup.string().required(t('yup.required')),
  });

  const closeModal = () => {
    setLoadingGoogle(false);
    handleClose();
  };

  const onFailure = (error) => {
    setLoadingGoogle(false);
    console.log('Login Failed:', error);
  };

  const googleResponse = async (response) => {
    // Check if a token was recieved and send it to our API:
    if (response?.access_token) {
      axios
        .post(
          `${
            process.env.REACT_APP_ENV === 'development'
              ? process.env.REACT_APP_API_LOCAL
              : process.env.REACT_APP_API_PROD
          }user/userAuth`,
          { token: response.access_token },
          userHeaders()
        )
        .then((res) => {
          const { token, user } = res.data;
          dispatch(userLoginSuccess(token, user));
          afterLogin(user);
          closeModal();
        })
        .catch((e) => {
          const { response } = e;
          if (response && response.data && response.data.msg) {
            if (response.data.msg) setErrorMsg(response.data.msg);
          }
          setError(true);
        });
    }
  };

  const handleRequestRecoverPassword = (email) => {
    setLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/requestRecoverPassword`,
        {
          email,
          languageId,
        }
      )
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          toast.dismiss();
          toast.success(t(res.data.code), {
            position: 'bottom-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }, 500);
      })
      .catch((e) => {
        setTimeout(() => {
          setLoading(false);
          setError(true);
          setErrorMsg(e.response.data.code);
        }, 1500);

        setTimeout(() => {
          setError(false);
        }, 6000);
      });
  };

  const continueWithGoogle = useGoogleLogin({
    onSuccess: googleResponse,
    onError: onFailure,
    onNonOAuthError: onFailure,
  });

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="xs"
      fullScreen={mobile}
      style={{ minWidth: '320px' }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={closeModal}
        style={{
          padding: '0.8rem',
          position: 'relative',
        }}
      >
        {booleanRecoverPsw ? (
          <IconButton
            aria-label="close"
            onClick={() => {
              setError(false);
              setBooleanRecoverPsw(false);
            }}
            style={{
              position: 'absolute',
              left: '0',
              top: '0',
              marginLeft: '0.5rem',
              marginTop: '0.4rem',
            }}
          >
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>
        ) : null}
        <p className="modal-reg-titulo">
          {booleanRecoverPsw ? t('login.recoverPsw.title') : t('login.title')}
        </p>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          style={{
            position: 'absolute',
            right: '0',
            top: '0',
            marginRight: '0.5rem',
            marginTop: '0.4rem',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div className="modal-reg-wrp">
          {loadingGoogle && (
            <div className="model-reg-loading">
              <Loading helperText="" />
            </div>
          )}
          <div className="modal-reg-oauth-wrp">
            {booleanRecoverPsw ? null : (
              <>
                <div className="modal-reg-oauth-btn" onClick={() => setLoadingGoogle(true)}>
                  <GoogleButton onClick={() => continueWithGoogle()} disabled={loadingGoogle} />
                </div>

                <hr style={{ width: '80%', margin: '0' }} />
              </>
            )}
            <p className="modal-reg-oauth-O" style={{ marginTop: '10px', textAlign: 'center' }}>
              {booleanRecoverPsw ? t('login.recoverPsw.details') : t('login.optionalLogin')}
            </p>
          </div>
          {/* )} */}
          <div>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                const { password, email } = data;
                axios
                  .post(
                    `${
                      process.env.REACT_APP_ENV === 'development'
                        ? process.env.REACT_APP_API_LOCAL
                        : process.env.REACT_APP_API_PROD
                    }user/login`,
                    {
                      email,
                      password,
                    }
                  )
                  .then((res) => {
                    const { token, user, hasToChangePassword } = res.data;
                    dispatch(userLoginSuccess(token, user));
                    setSubmitting(false);
                    if (hasToChangePassword) {
                      navigate(t('routes.changePassword'));
                    } else {
                      if (!user.isVerified) {
                        toast.dismiss();
                        toast.warn(t('login.verificationMsg'), {
                          position: 'bottom-right',
                          autoClose: 10000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      } else {
                        afterLogin(user);
                      }
                      closeModal();
                    }
                  })
                  .catch((e) => {
                    const { response } = e;
                    if (response && response.data && response.data.msg) {
                      if (response.data.msg) setErrorMsg(response.data.msg);
                    }
                    dispatch(userAuthError());
                    setError(true);
                    setSubmitting(false);
                  });
              }}
            >
              {({ values, isSubmitting }) => (
                <Form autoComplete="off">
                  <Grid container>
                    {error ? (
                      <ErrorMsg
                        setError={setError}
                        errorMsg={t(errorMsg)}
                        style={{ margin: '0 0 1rem 0', width: '100%' }}
                      />
                    ) : null}
                    <Grid item xs={12}>
                      <MyTextField name="email" type="input" placeholder="Email" />
                    </Grid>
                    <Grid item xs={12}>
                      {booleanRecoverPsw ? null : (
                        <MyTextField
                          name="password"
                          type="password"
                          placeholder={t('shared.password')}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                      {booleanRecoverPsw ? (
                        <Button
                          className={classes.button}
                          disabled={
                            !values.email || error || !validateEmail(values.email) || loading
                          }
                          onClick={() => {
                            handleRequestRecoverPassword(values.email);
                          }}
                        >
                          {loading ? t('shared.loading') : t('login.recoverPsw.recoverPsw')}
                        </Button>
                      ) : (
                        <Button
                          className={classes.button}
                          type="submit"
                          disabled={isSubmitting}
                          style={loadingGoogle ? { pointerEvents: 'none', opacity: '0.5' } : null}
                        >
                          {t('login.login')}
                        </Button>
                      )}
                      {booleanRecoverPsw ? null : (
                        <>
                          <p>
                            <span
                              className="modal-reg-login-redirect-forgot-psw-link"
                              onClick={() => {
                                setBooleanRecoverPsw(true);
                              }}
                            >
                              {t('login.forgotPsw')}
                            </span>
                          </p>

                          <p className="modal-reg-login-redirect">
                            {t('login.noAccountQuestion')}{' '}
                            <span
                              className="modal-reg-login-redirect-link"
                              onClick={() => {
                                openRegister();
                                closeModal();
                              }}
                            >
                              {t('shared.signup')}
                            </span>
                          </p>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

ModalLogin.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  afterLogin: PropTypes.func,
  openRegister: PropTypes.func,
};

ModalLogin.defaultProps = {
  afterLogin: () => {},
  openRegister: () => {},
};

