import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

function BuscadorTabs({ packagesPage }) {
  const { t } = useTranslation('global');

  return (
    <div className="buscador-tabs-wrp">
      <a
        className={clsx('noselect a-clean buscador-tabs-p', {
          active: !packagesPage,
        })}
        href={`${window.location.origin}${t('routes.explore')}`}
      >
        {t('explore.profiles')}
      </a>
      <a
        className={clsx('noselect a-clean buscador-tabs-p', {
          active: packagesPage,
        })}
        href={`${window.location.origin}${t('routes.packages')}`}
      >
        {t('explore.packages')}
      </a>
    </div>
  );
}

export default BuscadorTabs;

