import './ModalCountry.css';
import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

import {
  COUNTRY_IDS,
  LANGUAGE_IDS,
  useCountry,
} from '../../../../componentes/Hooks/CountryContext';
import { fade, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  button: {
    margin: '1.5rem 0 1rem 0',
    borderRadius: '5px',
    fontSize: '14px',
    backgroundColor: '#3b3b3b',
    color: 'white',
    transition: '0.2s',
    padding: '0.5rem 2rem',
    '&:hover': {
      backgroundColor: fade('#3b3b3b', 0.9),
    },
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      backgroundColor: fade('#3b3b3b', 0.5),
      color: 'white',
    },
  },
}));

export default function ModalCountry({ handleClose, open }) {
  const { t, i18n } = useTranslation('global');

  const mobile = useMediaQuery('(max-width:460px)');
  const classes = useStyles();
  const { countryObject, updateCountry } = useCountry();

  const [newCountryObject, setNewCountryObject] = useState(countryObject);

  useEffect(() => {
    setNewCountryObject(countryObject);
  }, [countryObject]);

  const onConfirmHandler = () => {
    updateCountry(newCountryObject);
    handleClose();
  };

  const onCloseHandler = () => {
    setNewCountryObject(countryObject);
    handleClose();
  };

  const onClickHandler = ({ countryId, languageId }) => {
    setNewCountryObject({ countryId, languageId });
  };

  const isActive = ({ countryId, languageId }) => {
    let style = '';
    if (JSON.stringify(newCountryObject) === JSON.stringify({ countryId, languageId })) {
      style = 'active';
    }
    return style;
  };

  return (
    <Dialog
      onClose={onCloseHandler}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="xs"
      fullScreen={mobile}
      style={{ minWidth: '320px' }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={onCloseHandler}
        style={{
          padding: '0.8rem',
          position: 'relative',
        }}
      >
        <p className="modal-country-title">{t('country.title')}</p>
        <IconButton
          aria-label="close"
          onClick={onCloseHandler}
          style={{
            position: 'absolute',
            right: '0',
            top: '0',
            marginRight: '0.5rem',
            marginTop: '0.4rem',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div className="modal-country-subtitle-wrapper">
          <p className="modal-country-subtitle">{t('country.subtitle.region')}</p>
        </div>

        <div className="modal-country-row">
          <button
            className={`modal-country-card ${isActive({
              countryId: COUNTRY_IDS.MEXICO,
              languageId: newCountryObject.languageId,
            })}`}
            onClick={() =>
              onClickHandler({
                countryId: COUNTRY_IDS.MEXICO,
                languageId: newCountryObject.languageId,
              })
            }
          >
            {t('shared.mx')}
          </button>

          <button
            className={`modal-country-card ${isActive({
              countryId: COUNTRY_IDS.USA,
              languageId: newCountryObject.languageId,
            })}`}
            onClick={() =>
              onClickHandler({
                countryId: COUNTRY_IDS.USA,
                languageId: newCountryObject.languageId,
              })
            }
          >
            {t('shared.usa')}
          </button>
        </div>

        <div className="modal-country-subtitle-wrapper">
          <p className="modal-country-subtitle">{t('country.subtitle.language')}</p>
        </div>

        <div className="modal-country-row">
          <button
            className={`modal-country-card ${isActive({
              countryId: newCountryObject.countryId,
              languageId: LANGUAGE_IDS.SPANISH,
            })}`}
            onClick={() =>
              onClickHandler({
                countryId: newCountryObject.countryId,
                languageId: LANGUAGE_IDS.SPANISH,
              })
            }
          >
            {t('shared.spanish')}
          </button>

          <button
            className={`modal-country-card ${isActive({
              countryId: newCountryObject.countryId,
              languageId: LANGUAGE_IDS.ENGLISH,
            })}`}
            onClick={() =>
              onClickHandler({
                countryId: newCountryObject.countryId,
                languageId: LANGUAGE_IDS.ENGLISH,
              })
            }
          >
            {t('shared.english')}
          </button>
        </div>

        <div className="modal-country-button-wrapper">
          <Button className={classes.button} type="submit" onClick={onConfirmHandler}>
            {t('country.button')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

ModalCountry.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

