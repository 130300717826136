import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

import { userHeaders } from '../../../Utils/headerSetter';
import Loading from '../../Loading/Loading';
import FormCategoria from './FormCategoria';
import ErrorMsg from '../../ErrorMsg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { crearTagsPasoCategoriasAfiliarForm } from '../../../Utils/utilFunctions';

export default function PasoCategoria({
  setStep,
  userID,
  setIsTerraza,
  setIdAliado,
  isEdition,
  dataPrincipal,
  isCollapsible,
  isTerraza,
  isSelected,
  isMainPage,
  setIsSelected,
  isAdminPage,
  submitStepAdmin,
  paisPaso0,
  backAdminPage,
}) {
  const { t } = useTranslation('global');
  const [locations, setLocations] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [error, setError] = useState(false);
  const [loadign, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(t('shared.unknownError'));
  const [collapse, setCollapse] = useState(!isSelected);

  const validationSchema = Yup.object({
    nombre: Yup.string().required(t('yup.required')),
    selectedLocations: Yup.number().min(0, t('yup.validate')).required(t('yup.required')),
    phoneNumber: Yup.string().test('phoneNumber', t('yup.phone'), (value) => {
      if (!value) return true;
      if (value.substring(0, 1) === '+') return false;
      return /^\d{10}$/.test(value);
    }),
    whatsapp: Yup.string().test('whatsapp', t('yup.phone'), (value) => {
      if (!value) return true;
      if (value.substring(0, 1) === '+') return false;
      return /^\d{10}$/.test(value);
    }),
    categoria: Yup.array()
      .of(Yup.number())
      .min(1, t('yup.category'))
      .required(t('yup.category'))
      .test(t('yup.category'), t('yup.category'), (value) => {
        return !value.includes(-1);
      }),
    email: Yup.string().test('email', t('yup.email'), (value) => {
      if (value == null || value.trim() === '' || !value) return true;
      const emailRegex = /^(?=.*@)/;
      return emailRegex.test(value);
    }),
    webSite: Yup.string().test('webSite', t('yup.webSite'), (value) => {
      if (value == null || value.trim() === '' || !value) return true;
      const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9#?&=._-]+)*\/?$/;
      return urlRegex.test(value);
    }),
  });

  const initialValues = {
    nombre: isEdition ? dataPrincipal?.nombre : '',
    selectedLocations: isEdition
      ? isAdminPage
        ? dataPrincipal?.selectedLocationsAdmin?.map((item) => item.idLocation)
        : dataPrincipal?.selectedLocations?.map((item) => item.idLocation)
      : [-1],
    pais: paisPaso0 ? paisPaso0 : dataPrincipal.paisId,
    phoneNumber: isEdition && dataPrincipal?.telefono ? dataPrincipal?.telefono : '',
    whatsapp: isEdition && dataPrincipal?.whatsapp ? dataPrincipal?.whatsapp : '',
    categoria: isEdition
      ? isAdminPage
        ? dataPrincipal?.categoriasAdmin?.map((item) => item?.idCategoria)
        : dataPrincipal?.categorias?.map((item) => item?.idCategoria)
      : [-1],
    email: isEdition && dataPrincipal?.email ? dataPrincipal?.email : '',
    webSite: isEdition && dataPrincipal?.webSite ? dataPrincipal?.webSite : '',
  };

  useEffect(() => {
    if (isCollapsible && !isSelected) setCollapse(true);
  }, [isCollapsible, isSelected]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/getForm1Info`,
        userHeaders(false)
      )
      .then((res) => {
        const { locations, categorias } = res.data;
        locations.unshift({ id: -1, name: t('afiliadosSteps.category.location') });
        setLocations(locations);
        categorias.unshift({ id: -1, key: 'afiliadosSteps.category.category' });
        setCategorias(categorias);
        setLoading(false);
      })
      .catch((e) => {
        const { response } = e;
        if (response && response.data && response.data.msg) {
          if (response.data.msg) setErrorMsg(response.data.msg);
        }
        setError(true);
        setLoading(false);
      });
  }, []);

  const comenzarDeNuevo = () => {
    if (isAdminPage) {
      backAdminPage();
    } else {
      setLoading(true);
      axios
        .post(
          `${
            process.env.REACT_APP_ENV === 'development'
              ? process.env.REACT_APP_API_LOCAL
              : process.env.REACT_APP_API_PROD
          }user/deleteAliadoEmpezado`,
          {
            idAliado: dataPrincipal ? dataPrincipal.id : null,
          },
          userHeaders()
        )
        .then(() => {
          window.location.reload();
        })
        .catch((e) => {
          const { response } = e;
          if (response && response.data && response.data.msg) {
            if (response.data.msg) setErrorMsg(response.data.msg);
          }
          setError(true);
          setLoading(false);
        });
    }
  };

  const userRegistered = (data) => {
    setLoading(true);
    const { categoria, selectedLocations, nombre, phoneNumber, whatsapp, pais, email, webSite } =
      data;
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/guardarPasoCategoria`,
        {
          id: userID,
          aliadoId: dataPrincipal ? dataPrincipal.id : null,
          categoria,
          selectedLocations,
          nombre,
          phoneNumber,
          whatsapp: whatsapp ? whatsapp : null,
          webSite: webSite ? webSite : null,
          tags: crearTagsPasoCategoriasAfiliarForm(
            t,
            nombre,
            locations,
            selectedLocations,
            categorias,
            categoria,
            pais
          ),
          pais: parseInt(pais),
          email,
        },
        userHeaders(false)
      )
      .then((res) => {
        const { idAliado } = res.data;
        setIsTerraza(categoria.includes(3));
        setIdAliado(idAliado);
        setLoading(false);
        setStep(2);
      })
      .catch((e) => {
        const { response } = e;
        if (response && response.data && response.data.msg) {
          if (response.data.msg) setErrorMsg(response.data.msg);
        }
        setError(true);
        setLoading(false);
      });
  };

  const adminRegistered = (data) => {
    const { categoria, selectedLocations, nombre, pais } = data;
    submitStepAdmin(1, {
      ...data,
      // Estos dos campos son un workaround para el registro desde admin dashboard
      selectedLocationsAdmin: selectedLocations.map((id) => ({
        idLocation: id,
      })),
      categoriasAdmin: categoria.map((id) => ({
        idCategoria: id,
      })),
      tags: crearTagsPasoCategoriasAfiliarForm(
        t,
        nombre,
        locations,
        selectedLocations,
        categorias,
        categoria,
        pais
      ),
      pais: parseInt(pais),
    });
  };

  return (
    <div
      style={
        isEdition
          ? { display: 'flex', width: '100%' }
          : { display: 'flex', width: '100%', marginBottom: '2rem' }
      }
    >
      {loadign ? (
        <div
          style={{
            height: '400px',
            position: 'relative',
            width: '100%',
          }}
        >
          <Loading helperText={t('shared.loading')} />
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          {error ? <ErrorMsg setError={setError} errorMsg={errorMsg} /> : null}
          <p
            className={isEdition ? 'url-txt-instruc-ed-neg' : 'url-txt-instruc'}
            onClick={() => {
              if (collapse) setIsSelected(0);
              setCollapse(!collapse);
            }}
          >
            {isCollapsible && (
              <span>
                {!collapse ? (
                  <IoIosArrowDown style={{ marginRight: '15px', marginBottom: '5px' }} />
                ) : (
                  <IoIosArrowForward style={{ marginRight: '15px', marginBottom: '5px' }} />
                )}
              </span>
            )}
            {t('afiliadosSteps.category.stepTitle')}
          </p>

          {(!collapse || !isCollapsible) && (
            <div>
              <Formik
                initialValues={initialValues}
                onSubmit={isAdminPage ? adminRegistered : userRegistered}
                validationSchema={validationSchema}
                component={({ values, setFieldValue }) => (
                  <FormCategoria
                    isEdition={isEdition}
                    values={values}
                    setFieldValue={setFieldValue}
                    categorias={categorias}
                    locations={locations}
                    isTerraza={isTerraza}
                    isMainPage={isMainPage}
                    comenzarDeNuevo={comenzarDeNuevo}
                    isAdminPage={isAdminPage}
                  />
                )}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

PasoCategoria.propTypes = {
  setStep: PropTypes.func,
  userID: PropTypes.number,
  isEdition: PropTypes.bool,
  setIsTerraza: PropTypes.func,
  setIdAliado: PropTypes.func,
  setIsSelected: PropTypes.func,
  submitStepAdmin: PropTypes.func,
  paisPaso0: PropTypes.string,
  dataPrincipal: PropTypes.shape({
    id: PropTypes.number,
    usuario: PropTypes.number,
    tipo: PropTypes.number,
    category: PropTypes.string,
    membresia: PropTypes.number,
    nombre: PropTypes.string,
    whatsapp: PropTypes.string,
    estadoId: PropTypes.number,
    estado: PropTypes.string,
    paisId: PropTypes.number,
    pais: PropTypes.string,
    verificado: PropTypes.number,
  }),
  isCollapsible: PropTypes.bool,
  isTerraza: PropTypes.bool,
  isMainPage: PropTypes.bool,
  isSelected: PropTypes.bool,
  isAdminPage: PropTypes.bool,
};

PasoCategoria.defaultProps = {
  setStep: () => null,
  setIsTerraza: () => null,
  setIdAliado: () => null,
  setIsSelected: () => null,
  submitStepAdmin: () => null,
  isEdition: false,
  isCollapsible: false,
  isTerraza: false,
  dataPrincipal: null,
  isMainPage: false,
  isSelected: false,
  isAdminPage: false,
  userID: null,
};

