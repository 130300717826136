import { useMutation, useQueryClient } from 'react-query';
import { userHeaders } from '../../Utils/headerSetter';
import axios from 'axios';

// Hook for stopping or resuming subscriptions
export default function useCancelPlan() {
  const queryClient = useQueryClient();

  const cancelSub = async ({ stripeId, stop }) => {
    const { data } = await axios.post(
      `${
        process.env.REACT_APP_ENV === 'development'
          ? process.env.REACT_APP_API_LOCAL
          : process.env.REACT_APP_API_PROD
      }stripe/cancel-sub`,
      { customerId: stripeId, stop },
      userHeaders()
    );
    const { success, msg } = data;
    if (success) {
      return success;
    } else {
      throw new Error(msg);
    }
  };

  // Tag optimistic update
  return useMutation(cancelSub, {
    // When mutate is called:
    onMutate: async ({ pathName, stop }) => {
      await queryClient.cancelQueries(['aliadoInfoDashboard', pathName]);
      const previousData = queryClient.getQueryData(['aliadoInfoDashboard', pathName]);

      if (previousData) {
        // Deep clone the object, otherwise the objects inside will have the same reference in memory
        const newData = JSON.parse(JSON.stringify(previousData));
        newData.plan.cancel_at_period_end = stop ? 1 : 0;
        queryClient.setQueryData(['aliadoInfoDashboard', pathName], newData);
      }
      return { previousData };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_error, { pathName }, context) => {
      queryClient.setQueryData(['aliadoInfoDashboard', pathName], context?.previousData);
    },
    // Always refetch after error or success:
    onSettled: (_data, _error, { pathName }) => {
      queryClient.invalidateQueries(['aliadoInfoDashboard', pathName]);
    },
  });
}
