const RenderElement = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes} className="rich-text-editor-text-size">
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes} className="rich-text-editor-text-size">
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <p className="rich-text-editor-title1" style={style} {...attributes}>
          {children}
        </p>
      );
    case 'heading-two':
      return (
        <p className="rich-text-editor-title2" style={style} {...attributes}>
          {children}
        </p>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes} className="rich-text-editor-text-size">
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes} className="rich-text-editor-text-size">
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes} className="rich-text-editor-text-size">
          {children}
        </p>
      );
  }
};

export default RenderElement;

