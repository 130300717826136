import { createContext, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Crear un contexto para el buscador
const BuscadorContext = createContext();

// Proveedor del contexto para manejar el estado del buscador
export const BuscadorProvider = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('global');

  // Manejamos el URL como unica fuente de verdad, el url es el "estado".
  const freeFilter = searchParams.get('free') || '';
  const locationFilter = searchParams.get('location')?.split(',') || [];
  const categoryFilter = searchParams.get('category')?.split(',') || [];
  const eventFilter = searchParams.get('event')?.split(',') || [];

  // se filtran las opciones del url para quitar cualquier cosa fake insertada a mano
  const cleanFilters = (categoryOptions, eventOptions, locationOptions) => {
    const cleanCategories = categoryFilter.filter((i) =>
      categoryOptions.map((c) => c.id).includes(i)
    );

    const cleanEvents = eventFilter.filter((i) => eventOptions.map((e) => e.id).includes(i));

    const cleanLocations = locationFilter
      .filter((i) => locationOptions.map((e) => e.id).includes(i))
      .slice(0, 1);

    _updateUrl(freeFilter, cleanLocations, cleanCategories, cleanEvents);
  };

  const _updateUrl = (free, location, category, event) => {
    const paramObj = {};
    if (free) {
      paramObj.free = free;
    }
    if (location.length) {
      paramObj.location = location;
    }
    if (category.length) {
      paramObj.category = category;
    }
    if (event.length) {
      paramObj.event = event;
    }
    const basePath = window.location.origin + t('routes.explore');
    const queryString = new URLSearchParams(paramObj).toString();
    if (queryString) {
      navigate('?' + queryString);
    } else if (basePath !== window.location.href) {
      navigate(window.location.search.pathname);
    }
  };

  const updateFilter = (filterType, newFilter, clearAll = false) => {
    if (clearAll) {
      _updateUrl('', locationFilter, [], []);
      return;
    }
    if (filterType === 'free') {
      _updateUrl(newFilter, locationFilter, categoryFilter, eventFilter);
      return;
    }
    if (filterType === 'location') {
      _updateUrl(freeFilter, newFilter, categoryFilter, eventFilter);
      return;
    }
    if (filterType === 'category') {
      _updateUrl(freeFilter, locationFilter, newFilter, eventFilter);
      return;
    }
    if (filterType === 'event') {
      _updateUrl(freeFilter, locationFilter, categoryFilter, newFilter);
      return;
    }
  };

  const clearAllFilters = () => {
    updateFilter('', '', true);
  };

  return (
    <BuscadorContext.Provider
      value={{
        locationFilter,
        freeFilter,
        categoryFilter,
        eventFilter,
        updateFilter,
        clearAllFilters,
        cleanFilters,
      }}
    >
      {children}
    </BuscadorContext.Provider>
  );
};

// Hook personalizado para acceder al contexto/estado del Buscador
export const useBuscadorState = () => {
  const context = useContext(BuscadorContext);
  if (!context) {
    throw new Error('useBuscadorState debe ser utilizado dentro de un BuscadorProvider');
  }
  return context;
};

