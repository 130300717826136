import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Button from '@material-ui/core/Button';
import { botonesAtrasYAdelante } from '../PasoForm';
import { BiReceipt } from 'react-icons/bi';

import './PasoConfirmacion.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function PasoConfirmacion({ username, stripeData, isAdminPage, backAdminPage }) {
  const { t } = useTranslation('global');
  const classes = botonesAtrasYAdelante();
  const movil = useMediaQuery('(max-width:960px)');

  return (
    <div className="wrapper-conf-aliado" style={{ minWidth: '320px' }}>
      <p className="titulo-conf-aliado" style={{ marginTop: '5rem' }}>
        {t('finalStep.title')}
      </p>
      <CheckCircleIcon style={{ color: '#8c50ff', fontSize: '4rem' }} />

      {stripeData &&
      stripeData?.subscriptionEnd &&
      stripeData?.tier &&
      stripeData?.lastInvoiceUrl ? (
        <div className="paso-conf-table-stripe">
          <div>
            <p className="paso-conf-table-stripe-h">Plan</p>
            <p className="paso-conf-table-stripe-d">{stripeData.tier}</p>
          </div>
          <div>
            <p className="paso-conf-table-stripe-h">{t('finalStep.until')}</p>
            <p className="paso-conf-table-stripe-d">
              {moment(stripeData.subscriptionEnd).format('DD/MM/YYYY')} ({t('finalStep.renewal')})
            </p>
          </div>
          <div className="paso-conf-table-stripe-factura">
            <Button
              className={classes.cancelButton}
              style={{
                textTransform: 'uppercase',
                padding: '4px 10px',
                minWidth: '220px',
              }}
              href={stripeData.lastInvoiceUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              {t('finalStep.download')}{' '}
              <BiReceipt style={{ fontSize: '1.2rem', marginLeft: '10px' }} />
            </Button>
          </div>
        </div>
      ) : null}

      <p className="texto-conf-aliado">{t('finalStep.description')}</p>
      <Button
        className={classes.button}
        style={movil ? { width: '90%' } : { width: '30%' }}
        href={`${t('routes.profile')}/${username}`}
        target={movil ? '_self' : '_blank'}
        rel="noopener noreferrer"
      >
        {t('finalStep.seeProfile')} <ArrowForwardIosIcon style={{ marginLeft: '1rem' }} />
      </Button>

      {isAdminPage && (
        <Button
          className={classes.cancelButton}
          onClick={backAdminPage}
          style={movil ? { width: '90%', marginTop: '20px' } : { width: '30%', marginTop: '20px' }}
        >
          {t('finalStep.adminMessage')}
        </Button>
      )}
    </div>
  );
}

