import { useTranslation } from 'react-i18next';
import './GoogleButton.css';
import React from 'react';

function GoogleButton({ onClick, disabled = false }) {
  const { t } = useTranslation('global');

  return (
    <button type="button" class="login-with-google-btn" onClick={onClick} disabled={disabled}>
      {t('login.continueGoogle')}
    </button>
  );
}

export default GoogleButton;

