import { Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SelectFilterButtons from './SelectFilterButtons';
import { useTranslation } from 'react-i18next';
import { tienenLosMismosElementos } from '../../Utils/utilFunctions';
import clsx from 'clsx';
import PlanodiIcons from '../../Utils/PlanodiIcons';

function SelectFilterOptionsDesktop({
  items,
  handleSubmit,
  multipleSelect,
  selectedItems,
  refMenu,
  selectItem,
  clearSelections,
  lastState,
  widthOptionsDesktop,
  textFromTranslationKey = false,
  textKey = '',
}) {
  const { t } = useTranslation('global');
  const [openNavDerecha, setOpenNavDerecha] = useState(true);

  useEffect(() => {
    const elemento = refMenu?.current;

    const calcularDistancia = () => {
      if (elemento) {
        const rect = elemento.getBoundingClientRect();
        const distancia = rect.left;
        if (distancia < 100) {
          setOpenNavDerecha(false);
        }
      }
    };

    // Calcular la distancia inicial
    calcularDistancia();

    // Escuchar el evento de redimensionamiento del navegador
    window.addEventListener('resize', calcularDistancia);

    // Limpiar el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', calcularDistancia);
    };
  }, []);

  return (
    <div
      ref={refMenu}
      className={clsx('sel-fil-options-wrp', {
        derecha: openNavDerecha,
        izquierda: !openNavDerecha,
        'width-2': widthOptionsDesktop === 2,
        'width-3': widthOptionsDesktop === 3,
      })}
    >
      <div style={{ width: '100%' }} className="sel-fil-options-div">
        {items.map((item) => (
          <div
            key={item.id}
            className="sel-fil-options-div-item"
            onClick={() => {
              selectItem(item);
            }}
          >
            {multipleSelect && (
              <Checkbox
                style={{ marginLeft: '10px' }}
                checked={selectedItems.includes(item.id)}
                color="secondary"
              />
            )}
            {item?.icon ? (
              <span style={{ margin: '0 0 0 5px' }}>
                <PlanodiIcons name={item.icon} />
              </span>
            ) : null}
            <p
              className={`noselect sel-filtros-p-item ${
                multipleSelect ? '' : 'sel-fil-options-div-item-single-select-p'
              } ${selectedItems.includes(item.id) ? 'active' : ''}`}
            >
              {textFromTranslationKey ? t(item.key) : item[textKey]}
            </p>
          </div>
        ))}
      </div>
      <SelectFilterButtons
        clear={clearSelections}
        submit={handleSubmit}
        multipleSelect={multipleSelect}
        borrarDisabled={!Boolean(selectedItems.length)}
        submitDisabled={tienenLosMismosElementos(lastState, selectedItems)}
      />
    </div>
  );
}

export default SelectFilterOptionsDesktop;

