import { useMutation, useQueryClient } from 'react-query';
import { userHeaders } from '../../../Utils/headerSetter';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

export default function useSaveInfoGeneral(pathName) {
  const queryClient = useQueryClient();
  const { t } = useTranslation('global');
  const saveInfo = async ({ idAliado, newContent }) => {
    try {
      await axios.post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/editar-negocio/info-general-proveedor`,
        { idAliado, content: newContent },
        userHeaders()
      );

      toast.success(t('shared.successUpdate'), {
        position: 'bottom-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error(t('shared.errorUpdate'), {
        position: 'bottom-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return useMutation(saveInfo, {
    // When mutate is called:
    onMutate: async ({ newContent }) => {
      await queryClient.cancelQueries(['aliadoInfoDashboard', pathName]);
      const previousData = queryClient.getQueryData(['aliadoInfoDashboard', pathName]);

      if (previousData) {
        // Deep clone the object, otherwise the objects inside will have the same reference in memory
        const newData = JSON.parse(JSON.stringify(previousData));
        newData.aliadoInfoGeneral.perfilContent = newContent;
        queryClient.setQueryData(['aliadoInfoDashboard', pathName], newData);
      }
      return { previousData };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_error, _values, context) => {
      queryClient.setQueryData(['aliadoInfoDashboard', pathName], context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['aliadoInfoDashboard', pathName]);
    },
  });
}

