import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Form as FormikForm } from 'formik';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import MyTextField from '../../formikInputs/MyTextField/MyTextField';
import Button from '@material-ui/core/Button';
import { botonesAtrasYAdelante } from '../PasoForm';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MyLongText from '../../formikInputs/MyLongText/MyLongText';
import MyCheckbox from '../../formikInputs/MyCheckbox/MyCheckbox';
import PoolIcon from '@material-ui/icons/Pool';
import Brightness5Icon from '@material-ui/icons/Brightness5';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import MyGoogleDirection from '../../formikInputs/MyGoogleDirection/MyGoogleDirection';

import './PasoInfoGeneral.css';

export default function FormInfoGeneralTerraza({
  setStep,
  errors,
  isEdition,
  isMainPage,
  t,
  isAdminPage,
  backAdminPage,
  googleAutocomplete,
}) {
  const navigate = useNavigate();
  const buttonTypes = botonesAtrasYAdelante();
  const movil = useMediaQuery('(max-width:960px)');

  return (
    <FormikForm style={{ width: '100%' }}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          className="postion-rel"
          style={
            movil
              ? { padding: '0', marginTop: '1rem' }
              : { padding: '1rem 0 1rem 1rem', marginTop: '1rem' }
          }
        >
          <p style={{ margin: '0 0 0.5rem 0' }} className="alaido-form-label1">
            {t('afiliadosSteps.details.capacity')}{' '}
            <span className="alaido-form-label2">{t('afiliadosSteps.details.capacity2')}</span>
          </p>
          <MyTextField
            name="capacidad"
            placeholder={`${t('afiliadosSteps.details.abbreviation')} 50`}
            style={{
              width: '45%',
              float: 'left',
              margin: '0',
            }}
          />
          <p
            style={{
              float: 'left',
              margin: '1rem 0rem 0 0',
              width: '10%',
              textAlign: 'center',
            }}
          >
            -
          </p>
          <MyTextField
            name="capacidad2"
            placeholder={`${t('afiliadosSteps.details.abbreviation')} 100`}
            style={{ width: '45%', float: 'left', margin: '0' }}
          />
          {/*<p*/}
          {/*  style={{*/}
          {/*    textAlign: "center",*/}
          {/*    marginTop: "5.5rem",*/}
          {/*    marginBottom: "0",*/}
          {/*    fontSize: "0.9rem",*/}
          {/*    color: "#99d227",*/}
          {/*  }}*/}
          {/*  className="alaido-form-label2"*/}
          {/*>*/}
          {/*  De {values.capacidad !== "" ? values.capacidad : "-"} a{" "}*/}
          {/*  {values.capacidad2 !== "" ? values.capacidad2 : "-"} personas*/}
          {/*</p>*/}
        </Grid>
        <Grid
          item
          xs={12}
          className="postion-rel"
          style={movil ? { padding: '0', marginTop: '2rem' } : { padding: '1rem 0 1rem 1rem' }}
        >
          <p style={{ margin: '0 0 0.5rem 0' }} className="alaido-form-label1">
            {t('afiliadosSteps.details.description')}{' '}
            <span className="alaido-form-label2">{t('afiliadosSteps.details.description2')}</span>
          </p>
          <MyLongText
            name="descripcion"
            placeholder={t('afiliadosSteps.details.descriptionExample')}
            rows={5}
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className="postion-rel"
          style={movil ? { padding: '0', marginTop: '2rem' } : { padding: '1rem 0 1rem 1rem' }}
        >
          <p style={{ margin: '0 0 0.5rem 0' }} className="alaido-form-label1">
            {t('afiliadosSteps.details.services')}{' '}
            <span className="alaido-form-label2">{t('afiliadosSteps.details.services2')}</span>
          </p>
          <MyLongText
            name="servicios"
            placeholder={t('afiliadosSteps.details.servicesExmaple')}
            rows={5}
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className="postion-rel"
          style={movil ? { padding: '0', marginTop: '2rem' } : { padding: '1rem 0 1rem 1rem' }}
        >
          <p style={{ margin: '0 0 0.5rem 0' }} className="alaido-form-label1">
            {t('afiliadosSteps.details.spaces')}{' '}
            <span className="alaido-form-label2">{t('afiliadosSteps.details.spaces2')}</span>
          </p>
          <MyLongText
            name="espacios"
            placeholder={t('afiliadosSteps.details.spacesExample')}
            rows={5}
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className="postion-rel"
          style={movil ? { padding: '0', marginTop: '2rem' } : { padding: '1rem 0 1rem 1rem' }}
        >
          <p style={{ margin: '0 0 0.5rem 0' }} className="alaido-form-label1">
            {t('afiliadosSteps.details.address')}
            {'  '}
            <span className="alaido-form-label2">{t('afiliadosSteps.details.address2')}</span>
          </p>

          <MyGoogleDirection
            name="direccion"
            placeholder={t('afiliadosSteps.details.addressExample')}
            style={{ width: '100%' }}
            googleAutocomplete={googleAutocomplete}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className="postion-rel"
          style={movil ? { marginTop: '2rem' } : { padding: '1rem 0 0 1rem' }}
        >
          <p style={{ margin: '0 0 0.5rem 0' }} className="alaido-form-label1">
            {t('afiliadosSteps.details.options')}
          </p>
        </Grid>
        <Grid item>
          <MyCheckbox name="alcohol" isValid>
            <label style={{ display: 'inline', marginLeft: '0.5rem' }} htmlFor="alcohol">
              <LocalBarIcon />
              {t('afiliadosSteps.details.opt_alcohol')}
            </label>
          </MyCheckbox>
        </Grid>
        <Grid item>
          <MyCheckbox name="eventosDia" isValid>
            <label style={{ display: 'inline', marginLeft: '0.5rem' }} htmlFor="eventosDia">
              <Brightness5Icon />
              {t('afiliadosSteps.details.opt_day')}
            </label>
          </MyCheckbox>
        </Grid>
        <Grid item>
          <MyCheckbox name="eventosNoche" isValid>
            <label style={{ display: 'inline', marginLeft: '0.5rem' }} htmlFor="eventosNoche">
              <Brightness2Icon />
              {t('afiliadosSteps.details.opt_night')}
            </label>
          </MyCheckbox>
        </Grid>
        <Grid item>
          <MyCheckbox name="estacionamiento" isValid>
            <label style={{ display: 'inline', marginLeft: '0.5rem' }} htmlFor="estacionamiento">
              <DriveEtaIcon />
              {t('afiliadosSteps.details.opt_parking')}
            </label>
          </MyCheckbox>
        </Grid>
        <Grid item>
          <MyCheckbox name="alberca" isValid>
            <label style={{ display: 'inline', marginLeft: '0.5rem' }} htmlFor="alberca">
              <PoolIcon />
              {t('afiliadosSteps.details.opt_pool')}
            </label>
          </MyCheckbox>
        </Grid>

        <Grid
          item
          container
          style={
            isEdition
              ? { marginTop: '1.5rem', marginBottom: '1.5rem' }
              : { marginTop: '3rem', marginBottom: '3rem' }
          }
        >
          {isEdition && !isAdminPage ? (
            <Grid item xs={12} style={movil ? { textAlign: 'center' } : { textAlign: 'right' }}>
              <Button
                className={buttonTypes.cancelButton}
                style={movil ? { width: '100%' } : { width: '30%' }}
                onClick={() => (isMainPage ? setStep(-1) : navigate(t('routes.myBusiness')))}
              >
                {isMainPage ? t('shared.back') : t('shared.cancel')}
              </Button>
              <Button
                type="submit"
                className={buttonTypes.saveButton}
                style={
                  movil
                    ? { width: '100%', marginTop: '1.5rem' }
                    : { width: '30%', marginLeft: '1.5rem' }
                }
              >
                {isMainPage ? t('shared.saveAndContinue') : t('shared.save')}
              </Button>
              {Object.keys(errors).length > 0 ? (
                <p
                  style={{
                    color: '#f44336',
                    fontFamily: 'Roboto',
                    marginTop: '0.5rem',
                    fontSize: '14px',
                  }}
                >
                  {t('yup.incomplete')}
                </p>
              ) : null}
            </Grid>
          ) : (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                className={buttonTypes.cancelButton}
                style={
                  movil
                    ? { width: '100%', marginBottom: '1rem' }
                    : { width: '30%', marginRight: '1rem' }
                }
                onClick={() => {
                  if (isAdminPage) {
                    backAdminPage();
                  } else {
                    setStep(-1);
                  }
                }}
              >
                {t('shared.back')}
              </Button>
              <Button
                type="submit"
                className={buttonTypes.button}
                style={movil ? { width: '100%' } : { width: '30%' }}
              >
                {t('shared.continue')}
              </Button>
              {Object.keys(errors).length > 0 ? (
                <p
                  style={{
                    color: '#f44336',
                    fontFamily: 'Roboto',
                    marginTop: '0.5rem',
                    fontSize: '14px',
                  }}
                >
                  {t('yup.incomplete')}
                </p>
              ) : null}
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormikForm>
  );
}

