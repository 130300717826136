import { SiteMaps } from '../../../componentes/SiteMap/SiteMap';

const eventTypesContent = [
  { name: 'birthday', route: 'search?event=birthday' },
  { name: `kidsParty`, route: 'search?event=kidsParty' },
  { name: 'wedding', route: 'search?event=wedding' },
  { name: 'corporate', route: 'search?event=corporate' },
  { name: 'poolParty', route: 'search?event=poolParty' },
  { name: 'babyShower', route: 'search?event=babyShower' },
  { name: 'roofTopParty', route: 'search?event=roofTopParty' },
  { name: 'religiousCeremony', route: 'search?event=religiousCeremony' },
];

const EventTypesSitemap = () => {
  return (
    <>
      <SiteMaps title={'eventsTypes'} content={eventTypesContent} />
    </>
  );
};

export default EventTypesSitemap;

