import './Packages.css';

import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { IconButton, Tooltip } from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';

export const StaticKindOfPrice = {
  precioTotal: 4,
  precioPorArticulo: 3,
  precioPorPersona: 1,
  precioPorHora: 2,
};

/**
 * @param {{title: string, price: string, img: string, isBusiness: boolean, isApproved: number}[]} cardInfo
 * @param  onClickFunction
 * @param  width
 * @param  border
 * */
export default function Packages({
  onClickFunction,
  cardInfo,
  width,
  label,
  border,
  onClickVerMas,
  kindOfPrice,
  t,
}) {
  const { name, price, mainImage, url, id, isBusiness, isActive, isApproved } = cardInfo;

  const aliadoStatus = (isActive, isApproved) => {
    let status;
    let statusColor;

    if (!isActive) {
      status = 'inactive';
      statusColor = 'rgb(249, 82, 82)';
    } else if (!isApproved) {
      status = 'pending';
      statusColor = 'rgb(255, 232, 152)';
    } else {
      status = 'active';
      statusColor = 'rgb(106, 216, 125)';
    }

    return {
      status,
      statusColor,
    };
  };

  const { status, statusColor } = aliadoStatus(isActive, isApproved);

  return (
    <div
      className="packages-wrapper"
      onClick={onClickFunction}
      key={id}
      style={border ? { width, border: 'solid #0D3B66 2px' } : { width }}
    >
      <div className="package-div-content">
        <p className="package-div-content-title">{name}</p>
        {isBusiness && (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ backgroundColor: statusColor }} className={`package-div-circle`} />
            <p className="package-div-status">{t(`shared.${status}`)}</p>
            {status !== 'active' ? (
              <>
                <Tooltip title={t(`shared.${status}Msg`)}>
                  <IconButton style={{ padding: '3px' }}>
                    <InfoIcon style={{ color: '#3f3f3f', fontSize: '13px' }} />
                  </IconButton>
                </Tooltip>
              </>
            ) : null}
          </span>
        )}
        <div
          className="package-div-content-price-button"
          style={price ? {} : { justifyContent: 'flex-end' }}
        >
          {price ? (
            <p className="package-div-content-price">
              {`$${price.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}`}{' '}
              <span style={{ fontSize: '0.7rem' }}>
                {(() => {
                  switch (kindOfPrice) {
                    case StaticKindOfPrice.precioTotal:
                      return 'Total';
                    case StaticKindOfPrice.precioPorArticulo:
                      return t('packages.pa');
                    case StaticKindOfPrice.precioPorHora:
                      return t('packages.ph');
                    case StaticKindOfPrice.precioPorPersona:
                      return t('packages.pp');
                  }
                })()}
              </span>
            </p>
          ) : null}
          <p className="package-div-content-button" onClick={onClickVerMas}>
            {label}
          </p>
        </div>
      </div>
      <img src={mainImage || url} alt="foto" className="packages-img-bg" />

      {/* {isBusiness && !isApproved ? (
        <div className="packages-ribbon">
          <span>{t('shared.pending')}</span>
        </div>
      ) : null} */}
    </div>
  );
}

Packages.propTypes = {
  width: PropTypes.string,
  label: PropTypes.node,
  cardInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.number,
    mainImage: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    id: PropTypes.number.isRequired,
  }).isRequired,
  border: PropTypes.bool,
  onClickFunction: PropTypes.func.isRequired,
  onClickVerMas: PropTypes.func.isRequired,
  kindOfPrice: PropTypes.number,
};

Packages.defaultProps = {
  width: '300px',
  border: false,
  label: (
    <span>
      VER MÁS <IoIosArrowForward />
    </span>
  ),
  kindOfPrice: StaticKindOfPrice.precioTotal,
};

