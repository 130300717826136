import './PackagesSection.css';
import React, { useEffect, useState } from 'react';
import CardPackages from '../../../componentes/CardPackages/CardPackages';
import BuscadorGridSkeleton from '../../Buscador/BuscadorGrid/BuscadorGridSkeleton';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @param  title - string
 * @param {{aliado: string, estados: Estado[], idAliado: number, id: number, name: string, packageImg: string, pathUrl: string, price: number, rn: number, verificado: number}[]}infoCards
 * @param  loading - bool
 * */

const PackagesSection = ({ infoCards, loading }) => {
  const { t } = useTranslation('global');
  const [totalCards, setTotalCards] = useState(9);
  const divRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        const width = divRef.current.offsetWidth;
        if (width > 3 * 375 + 2 * 26) {
          setTotalCards(9);
        } else if (width >= 2 * 375 + 26) {
          setTotalCards(6);
        } else {
          setTotalCards(5);
        }
      }
    };

    // Escuchar el evento de cambio de tamaño global de la ventana
    window.addEventListener('resize', handleResize);

    // Llamar handleResize inicialmente para obtener el ancho inicial
    handleResize();

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="cardPackages-container">
      <p className="cardPackages-main-title">{t('homepage.packages')}</p>
      <p className="cardPackages-main-subtitle">{t('homepage.packagesSubtitle')}</p>

      {loading ? (
        <BuscadorGridSkeleton numberOfCards={6} />
      ) : (
        <>
          {infoCards ? (
            <div className="cardPackages-main-wrp" ref={divRef}>
              {infoCards.slice(0, totalCards).map((item) => (
                <CardPackages
                  urlId={item.idPackage}
                  imgUrl={item.images[0].url}
                  aliadoName={item.aliadoName.trim()}
                  title={item.name}
                  aliadoPathUrl={item.pathUrl}
                  insignia={item.insignia}
                  price={item.price}
                  estados={item.locations}
                  key={item.id}
                  country={item.country}
                  kindOfPrice={item.kindOfPrice}
                  descuento={item.descuento}
                />
              ))}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default PackagesSection;

