const USER_AUTH_ERROR = 'USER_AUTH_ERROR';
const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
const USER_GET_USER_SUCCESS = 'USER_GET_USER_SUCCESS';
const COMPONENTS_FILTER_MODAL = 'COMPONENTS_FILTER_MODAL';

export {
  USER_AUTH_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_GET_USER_SUCCESS,
  COMPONENTS_FILTER_MODAL,
};

