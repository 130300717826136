import moment from 'moment';

/**
 * Genera un string que contiene los nombres de los dos primeros objetos
 * y la cantidad de objetos restantes en el formato especificado.
 * @param {{name: string}[]} objetos - Un array de objetos.
 * @returns {string} - El string formateado.
 * @example "Jalisco, Colima (1 estado más)"
 */
export const mostrarPrimerosDosObjetosDeArray = (array, name) => {
  if (!array || !array.length) {
    return '';
  }
  const primerosDos = array.slice(0, 2).map((item) => item.name);
  const restantes = array.length - 2;

  let resultado = primerosDos.join(', ');

  if (restantes > 0) {
    resultado += ` (${restantes} ${name}${restantes === 1 ? '' : 's'} más)`;
  }

  return resultado;
};

/**
 * Reorganiza un array de imágenes moviendo el elemento con main=1 a la posición 0.
 * @param {Array} images - Un array de objetos que representan imágenes.
 * @returns {Array} - El array reorganizado.
 */
export const reorganizarImagenPrincipal = (images) => {
  if (!images || !images.length) {
    return [];
  }

  // Crear una copia del array original
  const imagesCopy = [...images];

  // Encontrar el índice del elemento con main=1 en la copia
  const indexMain = imagesCopy.findIndex((image) => image.main === 1);

  // Verificar si se encontró un elemento con main=1
  if (indexMain !== -1) {
    // Mover el elemento a la posición 0 en la copia
    const [elementoMain] = imagesCopy.splice(indexMain, 1);
    imagesCopy.unshift(elementoMain);
  }

  return imagesCopy;
};

/**
 * Compara si dos arrays tienen los mismos elementos, independientemente del orden.
 * @param {Array<string>} array1 - El primer array a comparar.
 * @param {Array<string>} array2 - El segundo array a comparar.
 * @returns {boolean} True si los arrays tienen los mismos elementos, False de lo contrario.
 */
export const tienenLosMismosElementos = (array1, array2) => {
  if (!array1 || !array2) {
    return true;
  }
  // Ordena los arrays
  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  // Compara los arrays ordenados
  if (sortedArray1.length !== sortedArray2.length) {
    return false;
  }

  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
};

export const getCurrencyFromCountry = (country) => {
  if (country === 2) {
    return 'USD';
  }
  return 'MXN';
};

/**
 * Devuelve una cadena que representa el tiempo transcurrido en semanas, meses o años desde el timestamp dado.
 * @param {string} timestamp - Timestamp en formato ISO 8601 (ej. "2022-06-24T03:27:15.000Z").
 * @returns {string} - Cadena que representa el tiempo transcurrido en Planodi.
 */
export function tiempoTranscurridoEnPlanodi(timestamp) {
  // Obtener la fecha actual
  var ahora = moment();

  // Convertir el timestamp a un objeto Moment
  var fechaTimestamp = moment(timestamp);

  // Calcular la diferencia de tiempo en años, meses y semanas
  var añosTranscurridos = ahora.diff(fechaTimestamp, 'years');
  var mesesTranscurridos = ahora.diff(fechaTimestamp, 'months');
  var semanasTranscurridas = ahora.diff(fechaTimestamp, 'weeks');

  // Determinar la representación adecuada basada en la diferencia de tiempo
  if (añosTranscurridos >= 1) {
    const periodo = añosTranscurridos > 1 ? 'years' : 'year';
    return [añosTranscurridos, periodo];
  } else if (mesesTranscurridos >= 1) {
    const periodo = mesesTranscurridos > 1 ? 'months' : 'month';
    return [mesesTranscurridos, periodo];
  } else {
    if (semanasTranscurridas < 1) {
      return null;
    }
    const periodo = semanasTranscurridas > 1 ? 'weeks' : 'week';
    return [semanasTranscurridas, periodo];
  }
}

export function crearTagsPasoCategoriasAfiliarForm(
  t,
  nombre,
  locationsData,
  selectedLocations,
  categoriasData,
  selectedCategorias,
  pais
) {
  const tagsLocations = locationsData
    .filter((item) => {
      return selectedLocations.includes(item.id);
    })
    .map((item) => item.name)
    .join(',');
  const tagsCategoriasKeys = categoriasData
    .filter((item) => {
      return selectedCategorias.includes(item.id);
    })
    .map((item) => item.key)
    .join(',');

  const tagsCategorias = categoriasData
    .filter((item) => {
      return selectedCategorias.includes(item.id);
    })
    .map((item) => {
      return t(item.key);
    })
    .join(',');
  return `${nombre},${tagsLocations},${tagsCategoriasKeys},${tagsCategorias},${t(
    'countryTags.' + pais
  )}`;
}

