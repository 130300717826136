import './SelectFilter.css';

import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import SelectFilterDesktop from './SelectFilterDesktop';
import SelectFilterMobile from './SelectFilterMobile';

function SelectFilter({
  title,
  setOnGlobalState,
  lastState = [],
  items = [],
  multipleSelect = false,
  withBorder = true,
  darkMode = false,
  leftIcon = null,
  toggleArrow = true,
  sharpEdges = false,
  textFromTranslationKey = false,
  showSelectedElementsNumber = false,
  textKey = '',
  hideText = false,
  fullWidth = false,
  widthOptionsDesktop = 1,
}) {
  const mobile = useMediaQuery('(max-width:960px)');

  return (
    <>
      {mobile ? (
        <SelectFilterMobile
          title={title}
          withBorder={withBorder}
          darkMode={darkMode}
          sharpEdges={sharpEdges}
          toggleArrow={toggleArrow}
          leftIcon={leftIcon}
          items={items}
          multipleSelect={multipleSelect}
          textFromTranslationKey={textFromTranslationKey}
          textKey={textKey}
          setOnGlobalState={setOnGlobalState}
          lastState={lastState}
          showSelectedElementsNumber={showSelectedElementsNumber}
          hideText={hideText}
          fullWidth={fullWidth}
        />
      ) : (
        <SelectFilterDesktop
          title={title}
          withBorder={withBorder}
          darkMode={darkMode}
          sharpEdges={sharpEdges}
          toggleArrow={toggleArrow}
          leftIcon={leftIcon}
          items={items}
          multipleSelect={multipleSelect}
          textFromTranslationKey={textFromTranslationKey}
          textKey={textKey}
          setOnGlobalState={setOnGlobalState}
          lastState={lastState}
          showSelectedElementsNumber={showSelectedElementsNumber}
          hideText={hideText}
          fullWidth={fullWidth}
          widthOptionsDesktop={widthOptionsDesktop}
        />
      )}
    </>
  );
}

export default SelectFilter;

