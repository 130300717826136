import React, { useState } from 'react';
import RichText from '../../../componentes/RichText/RichText';
import { useTranslation } from 'react-i18next';
import { Button, useMediaQuery } from '@material-ui/core';
import { botonesAtrasYAdelante } from '../../../componentes/pasos/PasoForm';
import { useParams } from 'react-router-dom';
import useSaveFAQ from './useSaveFAQ';
import Toggle from '../../../componentes/Toggle/Toggle';

const EditInfo = ({ content, idAliado }) => {
  const { t } = useTranslation('global');
  const { pathName } = useParams();
  const mobile = useMediaQuery('(max-width:960px)');
  const buttonTypes = botonesAtrasYAdelante();
  const [newContent, setNewContent] = useState(content);
  const { mutate: saveRichText, isLoading } = useSaveFAQ(pathName);

  return (
    <>
      <RichText initialValue={content} setNewContent={(val) => setNewContent(val)} />
      <div
        style={
          mobile
            ? {
                width: '100%',
                textAlign: 'center',
                marginTop: '2rem',
              }
            : { width: '100%', textAlign: 'right', marginTop: '1.1rem' }
        }
      >
        <Button
          className={buttonTypes.saveButton}
          style={
            mobile ? { width: '100%', marginTop: '1.5rem' } : { width: '30%', marginLeft: '1.5rem' }
          }
          onClick={() => {
            saveRichText({ idAliado, newContent });
          }}
          disabled={isLoading}
        >
          {t('shared.save')}
        </Button>
      </div>
    </>
  );
};

function EditarFAQ({ content, isSelected, setIsSelected, isCollapsible, idAliado }) {
  const { t } = useTranslation('global');
  return (
    <>
      {isCollapsible ? (
        <Toggle
          handleOnSelect={() => setIsSelected(6)}
          text={t('afiliadosSteps.faq.title')}
          isSelected={isSelected}
        >
          <EditInfo idAliado={idAliado} content={content} />
        </Toggle>
      ) : (
        <>
          <p className={'url-txt-instruc-ed-neg'}>{t('afiliadosSteps.faq.title')}</p>
          <EditInfo idAliado={idAliado} content={content} />
        </>
      )}
    </>
  );
}

export default EditarFAQ;

