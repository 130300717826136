import React, { useEffect, useState, useRef } from 'react';

const GoogleMap = ({ aliado, address, locale, googleMaps }) => {
  const mapRef = useRef(null);
  const [response, setResponse] = useState(null);

  //GoogleMaps API desactivated due to billing.
  useEffect(() => {
    // Crear un objeto de geocodificación
    if (googleMaps) {
      const geocoder = new window.google.maps.Geocoder();

      // Geocodificar la dirección para obtener las coordenadas
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK' && results && results.length > 0) {
          setResponse(status);
          const location = results[0].geometry.location;

          // Configurar opciones del mapa
          const mapOptions = {
            center: location,
            zoom: 15,
            fullscreenControl: false,
            mapTypeControl: false,
            scrollwheel: true,
            streetViewControl: false,
            zoomControl: false,
          };

          // Crear el mapa
          const map = new window.google.maps.Map(mapRef.current, mapOptions);

          // Agregar un marcador en la ubicación
          new window.google.maps.Marker({
            position: location,
            map,
            title: aliado,
          });
        }
      });
    }
  }, []);

  return (
    <div
      ref={response ? mapRef : null}
      style={{ height: '350px', width: '100%', borderRadius: '6px', marginTop: '1rem' }}
    >
      {response ? null : (
        <iframe
          width="100%"
          height="350"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src={`https://maps.google.com/maps?width=100%25&height=350&hl=${locale}&q=${encodeURIComponent(
            address
          )}+(${encodeURIComponent(aliado)})&t=&z=15&ie=UTF8&iwloc=B&output=embed`}
        />
      )}
    </div>
  );
};

export default GoogleMap;
