import React from 'react';
import BuscadorGrid from './BuscadorGrid/BuscadorGrid';
import BuscadorFiltros from './BuscadorFiltros/BuscadorFiltros';
import { useLoadProfileCards } from './useLoadProfileCards';
import { useQuery } from 'react-query';
import axios from 'axios';
import { userHeaders } from '../../Utils/headerSetter';
import { useBuscadorState } from './BuscadorContext';
import BuscadorFiltrosSkeleton from './BuscadorFiltros/BuscadorFiltrosSkeleton';
import BuscadorGridSkeleton from './BuscadorGrid/BuscadorGridSkeleton';

function BuscadorPackages() {
  async function getFiltros() {
    const res = await axios.get(
      `${
        process.env.REACT_APP_ENV === 'development'
          ? process.env.REACT_APP_API_LOCAL
          : process.env.REACT_APP_API_PROD
      }aliado/getFiltros`,
      userHeaders(false)
    );
    return res.data;
  }
  const { isLoading, isError, data, error } = useQuery('filtrosPackages', getFiltros);
  const { locationFilter, freeFilter, categoryFilter, eventFilter } = useBuscadorState();
  const {
    isError: isErrorCards,
    data: dataCards,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isLoadingCards,
  } = useLoadProfileCards(
    data?.packagesTotalResults,
    locationFilter,
    freeFilter,
    categoryFilter,
    eventFilter,
    ['packages', 'packageCards']
  );

  const dataForGrid = (data) => {
    if (!data || !data?.pages) {
      return [];
    }
    return data.pages.flat();
  };

  if (isLoading || !data) {
    return (
      <div>
        <BuscadorFiltrosSkeleton />
        <BuscadorGridSkeleton />
      </div>
    );
  }

  return (
    <div>
      <BuscadorFiltros
        tiposEventos={data.tiposEventos}
        categorias={data.categorias}
        locations={data.locations}
      />
      <BuscadorGrid
        isError={isErrorCards}
        data={dataForGrid(dataCards)}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoadingCards={isLoadingCards}
        isPackagesPage
      />
    </div>
  );
}

export default BuscadorPackages;

