import './Navbar.css';

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { useMediaQuery } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios';
import {
  IoIosLogIn,
  IoIosLogOut,
  IoIosMegaphone,
  IoIosPersonAdd,
  IoIosSettings,
  IoMdFingerPrint,
  IoMdSearch,
} from 'react-icons/io';

import { userAuthError, userGetUserSuccess, userLogoutSuccess } from '../../../actions/userActions';
import { userHeaders } from '../../../Utils/headerSetter';
import logo from '../../../Assets/img/LogoBlanco.webp';
import logoMorado from '../../../Assets/img/Logo.webp';
import logoGris from '../../../Assets/img/PLANODI_PNG_gris.webp';
import ModalRegistro from './ModalRegistro/ModalRegistro';
import ModalLogin from './ModalLogin/ModalLogin';
import ModalCountry from './ModalCountry/ModalCountry';
import CountrySelector from './CountrySelector';

export function useAuthModals() {
  const [openRegistroModal, setOpenRegistroModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  return {
    openRegistroModal,
    openLoginModal,
    setOpenRegistroModal,
    setOpenLoginModal,
  };
}

/**
 * @param type: "purple" || "black" || "white"
 * @param fix: bool
 */
export default function Navbar({ shadow, type, fix, hideLocation = false }) {
  const { t } = useTranslation('global');

  const mobile = useMediaQuery('(max-width:960px)');
  const stateUser = useSelector((state) => state.user);
  const location = useLocation();
  const pathName = location.pathname;

  const { openRegistroModal, openLoginModal, setOpenRegistroModal, setOpenLoginModal } =
    useAuthModals();
  const [open, setOpen] = useState(false);
  const [openAcciones, setOpenAcciones] = useState(false);
  const [fixNavbarTop, setFixNavbarTop] = useState(false);
  const [openCountryModal, setOpenCountryModal] = useState(!localStorage.getItem('isI18NSet'));
  const [logoColor, setLogoColor] = useState(logo);
  const [mostrarMenu, setMostrarMenu] = useState(
    pathName === t('routes.home') ||
      pathName === t('routes.signUp') ||
      pathName === t('routes.login')
  ); //menu central

  const wrapperRef = useRef(null);
  const botonRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setOriginalColor = useCallback(() => {
    switch (type) {
      case 'pruple':
        setLogoColor(logoMorado);
        break;
      case 'black':
        setLogoColor(logoGris);
        break;
      case 'white':
        setLogoColor(logo);
        break;
      default:
        setLogoColor(logoGris);
    }
  }, [type]);

  useEffect(() => {
    setOriginalColor();
    axios
      .get(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/get`,
        userHeaders(false)
      )
      .then((res) => {
        const { token, user } = res.data;
        if (!user.isVerified) {
          toast.dismiss();
          toast.warn(t('login.userNotValidatedYet'), {
            position: 'bottom-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        dispatch(userGetUserSuccess(token, user));
      })
      .catch(() => {
        dispatch(userAuthError());
        setOpenRegistroModal(pathName === t('routes.signUp'));
        setOpenLoginModal(pathName === t('routes.login'));
      });
  }, [dispatch, pathName, setOriginalColor, setOpenRegistroModal, setOpenLoginModal]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        botonRef.current &&
        !botonRef.current.contains(event.target) &&
        openAcciones
      ) {
        setOpenAcciones(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, openAcciones, botonRef]);

  useEffect(() => {
    setMostrarMenu(
      pathName === t('routes.home') ||
        pathName === t('routes.signUp') ||
        pathName === t('routes.login')
    );
  }, [pathName]);

  useEffect(() => {
    const fixNavbar = () => {
      if (window.scrollY >= 20) {
        setFixNavbarTop(true);
        setLogoColor(logoGris);
      } else {
        setFixNavbarTop(false);
        setOriginalColor();
      }
    };

    window.addEventListener('scroll', fixNavbar);

    return () => window.removeEventListener('scroll', fixNavbar);
  }, [setOriginalColor]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAcciones = () => {
    setOpenAcciones(!openAcciones);
  };

  const handleOpenRegistroModal = () => {
    setOpenRegistroModal(true);
    setOpenAcciones(false);
  };
  const handleCloseRegistroModal = () => {
    setOpenRegistroModal(false);
  };
  const handleOpenLoginModal = () => {
    setOpenLoginModal(true);
    setOpenAcciones(false);
  };
  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  const handleCerrarSesion = () => {
    dispatch(userLogoutSuccess());
    setOpenAcciones(false);
  };

  const handleAfiliarmeButton = () => {
    if (stateUser.isAuthenticated) {
      if (stateUser.userInfo?.isVerified) {
        const allyStep = stateUser.userInfo?.allyPendingRegister?.step;
        if (allyStep) {
          navigate(`${t('routes.join')}?step=${allyStep + 1}`);
        } else {
          navigate(t('routes.join'));
        }
      } else {
        toast.dismiss();
        toast.warn(t('login.userNotValidatedYet'), {
          position: 'bottom-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      setOpenRegistroModal(true);
      navigate(t('routes.signUp'));
    }
  };

  const handleScrollTop = () => {
    if (
      location.pathname === '/en' ||
      location.pathname === '/es' ||
      location.pathname === '/en/sign-up' ||
      location.pathname === '/es/sign-up'
    ) {
      window.scrollTo(0, 0);
    }
  };
  return (
    <>
      <div
        className={`${shadow ? 'style-solid-navbar' : ''} ${fixNavbarTop ? 'active' : ''} ${
          fix ? 'navbar-fixed' : 'navbar-relative'
        }`}
      >
        <div className="nav-flex-wrp">
          <div className="img-logo-nav">
            <Link onClick={handleScrollTop} to={t('routes.home')}>
              <img
                src={logoColor}
                style={{
                  width: '100%',
                }}
                alt="Logo"
              />
            </Link>
          </div>

          {!hideLocation && !mobile ? (
            <CountrySelector openModalHandler={() => setOpenCountryModal(true)} />
          ) : null}

          <div className="botones-desktop">
            {mostrarMenu && !mobile ? (
              <div style={{ marginTop: '8px' }}>
                <a
                  href={t('routes.explore')}
                  className={
                    fixNavbarTop ? 'navbar-opciones-centro active' : 'navbar-opciones-centro'
                  }
                >
                  {t('navbar.explore')}
                </a>
                <p className="button-nav-publicar" onClick={handleAfiliarmeButton}>
                  {t('navbar.publish')}
                </p>
              </div>
            ) : null}

            {!hideLocation && mobile ? (
              <CountrySelector openModalHandler={() => setOpenCountryModal(true)} />
            ) : null}

            <div className="boton-acciones" onClick={handleAcciones} ref={botonRef}>
              <MenuIcon
                style={{
                  fontSize: '1.2rem',
                  marginRight: '0.5rem',
                  marginLeft: '0.2rem',
                }}
              />
              {stateUser.isAuthenticated ? (
                <Avatar
                  style={{
                    width: '1.9rem',
                    height: '1.9rem',
                    backgroundColor: '#0d3b66',
                  }}
                >
                  {stateUser.userInfo.nombre.charAt(0).toUpperCase()}
                </Avatar>
              ) : (
                <AccountCircleIcon style={{ fontSize: '1.9rem' }} />
              )}
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div style={{ padding: '0.2rem 0.5rem' }} className="menuIcon" onClick={handleClick}>
            <MenuIcon style={open ? { fontSize: '2rem', color: 'black' } : { fontSize: '2rem' }} />
          </div>
        </div>

        <div ref={wrapperRef} className={openAcciones ? 'menu-acciones active' : 'menu-acciones'}>
          <p className="acciones" onClick={handleAfiliarmeButton}>
            <IoIosMegaphone style={{ fontSize: '16px', margin: '-3px 2px 0 0' }} />{' '}
            {t('navbar.publish')}
          </p>
          {mobile && (
            <>
              <hr style={{ margin: '0' }} />
              <p className="acciones" onClick={() => navigate(t('routes.explore'))}>
                <IoMdSearch style={{ fontSize: '18px', margin: '-3px 2px 0 0' }} />{' '}
                {t('navbar.explore')}
              </p>
            </>
          )}
          {stateUser.isAuthenticated && !!stateUser.userInfo?.isAlly && (
            <>
              <hr style={{ margin: '0' }} />

              <p className="acciones" onClick={() => navigate(t('routes.myBusiness'))}>
                <IoIosSettings style={{ fontSize: '16px', margin: '-3px 2px 0 0' }} />{' '}
                {t('navbar.misNegocios')}
              </p>
            </>
          )}
          {!!stateUser.userInfo?.isAdmin && (
            <>
              <hr style={{ margin: '0' }} />

              <p className="acciones" onClick={() => navigate(t('routes.admin'))}>
                <IoMdFingerPrint style={{ fontSize: '20px', margin: '-3px 2px 0 0' }} /> Admin
              </p>
            </>
          )}
          <hr style={{ margin: '0' }} />
          {stateUser.isAuthenticated ? (
            <p className="acciones" onClick={handleCerrarSesion}>
              <IoIosLogOut style={{ fontSize: '18px', margin: '-3px 2px 0 0' }} />{' '}
              {t('shared.logout')}
            </p>
          ) : (
            <>
              <p className="acciones bold" onClick={handleOpenRegistroModal}>
                <IoIosPersonAdd style={{ fontSize: '20px', margin: '-3px 2px 0 0' }} />{' '}
                {t('shared.signup')}
              </p>

              <p className="acciones" onClick={handleOpenLoginModal}>
                <IoIosLogIn style={{ fontSize: '18px', margin: '-3px 2px 0 0' }} />{' '}
                {t('shared.login')}
              </p>
            </>
          )}
        </div>
      </div>

      <ModalRegistro
        handleClose={handleCloseRegistroModal}
        open={openRegistroModal}
        openLogin={() => setOpenLoginModal(true)}
      />

      <ModalLogin
        handleClose={handleCloseLoginModal}
        open={openLoginModal}
        openRegister={() => setOpenRegistroModal(true)}
      />

      <ModalCountry
        handleClose={() => setOpenCountryModal(false)}
        open={openCountryModal}
        openRegister={() => setOpenCountryModal(true)}
      />
    </>
  );
}

Navbar.propTypes = {
  shadow: PropTypes.bool,
  fix: PropTypes.bool,
  purpleLogo: PropTypes.bool,
  greyLogo: PropTypes.bool,
  openRegister: PropTypes.bool,
  openLogin: PropTypes.bool,
};

Navbar.defaultProps = {
  shadow: false,
  fix: false,
  purpleLogo: false,
  greyLogo: false,
  openRegister: false,
  openLogin: false,
};

