import { Skeleton } from '@mui/material';
import React from 'react';

function BuscadorFiltrosSkeleton() {
  return (
    <div className="bus-fil-wrp">
      <div className="bus-fil-filtros-wrp-1">
        <Skeleton variant="rectangular" width="70%" height="40px" />
        <Skeleton variant="rectangular" width="30%" height="40px" />
      </div>
      <div className="bus-fil-filtros-wrp">
        <Skeleton variant="rectangular" width="25%" height="40px" />
        <Skeleton variant="rectangular" width="25%" height="40px" />
      </div>
    </div>
  );
}

export default BuscadorFiltrosSkeleton;

