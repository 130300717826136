import './PasoWelcome.css';
import React from 'react';

import Button from '@material-ui/core/Button';
import { botonesAtrasYAdelante } from '../PasoForm';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import mex from '../../../Assets/img/flags/mex.jpeg';
import usa from '../../../Assets/img/flags/usa.jpeg';
import { useTranslation } from 'react-i18next';

export default function PasoWelcome({ setStep, setPais, currentPais }) {
  const { t } = useTranslation('global');
  const classes = botonesAtrasYAdelante();
  const mobile = useMediaQuery('(max-width:960px)');

  const nextStep = () => {
    setStep(1);
  };

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        minHeight: '400px',
        marginBottom: '1rem',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={mobile ? { width: '90%', marginTop: '5rem' } : { width: '50%', marginTop: '5rem' }}
      >
        <p className="url-txt-instruc" style={{ width: '100%', textAlign: 'center' }}>
          {t('afiliadosSteps.welcome.title')}
        </p>
        <p
          className="url-txt-instruc2"
          style={
            mobile
              ? {
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '0.8rem',
                }
              : {
                  width: '100%',
                  textAlign: 'center',
                }
          }
        >
          {t('afiliadosSteps.welcome.description')}
        </p>
      </div>
      <p style={{ marginTop: '40px', fontSize: '20px' }}>{t('afiliadosSteps.welcome.country')}</p>
      <div className="paso-welcome-botones-wrp">
        <div
          className={`paso-welcome-botones ${currentPais === '1' ? 'active' : ''}`}
          onClick={() => {
            setPais('1');
          }}
        >
          <div className="paso-welcome-images-flags-div">
            <img src={mex} alt="mexican flag" className="paso-welcome-images-flags-img" />
          </div>
          <p>{t('afiliadosSteps.welcome.mexico')}</p>
        </div>
        <div
          className={`paso-welcome-botones ${currentPais === '2' ? 'active' : ''}`}
          onClick={() => {
            setPais('2');
          }}
        >
          <div className="paso-welcome-images-flags-div">
            <img src={usa} alt="american flag" className="paso-welcome-images-flags-img" />
          </div>
          <p>{t('afiliadosSteps.welcome.usa')}</p>
        </div>
      </div>
      <div
        style={
          mobile
            ? { width: '100%', textAlign: 'center', marginTop: '1rem' }
            : { width: '100%', textAlign: 'center', marginTop: '2rem' }
        }
      >
        <Button
          className={classes.button}
          style={
            mobile ? { width: '100%', marginTop: '2rem' } : { width: '30%', marginTop: '2rem' }
          }
          onClick={nextStep}
          disabled={currentPais === '-1'}
        >
          {t('shared.continue')}
        </Button>
      </div>
    </div>
  );
}

