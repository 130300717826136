export const handleWhatsAppPackageContact = (pais, whatsapp, nombreAliado, nombrePaquete) => {
  let countryCode = '52';
  let phoneNumber = countryCode + whatsapp;

  let msg = `%C2%A1Hola%20${nombreAliado}!%20Encontré%20su%20paquete%20${nombrePaquete}%20a%20través%20de%20Planodi%20y%20me%20gustaría%20pedir%20más%20información.`;

  switch (pais) {
    case 2:
      countryCode = '1';
      phoneNumber = countryCode + whatsapp;
      msg = `%C2%A1Hi%20${nombreAliado}!%20I%20found%20your%20package%20${nombrePaquete}%20on%20Planodi%20and%20I%20would%20like%20to%20get%20more%20details.`;
      break;
  }
  return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${msg}`;
};

export const handleWhatsAppAliadoContact = (pais, whatsapp, returnLink) => {
  let countryCode = '52';
  let phoneNumber = countryCode + whatsapp;
  let msg = `%C2%A1Hola!%20Encontr%C3%A9%20su%20perfil%20en%20Planodi%20y%20me%20gustar%C3%ADa%20saber%20m%C3%A1s%20sobre%20sus%20servicios`;

  switch (pais) {
    case 2:
      countryCode = '1';
      phoneNumber = countryCode + whatsapp;
      msg = `Hello!%20I%20found%20your%20profile%20on%20Planodi%20and%20would%20like%20to%20know%20more%20about%20your%20services`;
      break;
  }

  if (returnLink) {
    return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${msg}.`;
  }

  window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${msg}.`, '_blank');
};

