/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Suspense, lazy } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fade } from '@material-ui/core';

import './HomePage.css';

import Navbar, { useAuthModals } from './Navbar/Navbar';
import { userHeaders } from '../../Utils/headerSetter';
import DotLoading from '../../componentes/DotLoading/DotLoading';
import { PATH_LANGUAGE_MAPPING, useCountry } from '../../componentes/Hooks/CountryContext';
import BuscadorSection from './BuscadorInicio/BuscadorSection';
import Footer from './Footer/Footer';
import TipoEventosBlur from './TipoEventos/TipoEventosBlur';
import CardProveedores from '../../componentes/CardProveedores/CardProveedores';
import PlanodiIcons from '../../Utils/PlanodiIcons';
import PackagesSection from './PackagesSection/PackagesSection';

const ModalRegistro = lazy(() => import('./Navbar/ModalRegistro/ModalRegistro'));
const ModalLogin = lazy(() => import('./Navbar/ModalLogin/ModalLogin'));
const AfiliarSection = lazy(() => import('./AfiliarSection/AfiliarSection'));
const Button = lazy(() => import('@material-ui/core/Button'));

const useStyles = makeStyles(() => ({
  buttonAfil: {
    width: '30%',
    borderRadius: '5px',
    padding: '8px',
    backgroundColor: fade('#3b3b3b', 0.95),
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: fade('#3b3b3b', 0.8),
      color: '#FFFFFF',
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

export default function HomePage() {
  const { t } = useTranslation('global');
  const { countryObject } = useCountry();
  const location = useLocation();
  const stateUser = useSelector((state) => state.user);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [offSetY, setOffSetY] = useState(0);
  const [paquetes, setPaquetes] = useState(null);
  const navigate = useNavigate();

  const { openRegistroModal, openLoginModal, setOpenRegistroModal, setOpenLoginModal } =
    useAuthModals();

  const classes = useStyles();
  const mobile = useMediaQuery('(max-width:960px)');

  const handleScroll = () => setOffSetY(window.scrollY);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    document.title = t('tabTitles.home');
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (token) {
      const urlLan = location?.pathname.split('/')[1];
      axios
        .post(
          `${
            process.env.REACT_APP_ENV === 'development'
              ? process.env.REACT_APP_API_LOCAL
              : process.env.REACT_APP_API_PROD
          }user/verifyEmail`,
          {},
          userHeaders(true, token)
        )
        .then(() => {
          setLoading(false);
          toast.dismiss();
          toast.success(t('toastCard.verifyAccount'), {
            position: 'bottom-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.history.replaceState(null, document.title, navigate(urlLan));
        })
        .catch((e) => {
          setLoading(false);
          let errorMsg = 'verifyAccountError';
          let errorType = 'error';

          if (e.response?.data?.msg === 'accountPrevVerified') {
            errorMsg = e.response?.data?.msg;
            errorType = 'warn';
          }

          toast[errorType](t(`toastCard.${errorMsg}`), {
            position: 'bottom-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          window.history.replaceState(null, document.title, navigate(urlLan));
        });
    }
  }, [location]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }aliado/getInfoHomePage`
      )
      .then((res) => {
        const { cards, locations, categorias, paquetesHomePage } = res.data;
        if (cards.length) setCards(cards);
        setLocations(locations);
        setCategorias(categorias);
        setPaquetes(paquetesHomePage);
        setLoading(false);
      })
      .catch((error) => {
        // TODO: Handler del error
      });
  }, []);

  return (
    <div className="wrp-all-home-page">
      <div className="wrp-home-page">
        <Navbar fix type="black" />
        <BuscadorSection locations={locations} categorias={categorias} loading={loading} />
        <TipoEventosBlur />
        <PackagesSection infoCards={paquetes} loading={loading} />
        <div className="flex-centered" style={{ marginTop: '30px' }}>
          <a
            href={`${window.location.origin}${t('routes.packages')}`}
            className="home-page-ver-mas"
          >
            <button className="button-clean flex-centered">
              <span>{t('shared.seeMorePackages')}</span>
              <PlanodiIcons name="arrow-rigth" style={{ marginLeft: '8px', fontSize: '1.1rem' }} />
            </button>
          </a>
        </div>
        <div style={{ marginTop: '80px' }}>
          <CardProveedores infoCards={cards} widthCard={240} verMasButton loading={loading} />
        </div>
        <div className="flex-centered">
          <a href={`${window.location.origin}${t('routes.explore')}`} className="home-page-ver-mas">
            <button className="button-clean flex-centered">
              <span>{t('shared.seeMore')}</span>
              <PlanodiIcons name="arrow-rigth" style={{ marginLeft: '8px', fontSize: '1.1rem' }} />
            </button>
          </a>
        </div>

        <Suspense
          fallback={
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '3rem',
                marginTop: '3rem',
              }}
            >
              <DotLoading />
            </div>
          }
        >
          <ModalRegistro
            handleClose={() => setOpenRegistroModal(false)}
            open={openRegistroModal}
            titleModal={t('modalSignup.title')}
            openLogin={() => setOpenLoginModal(true)}
          />
          <ModalLogin
            handleClose={() => {
              setOpenLoginModal(false);
            }}
            open={openLoginModal}
            openRegister={() => setOpenRegistroModal(true)}
          />

          <AfiliarSection offSetY={offSetY} stateUser={stateUser} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '4rem',
              marginBottom: '12rem',
            }}
          >
            <Button
              className={`afiliar-section-color-box-btn ${classes.buttonAfil}`}
              href={`${window.location.origin}${t('routes.explore')}`}
              style={mobile ? { width: '80%' } : { width: '30%' }}
            >
              {t('homepage.fullCatalogBtn')}
            </Button>
          </div>
        </Suspense>
      </div>
      <Footer />
    </div>
  );
}

