import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import axios from 'axios';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Navbar from '../../HomePage/Navbar/Navbar';
import { Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import MyTextField from '../../../componentes/formikInputs/MyTextField/MyTextField';
import Button from '@material-ui/core/Button';
import './RecoverPassword.css';
import { fade } from '@material-ui/core';
import ErrorMsg from '../../../componentes/ErrorMsg';
import { toast } from 'react-toastify';
import Loading from '../../../componentes/Loading/Loading';

//Todo Agregar loadings

const useStyles = makeStyles(() => ({
  button: {
    marginTop: '1.6rem',
    justifyContent: 'center',
    borderRadius: '10px',
    fontSize: '1rem',
    backgroundColor: '#8c50ff',
    color: 'white',
    transition: '0.2s',
    padding: '0.5rem 3rem',
    '&:hover': {
      backgroundColor: fade('#8c50ff', 0.9),
      color: 'white',
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

const RecoverPassword = () => {
  const [payload, setPayload] = useState({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation('global');
  const classes = useStyles();
  const validationSchema = yup.object({
    contrasena: yup
      .string()
      .required(t('yup.passwordRequired'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.{8,})/, t('yup.passwordRequirements')),
    contrasena2: yup.string().test('passwords-match', t('yup.passwordMatch'), function (value) {
      return this.parent.contrasena === value;
    }),
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setPayload({
      token: params.get('token'),
      email: params.get('email'),
      lan: params.get('lan'),
    });

    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  useEffect(() => {
    if (payload.token === null && payload.email === null && payload.lan === null) {
      navigate(t('routes.home'));
    }
  }, [payload]);

  const handlePasswordRecovery = (newPassword) => {
    const { token, email, lan } = payload;
    setError(false);
    setErrorMsg('');
    setLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/recoverPassword`,
        {
          token,
          email,
          newPassword,
          lan,
        }
      )
      .then((res) => {
        setLoading(false);
        toast.dismiss();
        toast.success(t(`login.recoverPsw.${res.data.code}`), {
          position: 'bottom-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate(t('routes.home'));
      })
      .catch((e) => {
        setTimeout(() => {
          setLoading(false);
          setError(true);
          setErrorMsg(e.response.data.code);
        }, 1500);
      });
  };

  return (
    <div>
      <Navbar hideLocation shadow />
      <div className="restore-psw-main-form-div">
        <p className="restore-psw-main-form-title">{t('login.recoverPsw.title2')}</p>

        <Formik
          initialValues={{
            contrasena: '',
            contrasena2: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(data) => {
            handlePasswordRecovery(data.contrasena);
          }}
        >
          {({ values, isSubmitting, isValid }) => (
            <Form autoComplete="off">
              <Grid container>
                <Grid item xs={12}>
                  <MyTextField
                    name="contrasena"
                    type="password"
                    placeholder={t('shared.password')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MyTextField
                    name="contrasena2"
                    type="password"
                    placeholder={t('shared.confirmPassword')}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button
                  className={classes.button}
                  type="submit"
                  disabled={!isValid || (!values.contrasena && !values.contrasena2) || loading}
                  style={{ opacity: '0.8' }}
                >
                  {t('login.recoverPsw.recoverPsw2')}
                </Button>
              </Grid>
              {loading ? <Loading /> : null}
            </Form>
          )}
        </Formik>
        {error ? (
          <ErrorMsg
            setError={setError}
            errorMsg={t(`login.recoverPsw.${errorMsg}`)}
            style={{ margin: '1rem 0', width: '100%', textAlign: 'center' }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default RecoverPassword;

