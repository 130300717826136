import React, { useEffect, useRef, useState } from 'react';
import SelectFilterButtons from './SelectFilterButtons';
import PlanodiIcons from '../../Utils/PlanodiIcons';
import { Checkbox } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { componentsShowFilterModal } from '../../actions/componentsActions';
import { useTranslation } from 'react-i18next';
import { tienenLosMismosElementos } from '../../Utils/utilFunctions';

function SelectFilterOptionsMobile() {
  const { t } = useTranslation('global');
  const dispatch = useDispatch();
  const refBg = useRef(null);
  const refModal = useRef(null);
  const { data } = useSelector((state) => state.components.SelectFilterOptionsMobile);
  const { title, items, multipleSelect, textFromTranslationKey, textKey, lastState } = data;
  const [selectedItems, setSelectedItems] = useState(lastState);

  useEffect(() => {
    function handleClickOutside(event) {
      /**
       *  MOBILE - Al dar click se cerrará el modal si se cumple:
       *  1. que el modal esté abierto
       *  2. que el click sea fuera del modal
       */
      if (
        refBg.current &&
        refBg.current.contains(event.target) &&
        refModal.current &&
        !refModal.current.contains(event.target)
      ) {
        dispatch(componentsShowFilterModal({ open: false, data: {} }));
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refBg, refModal]);

  const closeModal = () => {
    dispatch(componentsShowFilterModal({ open: false, data: {} }));
  };

  // Función para manejar la logica de click sobre un item
  const selectItem = (item) => {
    // Si el item ya esta seleccionado, se des-selecciona
    if (selectedItems.includes(item.id)) {
      removeItem(item);
      return;
    }
    if (multipleSelect) {
      setSelectedItems((prevState) => [...prevState, item.id]);
    } else {
      setSelectedItems([item.id]);
    }
  };

  // Función para des-seleccionar un item
  const removeItem = (item) => {
    setSelectedItems((prevState) => prevState.filter((itemId) => itemId !== item.id));
  };

  const clearSelections = () => {
    setSelectedItems([]);
  };

  const handleSubmit = () => {
    dispatch(
      componentsShowFilterModal({
        open: true,
        data: { title, items, multipleSelect, submit: selectedItems },
      })
    );
  };

  return (
    <div className="modal-filtros-open" ref={refBg}>
      <div className="modal-filtros-open-content" ref={refModal}>
        <div className="modal-filtros-open-content-header">
          <p></p>
          <p>{title}</p>
          <div onClick={closeModal}>
            <PlanodiIcons name="close" style={{ fontSize: '1.3rem' }} />
          </div>
        </div>
        <div className="modal-filtros-open-content-ops">
          {items.map((item) => (
            <div
              key={item.id}
              className="sel-fil-options-div-item"
              onClick={() => {
                selectItem(item);
              }}
            >
              {item?.icon ? (
                <span style={{ marginRight: '10px' }}>
                  <PlanodiIcons name={item.icon} style={{ fontSize: '1.1rem' }} />
                </span>
              ) : null}
              <p
                className={`noselect sel-filtros-p-item ${
                  multipleSelect ? '' : 'sel-fil-options-div-item-single-select-p'
                } ${selectedItems.includes(item.id) ? 'active' : ''}`}
              >
                {textFromTranslationKey ? t(item.key) : item[textKey]}
              </p>
              {multipleSelect && (
                <Checkbox
                  style={{ marginLeft: '10px' }}
                  checked={selectedItems.includes(item.id)}
                  color="secondary"
                />
              )}
            </div>
          ))}
        </div>
        <SelectFilterButtons
          clear={clearSelections}
          submit={handleSubmit}
          multipleSelect={multipleSelect}
          submitDisabled={tienenLosMismosElementos(lastState, selectedItems)}
        />
      </div>
    </div>
  );
}

export default SelectFilterOptionsMobile;

