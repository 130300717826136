import './CardProveedores.css';
import React, { useEffect, useRef, useState } from 'react';

import CardGeneral from '../CardGeneral/CardGeneral';
import BuscadorGridSkeleton from '../../Pages/Buscador/BuscadorGrid/BuscadorGridSkeleton';
import { useTranslation } from 'react-i18next';

/**
 * @param  title - string
 * @param infoCards
 * @param  widthCard - number
 * @param  verMasButton - bool
 * @param  loading - bool
 * */

const CardProveedores = ({ title, infoCards, widthCard, verMasButton, loading }) => {
  const { t } = useTranslation('global');
  const [totalCards, setTotalCards] = useState(8);
  const divRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        const width = divRef.current.offsetWidth;
        if (width >= 4 * 300 + 20 * 3) {
          setTotalCards(8);
        } else if (width >= 2 * 300 + 20) {
          setTotalCards(6);
        } else {
          setTotalCards(5);
        }
      }
    };

    // Escuchar el evento de cambio de tamaño global de la ventana
    window.addEventListener('resize', handleResize);

    // Llamar handleResize inicialmente para obtener el ancho inicial
    handleResize();

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="cardProveedores-container">
      <p className="cardProveedores-title">{t('homepage.tops')} </p>
      <p className="cardProveedores-subtitle">{t('homepage.topsSubtitle')}</p>
      {loading ? (
        <BuscadorGridSkeleton numberOfCards={8} />
      ) : (
        <>
          {infoCards ? (
            <div className="cardProveedores-main-wrp" ref={divRef}>
              {infoCards.slice(0, totalCards).map((item) => (
                <CardGeneral key={item.id} info={item} />
              ))}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default CardProveedores;

