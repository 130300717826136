import './ContactBox.css';

import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import 'moment/locale/es';
import { AiFillYoutube, AiFillInstagram, AiFillFacebook } from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ModalContact from './ModalContact';
import { useTranslation } from 'react-i18next';
import { HiChatAlt, HiOutlineChatAlt } from 'react-icons/hi';

export const botonesContacto = makeStyles(() => ({
  button: {
    backgroundColor: '#8c50ff',
    color: 'white',
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: '0.9rem',
    '&:hover': {
      backgroundColor: '#0D3B66',
      color: 'white',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonMobile: {
    height: '100%',
    width: '50px',
    backgroundColor: '#8c50ff',
    color: 'white',
    fontSize: '0.8rem',
    textTransform: 'none',
    padding: '12px',
    '&:hover': {
      backgroundColor: '#0D3B66',
      color: 'white',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  whiteButton: {
    backgroundColor: 'white',
    color: '#3b3b3b',
    border: '1px solid #3b3b3b',
    textTransform: 'none',
    padding: '12px 24px',
    fontSize: '0.9rem',
    '&:hover': {
      backgroundColor: 'rgba(246,246,246,0.9)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  whiteButtonMobile: {
    flex: 1,
    height: '100%',
    backgroundColor: 'white',
    color: '#3b3b3b',
    border: '1px solid #3b3b3b',
    textTransform: 'none',
    padding: '12px',
    fontSize: '0.8rem',
    '&:hover': {
      backgroundColor: 'rgba(246,246,246,0.9)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

/**
 * @param {{id: number,title: string, price: string, mainImage: string, description: string, allImages: [string]}} data
 * @param fixBox
 * @param width
 * @param onClickVerMas
 * */
export default function ContactBox({
  fixBox,
  width,
  handleWhatsAppContact,
  isPendingValidation,
  paisIdAliado,
  aliado,
  email,
  phoneNumber,
  whatsAppNumber,
  webSite,
  instagram,
  facebook,
  youtube,
  country,
}) {
  const { t } = useTranslation('global');
  const [isModalOpen, setModalOpen] = useState(false);

  const classes = botonesContacto();
  const mobile = useMediaQuery('(max-width:960px)');

  return (
    <>
      {isModalOpen && (
        <ModalContact
          open={true}
          handleClose={() => setModalOpen(false)}
          aliado={aliado}
          paisIdAliado={paisIdAliado}
          phoneNumber={phoneNumber}
          whatsAppNumber={whatsAppNumber}
          email={email}
          handleWhatsAppContact={handleWhatsAppContact}
          webSite={webSite}
          instagram={instagram}
          facebook={facebook}
          youtube={youtube}
          type={'aliado'}
        />
      )}
      {mobile ? (
        <div className="temp2-sel-box-wrp">
          <div className="flex w-full" style={{ gap: '10px', alignItems: 'center' }}>
            <Button
              className={Boolean(whatsAppNumber) ? classes.whiteButtonMobile : classes.buttonMobile}
              disabled={isPendingValidation}
              onClick={() => setModalOpen(true)}
              style={{ width: '100%' }}
            >
              {Boolean(whatsAppNumber) ? (
                <HiOutlineChatAlt style={{ fontSize: '18px', marginRight: '10px' }} />
              ) : (
                <HiChatAlt style={{ fontSize: '18px', marginRight: '10px' }} />
              )}
              {t('temp2.contactInfo')}
            </Button>
            {Boolean(whatsAppNumber) && (
              <Button
                className={classes.buttonMobile}
                disabled={isPendingValidation}
                onClick={() => handleWhatsAppContact(paisIdAliado, whatsAppNumber)}
              >
                <IoLogoWhatsapp style={{ fontSize: '20px' }} />
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className={fixBox ? 'temp2-sel-box-wrp-fix' : 'temp2-sel-box-wrp'} style={{ width }}>
          <Button
            className={Boolean(whatsAppNumber) ? classes.whiteButton : classes.button}
            disabled={isPendingValidation}
            style={{ width: '100%' }}
            onClick={() => setModalOpen(true)}
          >
            {Boolean(whatsAppNumber) ? (
              <HiOutlineChatAlt style={{ fontSize: '18px', marginRight: '10px' }} />
            ) : (
              <HiChatAlt style={{ fontSize: '18px', marginRight: '10px' }} />
            )}
            {t('temp2.contactInfo')}
          </Button>
          {Boolean(whatsAppNumber) && (
            <Button
              className={classes.button}
              disabled={isPendingValidation}
              style={{ width: '100%', marginTop: '1rem' }}
              onClick={() => handleWhatsAppContact(paisIdAliado, whatsAppNumber)}
            >
              <IoLogoWhatsapp style={{ fontSize: '16px', marginRight: '15px' }} />{' '}
              {t('temp2.sendWhatsApp')}
            </Button>
          )}

          {(instagram || facebook || youtube) && (
            <>
              <p className="temp2-sel-box-wrp-name">{t('temp2.socialMedia')}</p>
              <div className="temp2-sel-box-redes-s-wrp">
                {!!instagram && (
                  <AiFillInstagram
                    className="temp2-sel-box-redes-s-wrp-icon"
                    onClick={() => {
                      window.open(instagram, '_blank');
                    }}
                  />
                )}
                {!!facebook && (
                  <AiFillFacebook
                    className="temp2-sel-box-redes-s-wrp-icon"
                    onClick={() => {
                      window.open(facebook, '_blank');
                    }}
                  />
                )}
                {!!youtube && (
                  <AiFillYoutube
                    className="temp2-sel-box-redes-s-wrp-icon"
                    onClick={() => {
                      window.open(youtube, '_blank');
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

ContactBox.propTypes = {
  openLogin: PropTypes.func,
  handleWhatsAppContact: PropTypes.func,
  isPendingValidation: PropTypes.bool,
};

ContactBox.defaultProps = {
  openLogin: () => {},
  handleWhatsAppContact: () => {},
  isPendingValidation: false,
};

