import React from 'react';
import { useField } from 'formik';
import { OutlinedInput, Select } from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl: () => null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginTop: '58px',
      display: 'absolute',
      top: 0,
    },
  },
};

export default function MyMultipleSelect({
  name,
  children,
  disabled = false,
  label = null,
  style = {},
  styleInput = {},
  customHandleChange = null,
  renderValue = (selected) => selected.join(', '),
}) {
  const [field, meta, fieldHelpers] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <div style={{ margin: '0 0.5rem', ...style }}>
      {label ? <p className="formik-text-input">{label}</p> : null}
      <Select
        id={name}
        multiple
        onChange={(e) => {
          const values = e.target.value;
          if (values.includes(-1) && values.length > 1) {
            values.splice(values.indexOf(-1), 1);
          }
          fieldHelpers.setValue(values);
          if (customHandleChange) {
            customHandleChange(e);
          }
        }}
        input={<OutlinedInput />}
        renderValue={renderValue}
        MenuProps={MenuProps}
        error={!!errorText}
        value={field.value}
        onBlur={field.onBlur}
        disabled={disabled}
        style={{ width: '100%', ...styleInput }}
      >
        {children}
      </Select>
    </div>
  );
}
