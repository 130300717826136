import React from 'react';
import { GiPartyFlags, GiPartyPopper, GiHairStrands } from 'react-icons/gi';
import { BiDrink } from 'react-icons/bi';
import {
  IoLocationSharp,
  IoLocationOutline,
  IoRestaurantOutline,
  IoCarOutline,
} from 'react-icons/io5';
import { BsMusicNoteBeamed } from 'react-icons/bs';
import { FiCamera, FiMail } from 'react-icons/fi';
import { CgDice6 } from 'react-icons/cg';
import { MdRoomService } from 'react-icons/md';
import {
  FaCandyCane,
  FaChair,
  FaRegAddressBook,
  FaBold,
  FaItalic,
  FaUnderline,
  FaListUl,
  FaListOl,
  FaCode,
  FaQuoteLeft,
  FaAlignJustify,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaGithub,
} from 'react-icons/fa';
import { RiCake2Line, RiNumber1, RiNumber2 } from 'react-icons/ri';
import SearchIcon from '@material-ui/icons/Search';
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdClose,
  IoIosArrowForward,
  IoIosArrowBack,
} from 'react-icons/io';
import { CiFries, CiDiscount1 } from 'react-icons/ci';
import { HiOutlineLightBulb } from 'react-icons/hi2';

export default function PlanodiIcons({ name, style }) {
  const icons = {
    restaurant: <IoRestaurantOutline style={style} />,
    drink: <BiDrink style={style} />,
    close: <IoMdClose style={style} />,
    location: <IoLocationSharp style={style} />,
    'location-outline': <IoLocationOutline style={style} />,
    music: <BsMusicNoteBeamed style={style} />,
    camera: <FiCamera style={style} />,
    chair: <FaChair style={style} />,
    dice: <CgDice6 style={style} />,
    flags: <GiPartyFlags style={style} />,
    book: <FaRegAddressBook style={style} />,
    food: <MdRoomService style={style} />,
    mail: <FiMail style={style} />,
    candy: <FaCandyCane style={style} />,
    cake: <RiCake2Line style={style} />,
    popper: <GiPartyPopper style={style} />,
    hair: <GiHairStrands style={style} />,
    search: <SearchIcon style={style} />,
    'arrow-down': <IoIosArrowDown style={style} />,
    'arrow-up': <IoIosArrowUp style={style} />,
    'arrow-rigth': <IoIosArrowForward style={style} />,
    'arrow-left': <IoIosArrowBack style={style} />,
    FOOD: <IoRestaurantOutline style={style} />,
    DRINK: <BiDrink style={style} />,
    LOCATION: <IoLocationOutline style={style} />,
    MUSIC: <BsMusicNoteBeamed style={style} />,
    CAMARA: <FiCamera style={style} />,
    CHAIR: <FaChair style={style} />,
    DICE: <CgDice6 style={style} />,
    DECORATION: <GiPartyFlags style={style} />,
    AGENDA: <FaRegAddressBook style={style} />,
    WAITRESS: <MdRoomService style={style} />,
    ENVELOPE: <FiMail style={style} />,
    CANDY: <FaCandyCane style={style} />,
    CAKE: <RiCake2Line style={style} />,
    PARTY: <GiPartyPopper style={style} />,
    HAIR: <GiHairStrands style={style} />,
    CAR: <IoCarOutline style={style} />,
    SNACK: <CiFries style={style} />,
    LIGHT: <HiOutlineLightBulb style={style} />,
    discount: <CiDiscount1 style={style} />,
    format_bold: <FaBold style={style} />,
    format_italic: <FaItalic style={style} />,
    format_underlined: <FaUnderline style={style} />,
    format_list_bulleted: <FaListUl style={style} />,
    format_list_numbered: <FaListOl style={style} />,
    code: <FaCode style={style} />,
    looks_one: <RiNumber1 style={style} />,
    looks_two: <RiNumber2 style={style} />,
    format_quote: <FaQuoteLeft style={style} />,
    format_align_left: <FaAlignLeft style={style} />,
    format_align_center: <FaAlignCenter style={style} />,
    format_align_right: <FaAlignRight style={style} />,
    format_align_justify: <FaAlignJustify style={style} />,
    github: <FaGithub style={style} />,
  };

  return <>{icons[name]}</>;
}

