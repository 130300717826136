import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Actualizar estos IDs si la tabla de paises se actualiza en la base de datos
export const COUNTRY_IDS = {
  MEXICO: '1',
  USA: '2',
};

export const LANGUAGE_IDS = {
  SPANISH: '1',
  ENGLISH: '2',
};

export const PATH_LANGUAGE_MAPPING = {
  [LANGUAGE_IDS.SPANISH]: '/es',
  [LANGUAGE_IDS.ENGLISH]: '/en',
};

// Crear un contexto para el país
const CountryContext = createContext();

// Proveedor del contexto para manejar el estado del país
export const CountryProvider = ({ children }) => {
  const { t, i18n } = useTranslation('global');
  const navigate = useNavigate();

  const initialCountryId = i18n.language?.includes('es') ? COUNTRY_IDS.MEXICO : COUNTRY_IDS.USA;
  const initialLanguageId = i18n.language?.includes('es')
    ? LANGUAGE_IDS.SPANISH
    : LANGUAGE_IDS.ENGLISH;
  const initialI18n = {
    countryId: localStorage.getItem('country') || initialCountryId,
    languageId: localStorage.getItem('language') || initialLanguageId,
  };

  const [countryObject, setCountryObject] = useState(initialI18n);

  const handleNavigation = (languageId) => {
    if (window.location.pathname !== '/') {
      const url = new URL(window.location.href);
      if (languageId === LANGUAGE_IDS.SPANISH) {
        navigate(
          window.location.pathname.replace(
            PATH_LANGUAGE_MAPPING[LANGUAGE_IDS.ENGLISH],
            PATH_LANGUAGE_MAPPING[LANGUAGE_IDS.SPANISH]
          ) + url.search
        );
      } else {
        navigate(
          window.location.pathname.replace(
            PATH_LANGUAGE_MAPPING[LANGUAGE_IDS.SPANISH],
            PATH_LANGUAGE_MAPPING[LANGUAGE_IDS.ENGLISH]
          ) + url.search
        );
      }
    }
  };

  // Función para actualizar el país y guardar en localStorage
  const updateCountry = ({ countryId, languageId }) => {
    setCountryObject({ countryId, languageId });
    i18n.changeLanguage(languageId === LANGUAGE_IDS.SPANISH ? 'es' : 'en');
    handleNavigation(languageId);
    localStorage.setItem('country', countryId);
    localStorage.setItem('language', languageId);
    localStorage.setItem('isI18NSet', true);
  };

  // Función para obtener el nombre del país en su idioma
  const getCountryName = (countryId) =>
    t(`shared.${countryId === COUNTRY_IDS.MEXICO ? 'mx' : 'usa'}`);

  // Función para obtener el nombre del idioma
  const getLanguageName = (languageId) =>
    t(`shared.${languageId === LANGUAGE_IDS.SPANISH ? 'es' : 'en'}`);

  const getCountryNameAndLang = ({ countryId, languageId }) => {
    return `${getCountryName(countryId)} | ${getLanguageName(languageId)}`;
  };

  const getCurrentLanguagePath = () => {
    return PATH_LANGUAGE_MAPPING[countryObject.languageId];
  };

  return (
    <CountryContext.Provider
      value={{
        countryObject,
        updateCountry,
        getCountryNameAndLang,
        getLanguageName,
        getCurrentLanguagePath,
      }}
    >
      {children}
    </CountryContext.Provider>
  );
};

// Hook personalizado para acceder al contexto del país
export const useCountry = () => {
  const context = useContext(CountryContext);
  if (!context) {
    throw new Error('useCountry debe ser utilizado dentro de un CountryProvider');
  }
  return context;
};

