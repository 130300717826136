import { Skeleton } from '@mui/material';
import React from 'react';

function BuscadorGridSkeleton({ numberOfCards = 20 }) {
  return (
    <div className="buscador-grid-wrp">
      {Array.from({ length: numberOfCards }, (_, i) => i + 1).map((i) => (
        <div style={{ width: '100%' }} key={i}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height="250px"
            style={{ borderRadius: '8px' }}
          />
          <Skeleton width="60%" height="30px" style={{ marginTop: '3rem' }} />
          <Skeleton height="30px" />
        </div>
      ))}
    </div>
  );
}

export default BuscadorGridSkeleton;

