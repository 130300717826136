import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Form as FormikForm } from 'formik';
import Button from '@material-ui/core/Button';

import { botonesAtrasYAdelante } from '../PasoForm';
import MyLongText from '../../formikInputs/MyLongText/MyLongText';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function FormInfoGeneralProveedores({
  setStep,
  errors,
  isEdition,
  isMainPage,
  isAdminPage,
  backAdminPage,
  t,
}) {
  const navigate = useNavigate();
  const buttonTypes = botonesAtrasYAdelante();
  const movil = useMediaQuery('(max-width:960px)');

  return (
    <FormikForm style={{ width: '100%' }}>
      <Grid container>
        <Grid
          item
          xs={12}
          className="postion-rel"
          style={movil ? { padding: '0', marginTop: '1rem' } : { padding: '1rem 0 1rem 1rem' }}
        >
          <p style={{ margin: '0 0 0.5rem 0' }} className="alaido-form-label1">
            {t('afiliadosSteps.details.description')}{' '}
            <span className="alaido-form-label2">{t('afiliadosSteps.details.description2')}</span>
          </p>
          <MyLongText
            name="descripcion"
            rows={5}
            style={{ width: '100%' }}
            placeholder={t('afiliadosSteps.details.servicesExmaple2')}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className="postion-rel"
          style={movil ? { padding: '0', marginTop: '2rem' } : { padding: '1rem 0 1rem 1rem' }}
        >
          <p style={{ margin: '0 0 0.5rem 0' }} className="alaido-form-label1">
            {t('afiliadosSteps.details.services')}{' '}
            <span className="alaido-form-label2">{t('afiliadosSteps.details.services2')}</span>
          </p>
          <MyLongText
            name="servicios"
            rows={5}
            style={{ width: '100%' }}
            placeholder={t('afiliadosSteps.details.servicesExmaple3')}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className="postion-rel"
          style={movil ? { padding: '0', marginTop: '2rem' } : { padding: '1rem 0 1rem 1rem' }}
        >
          <p style={{ margin: '0 0 0.5rem 0' }} className="alaido-form-label1">
            {t('afiliadosSteps.details.experience')}{' '}
            <span className="alaido-form-label2">{t('afiliadosSteps.details.experience2')}</span>
          </p>
          <MyLongText
            name="experiencia"
            rows={5}
            style={{ width: '100%' }}
            placeholder={t('afiliadosSteps.details.experienceExample')}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className="postion-rel"
          style={movil ? { padding: '0', marginTop: '2rem' } : { padding: '1rem 0 1rem 1rem' }}
        >
          <p style={{ margin: '0 0 0.5rem 0' }} className="alaido-form-label1">
            {t('afiliadosSteps.details.serviceAddress')}
            {'  '}
            <span className="alaido-form-label2">
              {t('afiliadosSteps.details.serviceAddress2')}
            </span>
          </p>
          <MyLongText
            name="dondeOfrecen"
            placeholder={t('afiliadosSteps.details.serviceAddressExample')}
            rows={3}
            style={{ width: '100%' }}
          />
        </Grid>

        <Grid
          item
          container
          style={
            isEdition
              ? { marginTop: '1.5rem', marginBottom: '1.5rem' }
              : { marginTop: '3rem', marginBottom: '3rem' }
          }
        >
          {isEdition && !isAdminPage ? (
            <Grid item xs={12} style={movil ? { textAlign: 'center' } : { textAlign: 'right' }}>
              <Button
                className={buttonTypes.cancelButton}
                style={movil ? { width: '100%' } : { width: '30%' }}
                onClick={() => (isMainPage ? setStep(-1) : navigate(t('routes.myBusiness')))}
              >
                {isMainPage ? t('shared.back') : t('shared.cancel')}
              </Button>
              <Button
                type="submit"
                className={buttonTypes.saveButton}
                style={
                  movil
                    ? { width: '100%', marginTop: '1.5rem' }
                    : { width: '30%', marginLeft: '1.5rem' }
                }
              >
                {isMainPage ? t('shared.saveAndContinue') : t('shared.save')}
              </Button>
              {Object.keys(errors).length > 0 ? (
                <p
                  style={{
                    color: '#f44336',
                    fontFamily: 'Roboto',
                    marginTop: '0.5rem',
                    fontSize: '14px',
                  }}
                >
                  {t('yup.incomplete')}
                </p>
              ) : null}
            </Grid>
          ) : (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                className={buttonTypes.cancelButton}
                style={
                  movil
                    ? { width: '100%', marginBottom: '1rem' }
                    : { width: '30%', marginRight: '1rem' }
                }
                onClick={() => {
                  if (isAdminPage) {
                    backAdminPage();
                  } else {
                    setStep(-1);
                  }
                }}
              >
                {t('shared.back')}
              </Button>
              <Button
                type="submit"
                className={buttonTypes.button}
                style={movil ? { width: '100%' } : { width: '30%' }}
              >
                {t('shared.continue')}
              </Button>
              {Object.keys(errors).length > 0 ? (
                <p
                  style={{
                    color: '#f44336',
                    fontFamily: 'Roboto',
                    marginTop: '0.5rem',
                    fontSize: '14px',
                  }}
                >
                  {t('yup.incomplete')}
                </p>
              ) : null}
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormikForm>
  );
}

