import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { userHeaders } from '../../../Utils/headerSetter';
import { toast } from 'react-toastify';

import '../Admin.css';
import Loading from '../../../componentes/Loading/Loading';
import { Line } from 'react-chartjs-2';
import { FcApproval, FcCancel } from 'react-icons/fc';
import { FaSync } from 'react-icons/fa';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ModalApproval from './ModalApproval/ModalApproval';
import { Button } from '@material-ui/core';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const halagos = [
  'crack',
  'maquina',
  'mastodonte',
  'tifón',
  'fiera',
  'titan',
  'jefe',
  'maestro',
  'crack, maquina, fiera, jefe, tifón, número 1, figura, mastodonte, toro, furia, ciclón, tornado, artista, fenómeno, campeón, maestro, torero, socio..',
];

export default function HomeAdmin({ checkIfIsAdmin }) {
  const { t } = useTranslation('global');
  const [loading, setLoading] = useState(false);
  const [negocios, setNegocios] = useState(undefined);
  const [halago, setHalago] = useState(halagos[0]);
  const [totalUsuarios, setTotalUsuarios] = useState(0);
  const [totalAliados, setTotalAliados] = useState(0);
  const [semanas, setSemanas] = useState([]);
  const [aliadosPorSemana, setAliadosPorSemana] = useState([]);
  const [openModalApproval, setOpenModalApproval] = useState(false);
  const [syncRankingDisabled, setSyncRankingDisabled] = useState(false);
  const [aliadoId, setAliadoId] = useState({});

  const movil = useMediaQuery('(max-width:960px)');

  useEffect(() => {
    checkIfIsAdmin();
    axios
      .get(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }admin/home`,
        userHeaders(false)
      )
      .then((res) => {
        const { aliadosPorVerificar, aliadosPorSemana, semanas, totalUsuarios, totalAliados } =
          res.data;
        setNegocios(aliadosPorVerificar);
        setSemanas(semanas);
        setAliadosPorSemana(aliadosPorSemana);
        setTotalUsuarios(totalUsuarios);
        setTotalAliados(totalAliados);
        setHalago(halagos[Math.floor(Math.random() * halagos.length)]);
      })
      .catch(() => {
        setNegocios([]);
        toast.error('Parece que hubo un error al leer tus negocios!', {
          position: 'bottom-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, []);

  const handleApprove = (idAliado) => {
    setLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/validarNegocio`,
        { idAliado },
        userHeaders()
      )
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
        toast.error('Parece que hubo un error al validar el negocio!', {
          position: 'bottom-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleReject = (idAliado) => {
    setAliadoId(idAliado);
    setOpenModalApproval(true);
  };

  const handleRejectConfirm = (idAliado) => {
    setLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }admin/rejectAlly`,
        { id: idAliado },
        userHeaders()
      )
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
        toast.error('¡Parece que hubo un error al borrar el negocio!', {
          position: 'bottom-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const actualizarRankings = async () => {
    setSyncRankingDisabled(true);
    try {
      checkIfIsAdmin();
      await axios.get(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }admin/refresh-rankings`,
        userHeaders(false)
      );
      toast.success(
        'Cards actualizados - el nuevo orden se verá reflejado en los próximos minutos.',
        {
          position: 'bottom-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {
      toast.error('Parece que hubo un error al leer tus negocios!', {
        position: 'bottom-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return !negocios || loading ? (
    <Loading helperText="Cargando..." />
  ) : (
    <div className="admin-wrapper">
      <div className="admin-content-wrp">
        <p className="admin-title">Bienvenido, {halago}.</p>
        {negocios.length > 0 && (
          <>
            <p className="admin-subtitle">
              Negocios nuevos por validar para que puedan ser vistos por los clientes.
            </p>
            <table className="table-admin">
              <thead>
                <tr>
                  <th>Id</th>

                  <th>Nombre</th>

                  <th>Validar</th>
                </tr>
              </thead>

              <tbody>
                {negocios.map((negocio) => (
                  <tr key={negocio.idAliado}>
                    <td>{negocio.idAliado}</td>

                    <td>
                      <a
                        href={`${t('routes.profile')}/${negocio.pathUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {negocio.nombre}
                      </a>
                    </td>

                    <td style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1.5rem' }}>
                      <button
                        className="table-admin-eliminar-btn"
                        onClick={() => handleReject(negocio.idAliado)}
                      >
                        Eliminar
                        <FcCancel style={{ marginTop: '-2px', marginLeft: '0px' }} />
                      </button>
                      <button
                        className="table-admin-validar-btn"
                        onClick={() => handleApprove(negocio.idAliado)}
                      >
                        Validar <FcApproval style={{ marginTop: '-2px', marginLeft: '-3px' }} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ModalApproval
              open={openModalApproval}
              handleClose={() => {
                setAliadoId({});
                setOpenModalApproval(false);
              }}
              handleRejectConfirm={handleRejectConfirm}
              aliadoId={aliadoId}
              halago={halago}
            />
          </>
        )}
        {semanas.length && aliadosPorSemana.length ? (
          <div className="admin-home-plot">
            <div className="admin-home-plot-div">
              <Line
                data={{
                  labels: semanas,
                  datasets: [
                    {
                      label: 'Aliados registrados en el último mes',
                      data: aliadosPorSemana,
                      backgroundColor: 'rgba(75,192,192,0.2)',
                      borderColor: 'rgba(75,192,192,1)',
                    },
                  ],
                }}
                height={movil ? 8 : 5}
                width={10}
                options={{
                  scales: {
                    x: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: '# de semana',
                      },
                    },
                    y: {
                      title: {
                        display: true,
                        text: '# de aliados',
                      },
                      beginAtZero: true,
                      ticks: {
                        stepSize: 1,
                      },
                    },
                  },
                }}
              />
            </div>
            <div className="admin-home-plot-div">
              <p className="admin-home-totales-title">Totales:</p>
              <div className="admin-home-totales">
                <div className="admin-home-totales-box">
                  <p className="admin-home-totales-num">{totalAliados}</p>
                  <p className="admin-home-totales-desc">Aliados</p>
                </div>
                <div className="admin-home-totales-box">
                  <p className="admin-home-totales-num">{totalUsuarios}</p>
                  <p className="admin-home-totales-desc">usuarios</p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="flex-centered" style={{ margin: '3rem 0 4rem 0' }}>
          <Button
            variant="outlined"
            startIcon={<FaSync />}
            onClick={actualizarRankings}
            disabled={syncRankingDisabled}
          >
            Actualizar Rankings
          </Button>
        </div>
      </div>
    </div>
  );
}

