import axios from 'axios';
import { useInfiniteQuery } from 'react-query';
import { userHeaders } from '../../Utils/headerSetter';

/**
 * Load content
 */
export function useLoadProfileCards(
  totalCards,
  locationFilter,
  freeFilter,
  categoryFilter,
  eventFilter,
  queryNames
) {
  async function getCards({ pageParam = 1 }) {
    const category = categoryFilter.join(',');
    const event = eventFilter.join(',');
    const location = locationFilter.join(',');
    const res = await axios.get(
      `${
        process.env.REACT_APP_ENV === 'development'
          ? process.env.REACT_APP_API_LOCAL
          : process.env.REACT_APP_API_PROD
      }aliado/${
        queryNames[0]
      }?lastRankingPosition=${pageParam}&category=${category}&event=${event}&free=${freeFilter}&location=${location}`,
      userHeaders(false)
    );
    return res.data;
  }

  return useInfiniteQuery(
    [queryNames[1], locationFilter, freeFilter, categoryFilter, eventFilter],
    getCards,
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage[lastPage.length - 1]?.rankingPosition < totalCards) {
          return lastPage[lastPage.length - 1].rankingPosition;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(totalCards),
    }
  );
}

