import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import PublishIcon from '@material-ui/icons/Publish';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import axios from 'axios';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useNavigate } from 'react-router-dom';

import { userHeaders } from '../../../Utils/headerSetter';
import Loading from '../../Loading/Loading';
import { filterImageSize, imageCompressorAndFilter } from '../../../Utils/filterSize';
import ErrorMsg from '../../ErrorMsg';
import DotLoading from '../../DotLoading/DotLoading';
import { botonesAtrasYAdelante } from '../PasoForm';

import './PasoImagenes.css';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io/index';
import { useTranslation } from 'react-i18next';

export default function PasoImagenes({
  premium,
  setStep,
  idAliado,
  infoPlan,
  currentImages,
  isEdition,
  isCollapsible,
  initialMain,
  isMainPage,
  isSelected,
  setIsSelected,
  isAdminPage,
  backAdminPage,
  submitStepAdmin,
  plan,
}) {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const buttonTypes = botonesAtrasYAdelante();
  const mobile = useMediaQuery('(max-width:960px)');

  const [imagenes, setImagenes] = useState([]);
  const [savedImages, setSavedImages] = useState(currentImages);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  const [advertenciaFiles, setAdvertenciaFiles] = useState(false);
  const [advertenciaSize, setAdvertenciaSize] = useState(false);
  const [error, setError] = useState(false);
  const [premiumAndMoreImages, setPremiumAndMoreImages] = useState(false);
  const [collapse, setCollapse] = useState(!isSelected);
  const [errorMsg, setErrorMsg] = useState(t('shared.unknownError'));
  const [mainImage, setMainImage] = useState(initialMain);
  const [totalImages, setTotalImages] = useState(0);

  useEffect(() => {
    setTotalImages(savedImages.concat(imagenes).length);
  }, [savedImages, imagenes]);

  useEffect(() => {
    if (isCollapsible && !isSelected) setCollapse(true);
  }, [isCollapsible, isSelected]);

  const isImageLimitPlanExceeded = (files) => {
    switch (plan?.tier) {
      case 'free':
        if (totalImages >= 7 || totalImages + files > 7) {
          return true;
        }
        break;

      case 'basic':
        if (totalImages >= 7 || totalImages + files > 7) {
          return true;
        }
        break;

      case 'premium':
        if (totalImages >= 50 || totalImages + files > 50) {
          return true;
        }
        break;
      case 'pro':
        if (totalImages >= 100 || totalImages + files > 100) {
          return true;
        }
        break;
      default:
        if (totalImages >= 7 || totalImages + files > 7) {
          return true;
        } else {
          return false;
        }
    }
  };

  const handleChange = async (event) => {
    setLoadingImages(true);
    let filtroSizeRes;
    try {
      filtroSizeRes = await imageCompressorAndFilter(10000000, [...event.target.files]);
    } catch (e) {
      filtroSizeRes = filterImageSize(10000000, [...event.target.files]);
    }
    setLoadingImages(false);
    let files = filtroSizeRes.filteredImages;
    if (filtroSizeRes.deleted) {
      setAdvertenciaSize(true);
    }
    if (files.length > infoPlan.cantidadDeFotos && !premium) {
      files = files.slice(0, infoPlan.cantidadDeFotos);
      setAdvertenciaFiles(true);
    }
    if (premium && files.length > 7 && !isEdition) {
      files = files.slice(0, 7);
      setPremiumAndMoreImages(true);
    }
    setMainImage(files[0].name);
    setImagenes(files);
  };

  const handleMoreImages = async (event) => {
    setLoadingImages(true);

    if (isImageLimitPlanExceeded(event.target.files.length)) {
      setLoadingImages(false);
      setError(true);
      setErrorMsg(t('afiliadosSteps.images.error5'));

      setTimeout(() => {
        setError(false);
      }, 5000);

      return;
    }
    const hasDuplicate = Array.from(event.target.files).some((file) =>
      imagenes.some((imagen) => imagen.name === file.name)
    );

    if (hasDuplicate) {
      setLoadingImages(false);
      setError(true);
      setErrorMsg(t('afiliadosSteps.images.error4'));

      setTimeout(() => {
        setError(false);
      }, 5000);
      return;
    } else {
      setError(false);
    }

    let filtroSizeRes;

    try {
      filtroSizeRes = await imageCompressorAndFilter(10000000, [
        ...imagenes,
        ...event.target.files,
      ]);
    } catch (e) {
      filtroSizeRes = filterImageSize(10000000, [...imagenes, ...event.target.files]);
    }
    setLoadingImages(false);
    let files = filtroSizeRes.filteredImages;

    if (filtroSizeRes.deleted) {
      setAdvertenciaSize(true);
    }

    setImagenes(files);
  };

  const deleteImage = (fotoIdentifier, isSavedImage = false) => {
    if (isSavedImage) {
      const filteredSavedImages = savedImages.filter((fotos) => fotos.id !== fotoIdentifier);
      setImagesToDelete([...imagesToDelete, fotoIdentifier]);
      setSavedImages(filteredSavedImages);
      if (
        fotoIdentifier.toString() === mainImage &&
        (filteredSavedImages.length || imagenes.length)
      ) {
        if (imagenes.length) {
          setMainImage(imagenes[0].name);
        } else {
          setMainImage(filteredSavedImages[0].id.toString());
        }
      }
      if (!filteredSavedImages.length && !imagenes.length) {
        setMainImage('');
      }
      return;
    }
    const newFiles = imagenes.filter((fotos) => fotos.name !== fotoIdentifier);
    setImagenes(newFiles);
    if (fotoIdentifier === mainImage && (newFiles.length || savedImages.length)) {
      if (savedImages.length) {
        setMainImage(savedImages[0].id.toString());
      } else {
        setMainImage(newFiles[0].name);
      }
    }
    if (!savedImages.length && !newFiles.length) {
      setMainImage('');
    }
  };

  const handleSubmitImages = async () => {
    if (!totalImages || totalImages < 3) {
      setError(true);
      setErrorMsg(t('afiliadosSteps.images.error6'));

      setTimeout(() => {
        setError(false);
      }, 5000);
      return;
    }
    setLoading(true);

    const mainFile = imagenes.filter((file) => file.name === mainImage);
    let imagenesSinMain = imagenes.filter((file) => file.name !== mainImage);
    const base64Encodedimages = await Promise.all(
      imagenesSinMain.map((imagen) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(imagen);
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      })
    );

    let objMainImage;
    if (mainFile.length) {
      const base64EncodedMainImage = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(mainFile[0]);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
      objMainImage = { saved: false, id: null, base64: base64EncodedMainImage };
    } else {
      objMainImage = { saved: true, id: parseInt(mainImage), base64: null };
    }
    if (isAdminPage) {
      submitStepAdmin(4, {
        imagenes: base64Encodedimages,
        idAliado: null,
        isEdition: false,
        imagesToDelete,
        totalSavedImages: savedImages.length,
        mainImage: objMainImage,
      });
      return;
    }
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/images`,
        {
          imagenes: base64Encodedimages,
          idAliado,
          isEdition,
          imagesToDelete,
          totalSavedImages: savedImages.length,
          mainImage: objMainImage,
        },
        userHeaders()
      )
      .then(() => {
        setLoading(false);
        setStep(+1);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data && err.response.data.msg) {
          setErrorMsg(
            err.response.data.code
              ? t(`afiliadosSteps.images.http.${err.response.data.code}`)
              : err.response.data.msg
          );
        }
        setError(true);
      });
  };

  const handleSubmitDEV = async () => {
    if (isAdminPage) {
      submitStepAdmin(4, {
        imagenes: [],
        idAliado,
        isEdition,
        imagesToDelete,
        totalSavedImages: 0,
        mainImage: { saved: false, id: null, base64: '' },
      });
      return;
    }
    setLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }user/images`,
        {
          imagenes: [],
          idAliado,
          isEdition,
          imagesToDelete,
          totalSavedImages: 0,
          mainImage: { saved: false, id: null, base64: '' },
        },
        userHeaders()
      )
      .then(() => {
        setLoading(false);
        setStep(+1);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data && err.response.data.msg) {
          setErrorMsg(err.response.data.msg);
        }
        setError(true);
      });
  };

  return (
    <div
      style={
        isEdition && mobile
          ? null
          : {
              minHeight: '58vh',
            }
      }
    >
      {loading ? (
        <div
          style={{
            height: '400px',
            position: 'relative',
            width: '100%',
          }}
        >
          <Loading helperText={t('afiliadosSteps.images.loading')} />
        </div>
      ) : (
        <div style={{ minWidth: '320px' }}>
          <p
            className={isEdition ? 'url-txt-instruc-ed-neg' : 'url-txt-instruc'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (collapse) setIsSelected(2);
              setCollapse(!collapse);
            }}
          >
            {isCollapsible && (
              <span>
                {!collapse ? (
                  <IoIosArrowDown style={{ marginRight: '15px', marginBottom: '5px' }} />
                ) : (
                  <IoIosArrowForward style={{ marginRight: '15px', marginBottom: '5px' }} />
                )}
              </span>
            )}
            {t('afiliadosSteps.images.title')}
          </p>
          {(!collapse || !isCollapsible) && (
            <>
              <p className="url-txt-instruc2" style={{ marginBottom: '3rem' }}>
                {t('afiliadosSteps.images.description')}
              </p>
              {premium ? (
                premiumAndMoreImages ? (
                  <Grid
                    item
                    className="afil-txt-flx"
                    style={{ marginBottom: '1rem', padding: '0 1rem' }}
                  >
                    <p className="afil-img-txt2">
                      <span
                        style={{
                          fontSize: '1.5rem',
                          color: '#8c50ff',
                        }}
                      >
                        ⚠
                      </span>{' '}
                      {t('afiliadosSteps.images.warning1')}{' '}
                      <b>{t('afiliadosSteps.images.warning2')}</b>{' '}
                      {t('afiliadosSteps.images.warning3')}
                    </p>
                  </Grid>
                ) : null
              ) : null}
              {error ? <ErrorMsg setError={setError} errorMsg={errorMsg} /> : null}

              {advertenciaFiles ? (
                <ErrorMsg
                  setError={setAdvertenciaFiles}
                  errorMsg={`${t('afiliadosSteps.images.error1')} ${infoPlan.cantidadDeFotos} ${t(
                    'afiliadosSteps.images.error2'
                  )}`}
                />
              ) : null}
              {advertenciaSize ? (
                <ErrorMsg
                  setError={setAdvertenciaSize}
                  errorMsg={t('afiliadosSteps.images.error3')}
                />
              ) : null}
              {imagenes.length ||
              savedImages.length ||
              (process.env.REACT_APP_ENV === 'development' && !isEdition) ? (
                <Grid item className="afil-btns-imgs-flx">
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file-add"
                    multiple
                    type="file"
                    name="imagenesAdd"
                    disabled={isImageLimitPlanExceeded()}
                    onChange={handleMoreImages}
                  />
                  <label
                    htmlFor="contained-button-file-add"
                    className={
                      isImageLimitPlanExceeded()
                        ? 'afil-btn-upload-more-inactive'
                        : 'afil-btn-upload-more'
                    }
                    style={
                      loadingImages
                        ? {
                            pointerEvents: 'none',
                            background: '#a7a7a7',
                            opacity: 0.8,
                          }
                        : null
                    }
                  >
                    <div component="span">
                      {isImageLimitPlanExceeded() ? (
                        t('afiliadosSteps.images.warning6').toUpperCase()
                      ) : (
                        <>
                          {t('afiliadosSteps.images.addMore')} <AddCircleOutlineIcon />
                        </>
                      )}
                    </div>
                  </label>
                  {isEdition ? null : (
                    <div
                      style={loadingImages ? { pointerEvents: 'none', opacity: 0.7 } : null}
                      className="afil-btn-finalizar"
                      onClick={() => {
                        if (process.env.REACT_APP_ENV === 'development' && !imagenes.length) {
                          handleSubmitDEV();
                        } else {
                          handleSubmitImages();
                        }
                      }}
                    >
                      {t('shared.continue')} <ArrowRightIcon />
                    </div>
                  )}
                </Grid>
              ) : (
                <Grid item className="afil-txt-flx">
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="imagenes"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="contained-button-file"
                    className="afil-btn-upload"
                    style={
                      loadingImages
                        ? {
                            pointerEvents: 'none',
                            background: '#a7a7a7',
                            opacity: 0.8,
                          }
                        : null
                    }
                  >
                    <div component="span">
                      {t('afiliadosSteps.images.select')} <PublishIcon />
                    </div>
                  </label>
                </Grid>
              )}
              <Grid item className="afil-img-preview-wrap">
                {loadingImages ? (
                  <div style={{ marginBottom: '2rem' }}>
                    <p
                      style={{
                        color: '#0D3B66',
                        opacity: 0.4,
                        fontSize: '0.8rem',
                        textAlign: 'center',
                      }}
                    >
                      {t('afiliadosSteps.images.optimizing')}
                    </p>
                    <div style={{ position: 'relative', width: '100%' }}>
                      <DotLoading />
                    </div>
                  </div>
                ) : imagenes.length || currentImages.length ? (
                  <>
                    {imagenes.length > 0 &&
                      imagenes.map((file) => (
                        <div
                          style={{
                            position: 'relative',
                          }}
                          key={Math.random()}
                        >
                          <img
                            src={URL.createObjectURL(file)}
                            className={`afil-img-preview ${file.name === mainImage && 'main'}`}
                            alt={'Mi negocio'}
                            onClick={() => setMainImage(file.name)}
                          />
                          <div
                            className="afil-img-preview-delete"
                            onClick={() => {
                              deleteImage(file.name);
                            }}
                          >
                            <ClearIcon style={{ fontSize: '0.8rem' }} />
                          </div>
                        </div>
                      ))}
                    {savedImages.length > 0 &&
                      savedImages.map((img) => (
                        <div
                          style={{
                            position: 'relative',
                          }}
                          key={img.id}
                        >
                          <img
                            src={img.url}
                            className={`afil-img-preview ${
                              img.id.toString() === mainImage && 'main'
                            }`}
                            alt={'Mi negocio'}
                            onClick={() => setMainImage(img.id.toString())}
                          />
                          <div
                            className="afil-img-preview-delete"
                            onClick={() => {
                              deleteImage(img.id, true);
                            }}
                          >
                            <ClearIcon style={{ fontSize: '0.8rem' }} />
                          </div>
                        </div>
                      ))}
                  </>
                ) : null}
              </Grid>
              {isEdition && (
                <div
                  style={
                    mobile
                      ? {
                          width: '100%',
                          textAlign: 'center',
                          marginTop: '2rem',
                        }
                      : { width: '100%', textAlign: 'right', marginTop: '2rem' }
                  }
                >
                  <Button
                    className={buttonTypes.cancelButton}
                    style={mobile ? { width: '100%' } : { width: '30%' }}
                    onClick={() => (isMainPage ? setStep(-1) : navigate(t('routes.myBusiness')))}
                    disabled={loadingImages}
                  >
                    {isMainPage ? t('shared.back') : t('shared.cancel')}
                  </Button>
                  <Button
                    className={buttonTypes.saveButton}
                    style={
                      mobile
                        ? { width: '100%', marginTop: '1.5rem' }
                        : { width: '30%', marginLeft: '1.5rem' }
                    }
                    onClick={handleSubmitImages}
                    disabled={loadingImages}
                  >
                    {isMainPage ? t('shared.saveAndContinue') : t('shared.save')}
                  </Button>
                </div>
              )}

              {!isEdition && (
                <div
                  style={
                    mobile
                      ? {
                          width: '100%',
                          textAlign: 'center',
                          marginTop: '2rem',
                        }
                      : { width: '100%', textAlign: 'right', marginTop: '2rem' }
                  }
                >
                  <Button
                    className={buttonTypes.cancelButton}
                    style={mobile ? { width: '100%' } : { width: '30%' }}
                    onClick={() => {
                      if (isAdminPage) {
                        backAdminPage();
                      } else {
                        setStep(-1);
                      }
                    }}
                    disabled={loadingImages}
                  >
                    {t('shared.back')}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

PasoImagenes.propTypes = {
  setStep: PropTypes.func.isRequired,
  idAliado: PropTypes.number.isRequired,
  setIsSelected: PropTypes.func,
  isEdition: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  isMainPage: PropTypes.bool,
  infoPlan: PropTypes.shape({ cantidadDeFotos: PropTypes.number }),
  premium: PropTypes.bool,
  isSelected: PropTypes.bool,
  initialMain: PropTypes.string,
  currentImages: PropTypes.arrayOf(
    PropTypes.shape({
      aliado: PropTypes.number,
      id: PropTypes.number,
      imagen: PropTypes.number,
      isActive: PropTypes.number,
      main: PropTypes.number,
      url: PropTypes.string,
    })
  ),
};

PasoImagenes.defaultProps = {
  setIsSelected: () => null,
  isEdition: false,
  isCollapsible: false,
  isMainPage: false,
  infoPlan: { cantidadDeFotos: 3 },
  premium: false,
  isSelected: false,
  currentImages: [],
  initialMain: '',
};

