import './Navbar.css';

import React from 'react';
import { IconButton, Tooltip, useMediaQuery } from '@material-ui/core';
import { useCountry } from '../../../componentes/Hooks/CountryContext';
import LanguageIcon from '@mui/icons-material/Language';

function CountrySelector({ openModalHandler }) {
  const mobile = useMediaQuery('(max-width:960px)');
  const { countryObject, getCountryNameAndLang } = useCountry();

  return mobile ? (
    <Tooltip title="Location">
      <IconButton onClick={openModalHandler} style={{ marginRight: '8px' }}>
        <LanguageIcon style={{ color: '#3f3f3f', fontSize: '20px' }} />
      </IconButton>
    </Tooltip>
  ) : (
    <div
      onClick={() => {
        openModalHandler();
      }}
      className="nav-location-btn"
    >
      <LanguageIcon style={{ fontSize: '20px', margin: '-3px 2px 0 0' }} />{' '}
      {getCountryNameAndLang(countryObject)}
    </div>
  );
}

export default CountrySelector;

