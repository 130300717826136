import React, { useState } from 'react';
import { useField } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import useDebounce from '../../../Utils/hooks/useDebounce';
import useNonInitialEffect from '../../../Utils/hooks/useNonInitialEffect';
import './MyGoogleDirection.css';

export default function MyGoogleDirection({
  placeholder,
  name,
  label = null,
  rows = 1,
  disabled = false,
  style,
  googleAutocomplete,
}) {
  const sessionToken = new window.google.maps.places.AutocompleteSessionToken();
  const autoCompleteService = new window.google.maps.places.AutocompleteService();

  const [field, meta, helpers] = useField(name);
  const [directions, setDirections] = useState([]);
  const [selected, setSelected] = useState(false);
  const [openPlaces, setOpenPlaces] = useState(false);
  const errorText = meta.error && meta.touched ? meta.error : '';

  const debouncedValue = useDebounce(field.value, 800);

  useNonInitialEffect(() => {
    if (!!debouncedValue && !!googleAutocomplete && !selected) {
      autoCompleteService.getPlacePredictions(
        {
          input: debouncedValue,
          sessionToken,
        },
        (value) => {
          setDirections(value);
          setOpenPlaces(true);
        }
      );
    }
  }, [debouncedValue]);

  const handlePlaceChange = (e) => {
    field.onChange(e);
    setSelected(false);
  };

  const handlePlaceSelection = (option) => {
    helpers.setValue(option.description);

    setTimeout(() => {
      setSelected(true);
      setOpenPlaces(false);
    }, 200);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setOpenPlaces(false);
    }, 100);
  };
  const estilosForm = {
    ...style,
  };

  return (
    <FormControl style={estilosForm} className="widthForInputs">
      {label ? (
        <Typography variant="subtitle1" style={{ fontSize: '18px' }}>
          {label}
        </Typography>
      ) : null}
      <TextField
        id={field.name}
        placeholder={placeholder}
        value={field.value}
        onChange={(e) => handlePlaceChange(e)}
        helperText={errorText}
        error={!!errorText}
        variant="outlined"
        multiline
        onBlur={handleBlur}
        disabled={disabled}
      />
      {!selected && openPlaces && directions && (
        <div className="google-dropdown-div">
          {directions.slice(0, 5).map((option, index) => (
            <div
              key={index}
              className="google-dropdown-wrapper"
              onClick={() => {
                handlePlaceSelection(option);
              }}
            >
              <div className="google-dropdown-icon-wrapper">
                <LocationOnIcon className="google-dropdown-icon" />
              </div>
              <p className="google-dropdown-option">{option.description}</p>
            </div>
          ))}
        </div>
      )}
    </FormControl>
  );
}

MyGoogleDirection.defaultProps = {
  disabled: false,
  style: {},
  rows: 1,
  label: null,
};
