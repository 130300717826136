import './BuscadorPlanodi.css';

import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import useDidMountEffect from '../../../componentes/Hooks/useDidMountEffect';
import { useTranslation } from 'react-i18next';
import PlanodiIcons from '../../../Utils/PlanodiIcons';

function BuscadorPlanodi({ locations, categorias, loading }) {
  const { t } = useTranslation('global');
  const mobile = useMediaQuery('(max-width:960px)');
  const [showLocationSelect, setShowLocationSelect] = useState(false);
  const [showQueBuscasSelect, setShowQueBuscasSelect] = useState(false);
  const [activeEstado, setActiveEstado] = useState(-1);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [inputDonde, setInputDonde] = useState('');
  const [inputQueBuscas, setInputQueBuscas] = useState('');
  const [activeCategoria, setActiveCategoria] = useState(-1);

  const wrapperRefDonde = useRef(null);
  const dondeRef = useRef(null);
  const wrapperRefQue = useRef(null);
  const queRef = useRef(null);
  const input2Ref = useRef(null);

  useDidMountEffect(() => {
    setFilteredLocations(locations);
  }, [locations]);

  const handleChangeDonde = (value) => {
    setInputDonde(value);
    if (activeEstado !== -1) {
      setActiveEstado(-1);
    }
    const busquedaSinAcentosMinusculas = value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    const filtroRes = locations.filter((item) => {
      const estadoSinAcentosMinusculas = item.estado
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      return estadoSinAcentosMinusculas.indexOf(busquedaSinAcentosMinusculas) === 0;
    });
    setFilteredLocations(filtroRes);
  };

  const handleChangeQueBuscas = (value) => {
    setInputQueBuscas(value);
    setShowQueBuscasSelect(false);
    setActiveCategoria(-1);
    if (value === '') {
      setShowQueBuscasSelect(true);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRefDonde.current &&
        !wrapperRefDonde.current.contains(event.target) &&
        dondeRef.current &&
        !dondeRef.current.contains(event.target)
      ) {
        setShowLocationSelect(false);
      }

      if (
        wrapperRefQue.current &&
        !wrapperRefQue.current.contains(event.target) &&
        queRef.current &&
        !queRef.current.contains(event.target)
      ) {
        setShowQueBuscasSelect(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRefDonde, dondeRef, wrapperRefQue, queRef]);

  const createQueryString = () => {
    const paramObj = {
      category: `${activeCategoria}`,
      location: `${activeEstado}`,
      free: `${inputQueBuscas}`,
    };

    for (const property in paramObj) {
      if (paramObj[property] === '-1') {
        delete paramObj[property];
      }
    }
    categorias.forEach((categoria) => {
      if (t(categoria.key) === paramObj.free) {
        delete paramObj.free;
      }
    });
    return new URLSearchParams(paramObj).toString();
  };

  const getHref = () => {
    if (activeCategoria === -1 && activeEstado == -1 && inputQueBuscas.length === 0) {
      return `${window.location.origin}${t('routes.explore')}`;
    } else {
      return `${window.location.origin}${t('routes.explore')}?${createQueryString()}`;
    }
  };

  // Función que maneja el evento de presionar una tecla
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Si se presiona Enter, enfocar el segundo input
      input2Ref.current.focus();
      setShowLocationSelect(true);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {showQueBuscasSelect && !loading && (
        <div className="buscador-planodi-box-quebuscas-wrp" ref={wrapperRefQue}>
          <div style={{ width: '100%' }} className="buscador-planodi-box-quebuscas-div">
            {categorias.map((categoria, idx) => (
              <div
                key={categoria.id}
                className="buscador-planodi-box-loc-div-2"
                onClick={() => {
                  if (categoria.id === activeCategoria) {
                    setActiveCategoria(-1);
                    setInputQueBuscas('');
                  } else {
                    setActiveCategoria(categoria.id);
                    setInputQueBuscas(t(categoria.key));
                    setShowQueBuscasSelect(false);
                  }
                }}
                style={{ width: '100%' }}
              >
                <p
                  className={`${
                    activeCategoria === categoria.id ? 'active' : null
                  } buscador-planodi-box-quebuscas-div-p`}
                >
                  <span style={{ marginRight: '5px' }}>
                    <PlanodiIcons name={categoria.icon} />
                  </span>{' '}
                  {t(categoria.key)}
                </p>
              </div>
            ))}
          </div>
          <div className="buscador-planodi-horizontal-overflow-blur-white" />
        </div>
      )}
      {showLocationSelect && !loading && (
        <div className="buscador-planodi-box-location-wrp" ref={wrapperRefDonde}>
          <div style={{ width: '100%' }} className="buscador-planodi-box-loc-div" id="containerDiv">
            {filteredLocations.map((location) => (
              <div
                key={location.id}
                className="buscador-planodi-box-loc-div-2"
                onClick={() => {
                  if (activeEstado === location.key) {
                    setActiveEstado(-1);
                    setInputDonde('');
                  } else {
                    setActiveEstado(location.key);
                    setInputDonde(location.name);
                    setShowLocationSelect(false);
                  }
                }}
                style={mobile ? { width: '100%' } : { width: '50%' }}
              >
                <p
                  className={`${
                    activeEstado === location.key ? 'active' : null
                  } buscador-planodi-box-quebuscas-div-p`}
                >
                  {location.name}
                </p>
              </div>
            ))}
          </div>
          <div className="buscador-planodi-horizontal-overflow-blur-white" />
        </div>
      )}
      <div className="buscador-planodi-wrp">
        <div
          style={
            mobile ? { position: 'relative', flexGrow: 1 } : { position: 'relative', flexGrow: 5 }
          }
          ref={queRef}
        >
          <input
            type="text"
            name="queBuscas"
            onKeyDown={handleKeyDown}
            autoComplete="off"
            className="buscador-planodi-input-1"
            placeholder=" "
            onFocus={() => setShowQueBuscasSelect(true)}
            value={inputQueBuscas}
            onChange={(e) => handleChangeQueBuscas(e.target.value)}
          />
          <label htmlFor="queBuscas" className="buscador-planodi-label">
            <span className="buscador-planodi-content">{t('buscadorInicio.what')}</span>
          </label>
          <button
            type="button"
            className="buscador-planodi-icon-div"
            style={{
              fontSize: '1rem',
              cursor: 'pointer',
              border: 'none',
              background: 'none',
              padding: mobile ? '10px' : 0,
            }}
            onClick={(e) => {
              e.preventDefault();
              setShowQueBuscasSelect(true);
            }}
          >
            <PlanodiIcons
              name="arrow-down"
              style={{
                fontSize: '1rem',
              }}
            />
          </button>
        </div>
        <div style={{ position: 'relative' }} ref={dondeRef}>
          <input
            type="text"
            name="donde"
            ref={input2Ref}
            autoComplete="off"
            className="buscador-planodi-input-2"
            placeholder=" "
            onFocus={() => setShowLocationSelect(true)}
            value={inputDonde}
            onChange={(e) => handleChangeDonde(e.target.value)}
          />
          <label htmlFor="donde" className="buscador-planodi-label">
            <span className="buscador-planodi-content">{t('buscadorInicio.where')}</span>
          </label>

          <button
            type="button"
            className="buscador-planodi-icon-div"
            style={{
              fontSize: '1rem',
              cursor: 'pointer',
              border: 'none',
              background: 'none',
              padding: mobile ? '10px' : 0,
            }}
            onClick={(e) => {
              e.preventDefault();
              setShowLocationSelect(true);
            }}
          >
            <PlanodiIcons
              name="arrow-down"
              style={{
                fontSize: '1rem',
              }}
            />
          </button>
        </div>
        {mobile ? (
          <Button className="buscador-planodi-boton-go-mobile" href={getHref()}>
            {t('buscadorInicio.search')}
          </Button>
        ) : (
          <a className="buscador-planodi-boton-go" href={getHref()}>
            <PlanodiIcons name="search" style={{ fontSize: '1.5rem' }} />
          </a>
        )}
      </div>
    </div>
  );
}

export default BuscadorPlanodi;

