import React, { useEffect, useState } from 'react';
import './PrivacyOptions.css';
import Navbar from '../../HomePage/Navbar/Navbar';
import Footer from '../../HomePage/Footer/Footer';
import { getUrl } from '../../../Utils/url';
import { useTranslation } from 'react-i18next';
import { useCountry } from '../../../componentes/Hooks/CountryContext';
import { useNavigate } from 'react-router-dom';

const PrivacyOptions = () => {
  document.title = 'Planodi | Your Privacy Choices';
  const { t } = useTranslation('global');
  const { countryObject } = useCountry();
  const navigate = useNavigate();

  useEffect(() => {
    if (countryObject?.countryId !== '2') {
      navigate(`${t('routes.documents')}?id=privacyPolicy`);
    }
  }, [countryObject?.countryId]);

  return (
    <div>
      <Navbar />
      <div className="privacy-options-wrp">
        <div className="privacy-options-main-title-div">
          <p>{t('privacyOptions.title')}</p>
        </div>
        <div className="privacy-options-main-content-div">
          <p>{t('privacyOptions.content')}</p>
          <p style={{ marginTop: '1rem' }}>
            {t('privacyOptions.more')}
            <span>
              <a style={{ color: 'black' }} href={getUrl(t, 'documents?id=privacyPolicy')}>
                {t('privacyOptions.privacyPolicy')}.
              </a>
            </span>
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyOptions;

