/* eslint-disable no-unused-vars */
import './CardGeneral.css';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GoVerified } from 'react-icons/go/index';
import { useTranslation } from 'react-i18next';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa/index';
import { BsDot } from 'react-icons/bs/index';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  mostrarPrimerosDosObjetosDeArray,
  reorganizarImagenPrincipal,
} from '../../Utils/utilFunctions';

/**
 * Componente de slider con cards
 * @param {{nombre: string, precio: string, descripcion: string, pathUrl: string}[]}  info
 * */

export default function CardGeneral({ info }) {
  const mobile = useMediaQuery('(max-width:960px)');
  const { t } = useTranslation('global');
  const [imagenActual, setImagenActual] = useState(0);
  const { name, insignia, pathUrl, locations, categories, images, logoImgUrl, price, descuento } =
    info;
  const imagenesAcomodadas = reorganizarImagenPrincipal(images);
  const nextImage = (direccion) => {
    if (direccion > 0) {
      imagenActual === imagenesAcomodadas.length - 1
        ? setImagenActual(imagenActual)
        : setImagenActual(imagenActual + 1);
    }
    if (direccion < 0) {
      imagenActual === 0
        ? setImagenActual(imagenesAcomodadas.length - 1)
        : setImagenActual(imagenActual - 1);
    }
  };

  const categorySelection = (array) => {
    if (!array || !array.length) {
      return '';
    }
    let result = t(`allyCard.${array[0].category}`);
    const restantes = array.length - 1;

    if (restantes > 0) {
      result += ` (${restantes} ${
        restantes === 1 ? t('allyCard.oneMoreCategories') : t('allyCard.moreCategories')
      })`;
    }

    return result;
  };

  return (
    <a
      href={`${t('routes.profile')}/${pathUrl}`}
      target={mobile ? null : '_blank'}
      rel={mobile ? null : 'noopener noreferrer'}
      className="cardGen-links"
    >
      <div className="card-wrapper">
        <div
          className="card-media"
          style={{
            backgroundImage: `url('${imagenesAcomodadas[imagenActual]?.url}')`,
          }}
        >
          {imagenActual === imagenesAcomodadas.length - 1 ? (
            <div className="ver-mas-card-general">
              <p style={{ margin: '0' }}>{t('shared.seeMore').toUpperCase()}</p>
            </div>
          ) : null}
          {logoImgUrl ? (
            <div style={{ background: `url(${logoImgUrl}) white` }} className="card-logo" />
          ) : null}
          <FaAngleLeft
            className="imgs-card-gen-izq"
            onClick={(e) => {
              e.preventDefault();
              nextImage(-1);
            }}
            style={mobile && { opacity: 1 }}
          />
          <FaAngleRight
            className="imgs-card-gen-der"
            onClick={(e) => {
              e.preventDefault();
              nextImage(1);
            }}
            style={mobile && { opacity: 1 }}
          />
          {Boolean(descuento) && (
            <div className="card-discount">
              <p>-{descuento}%</p>
            </div>
          )}
        </div>
        <div className="card-info">
          <p className="card-titulo">
            {name.length >= 45 ? `${name.substring(0, 45)}...` : name}
            {Boolean(insignia) ? (
              <span
                style={{
                  color: `var(--planodi-${insignia})`,
                  marginLeft: '0.3rem',
                }}
              >
                <GoVerified
                  style={{
                    marginBottom: '0.2rem',
                  }}
                />
              </span>
            ) : null}
          </p>
          <p className="card-descripcion">
            {price ? price : '$$$'} <BsDot /> {mostrarPrimerosDosObjetosDeArray(locations, 'name')}
            <BsDot /> {categorySelection(categories)}
          </p>
          {/*<p className="card-exp-content-2">{description}</p>*/}
        </div>
      </div>
    </a>
  );
}

CardGeneral.propTypes = {
  info: PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string,
    precio: PropTypes.string,
    descripcion: PropTypes.string,
    pathUrl: PropTypes.string,
    alcohol: PropTypes.number,
    capacidad: PropTypes.string,
    horarioDia: PropTypes.number,
    horarioNoche: PropTypes.number,
    estacionamiento: PropTypes.number,
  }),
};

