import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

function Toggle({ children, handleOnSelect, customClassName, text, isSelected }) {
  const [collapse, setCollapse] = useState(!isSelected);
  return (
    <>
      <p
        className={customClassName ? customClassName : 'url-txt-instruc-ed-neg'}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (collapse) handleOnSelect();
          setCollapse(!collapse);
        }}
      >
        <span>
          {!collapse ? (
            <IoIosArrowDown style={{ marginRight: '15px', marginBottom: '5px' }} />
          ) : (
            <IoIosArrowForward style={{ marginRight: '15px', marginBottom: '5px' }} />
          )}
        </span>
        {text}
      </p>
      {!collapse && children}
    </>
  );
}

export default Toggle;

