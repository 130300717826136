import React, { useEffect, useRef, useState } from 'react';
import { IoLocationOutline } from 'react-icons/io5';
import { BsDot } from 'react-icons/bs';
import { GoVerified } from 'react-icons/go/index';
import { IoAppsOutline } from 'react-icons/io5';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { IoIosArrowBack } from 'react-icons/io';
import { IoMdPeople } from 'react-icons/io';
import { AiOutlineCar } from 'react-icons/ai';
import { BiDrink, BiSun } from 'react-icons/bi';
import { IoMoonOutline } from 'react-icons/io5';
import { TiWeatherPartlySunny } from 'react-icons/ti';
import { useSelector } from 'react-redux';
import { FaSwimmingPool } from 'react-icons/fa';
import { IoStar } from 'react-icons/io5/index';

import Reviews from './Reviews/Reviews';
import Navbar, { useAuthModals } from '../../HomePage/Navbar/Navbar';
import ContactBox from './ContactBox/ContactBox';
import CarouselV2 from '../../../componentes/CarouselV2/CarouselV2';
import './Template2.css';
import Footer from '../../HomePage/Footer/Footer';
import GalleryTemplate2 from './GalleryTemplate2/GalleryTemplate2';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { userHeaders } from '../../../Utils/headerSetter';
import DotLoading from '../../../componentes/DotLoading/DotLoading';
import ModalRegistro from '../../HomePage/Navbar/ModalRegistro/ModalRegistro';
import ModalLogin from '../../HomePage/Navbar/ModalLogin/ModalLogin';
import { toast } from 'react-toastify';
import { AiFillYoutube } from 'react-icons/ai/index';
import { TIPOS_DE_MULTIMEDIA } from '../../../Utils/variablesGlobalesYStatics';
import InfoIcon from '@mui/icons-material/Info';
import GoogleMap from '../../../componentes/GoogleMap/GoogleMap';
import { useTranslation } from 'react-i18next';
import { useCountry } from '../../../componentes/Hooks/CountryContext';
import { handleWhatsAppAliadoContact } from '../../../Utils/whatsAppContact';
import { tiempoTranscurridoEnPlanodi } from '../../../Utils/utilFunctions';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Paywall from '../../../componentes/Paywall';
import { Alert } from '@mui/material';
import PlanodiIcons from '../../../Utils/PlanodiIcons';
import RichText from '../../../componentes/RichText/RichText';
import RichTextVisualizer from '../../../componentes/RichText/RichTextVisualizer';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

export default function Template2() {
  const stateUser = useSelector((state) => state.user);
  const { countryObject } = useCountry();
  const { t, i18n } = useTranslation('global');
  const { openRegistroModal, openLoginModal, setOpenRegistroModal, setOpenLoginModal } =
    useAuthModals();
  const { pathName } = useParams();

  const [idAliado, setIdAliado] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showGallery, setShowGallery] = useState(false);
  const [imagenActualGal, setImagenActualGal] = useState(0);
  const [videoActualGal, setVideoActualGal] = useState(0);
  const [isTerraza, setIsTerraza] = useState(true);
  const [bigGallery, setBigGallery] = useState(false);
  const [fixBox, setFixBox] = useState(false);
  const [activePackage, setActivePackage] = useState(1);
  const [tiempoEnPlanodi, setTiempoEnPlanodi] = useState(null);
  const [widthBox, setWidthBox] = useState(0);
  const [heightBox, setHeightBox] = useState(0);
  const [infoAliado, setInfoAliado] = useState(null);
  const [imagesGallery, setImagesGallery] = useState([]);
  const [videosGallery, setVideosGallery] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [avgScore, setAvgScore] = useState(5);
  const [reviewsPerPage, setReviewsPerPage] = useState(4);
  const [reloadInfo, setReloadInfo] = useState(0);
  const [visibleHeight, setVisibleHeight] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [activeScrollImage, setActiveScrollImage] = useState(1);
  const [isPendingValidation, setIsPendingValidation] = useState(false);
  const [initialMediaGallery, setInitialMediaGallery] = useState(TIPOS_DE_MULTIMEDIA.imagenes);
  const [googleMaps, setGoogleMaps] = useState(false);
  const [plan, setPlan] = useState({ tier: 'free', status: 'active' });

  const ref = useRef(null);
  const refFLexImages = useRef(null);
  const refContent = useRef(null);
  const mobile = useMediaQuery('(max-width:960px)');
  const midSize = useMediaQuery('(max-width:580px)');
  const navigate = useNavigate();

  useEffect(() => {
    if (isTerraza) {
      axios
        .get(
          `${
            process.env.REACT_APP_ENV === 'development'
              ? process.env.REACT_APP_API_LOCAL
              : process.env.REACT_APP_API_PROD
          }aliado/googleConfig`,
          {}
        )
        .then((res) => {
          if (res.data.google_maps) {
            setGoogleMaps(true);
          }
        });
    }
  }, []);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_ENV === 'development'
            ? process.env.REACT_APP_API_LOCAL
            : process.env.REACT_APP_API_PROD
        }aliado/aliadoProfile?username=${pathName}`,
        userHeaders(false)
      )
      .then((res) => {
        const {
          aliadoInfoGeneral,
          infoSecundaria,
          images,
          videos,
          packages,
          multimediaPackages,
          reviews,
          avarageScore,
          plan,
        } = res.data;
        setPlan(plan);
        setVideosGallery(videos);
        setLogoUrl(aliadoInfoGeneral?.logoUrl);
        setReviews(reviews);
        setAvgScore(avarageScore);
        delete infoSecundaria.id;
        let packagesWithImages = [];
        if (packages.length) {
          packagesWithImages = packages.map((item) => {
            let mainImage = '';
            let allImages = [];
            multimediaPackages.forEach((itemImg) => {
              if (itemImg.idPackage === item.id) {
                allImages.push(itemImg.url);
                if (itemImg.main) {
                  mainImage = itemImg.url;
                }
              }
            });
            return { ...item, mainImage, allImages };
          });
        }
        document.title = `Planodi | ${aliadoInfoGeneral.nombre}`;
        setInfoAliado({
          ...aliadoInfoGeneral,
          ...infoSecundaria,
          packages: packagesWithImages,
        });
        setTiempoEnPlanodi(tiempoTranscurridoEnPlanodi(aliadoInfoGeneral.fechaAgregado));
        setActivePackage(packages.length ? packagesWithImages[0].id : null);
        setIdAliado(aliadoInfoGeneral.id);
        const imagesGalUrl = images.map((item) => item.url);
        setIsTerraza(aliadoInfoGeneral.tipo === 3);
        setImagesGallery(imagesGalUrl);
        setBigGallery(imagesGalUrl.length >= 5);
        setVisibleHeight(window.innerHeight);
        setLoading(false);
      })
      .catch(() => {
        navigate(t('routes.explore'));
      });
  }, [reloadInfo, pathName]);

  useEffect(() => {
    if (infoAliado && stateUser) {
      const isPending =
        !infoAliado?.isApproved &&
        infoAliado?.usuario !== stateUser?.userInfo?.id &&
        !stateUser?.userInfo?.isAdmin;
      setIsPendingValidation(isPending);
      if (isPending) {
        toast.dismiss();
        toast.warn(t('toastCard.planodiValidation'), {
          position: 'bottom-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          progress: undefined,
          onClick: () => {
            navigate(t('routes.home'));
            toast.dismiss();
          },
        });
      }
    }
  }, [stateUser, infoAliado]);

  const clickImage = (image) => {
    setImagenActualGal(image);
    setInitialMediaGallery(TIPOS_DE_MULTIMEDIA.imagenes);
    setShowGallery(true);
  };

  const getListSize = () => {
    if (ref && ref.current && ref.current.clientWidth) {
      const newWidth = ref.current.clientWidth;
      setWidthBox(newWidth - 24.5);
    }
  };

  useEffect(() => {
    getListSize();
    if (refContent && refContent.current && refContent.current.clientHeight) {
      setHeightBox(refContent.current.clientHeight - 200);
    }
  }, [loading]);

  useEffect(() => {
    const fixNavbar = () => {
      if (window.scrollY >= 488 && window.scrollY <= heightBox) {
        setFixBox(true);
      } else {
        setFixBox(false);
      }
    };

    window.addEventListener('scroll', fixNavbar);

    return () => window.removeEventListener('scroll', fixNavbar);
  }, [heightBox]);

  useEffect(() => {
    window.addEventListener('resize', getListSize);
    return () => window.removeEventListener('resize', getListSize);
  }, []);

  const changeImage = (direccion) => {
    if (direccion > 0) {
      imagenActualGal === imagesGallery.length - 1
        ? setImagenActualGal(0)
        : setImagenActualGal(imagenActualGal + 1);
    }
    if (direccion < 0) {
      imagenActualGal === 0
        ? setImagenActualGal(imagesGallery.length - 1)
        : setImagenActualGal(imagenActualGal - 1);
    }
  };

  const changeVideo = (direccion) => {
    if (direccion > 0) {
      videoActualGal === videosGallery.length - 1
        ? setVideoActualGal(0)
        : setVideoActualGal(videoActualGal + 1);
    }
    if (direccion < 0) {
      videoActualGal === 0
        ? setVideoActualGal(videosGallery.length - 1)
        : setVideoActualGal(videoActualGal - 1);
    }
  };

  useEffect(() => {
    if (mobile) {
      setReviewsPerPage(2);
    } else {
      setReviewsPerPage(4);
    }
  }, [mobile]);

  const resizeVisibleHeight = () => {
    setVisibleHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeVisibleHeight);

    return () => window.removeEventListener('resize', resizeVisibleHeight);
  }, []);

  const handleScrollImage = () => {
    if (refFLexImages && refFLexImages.current) {
      setActiveScrollImage(
        Math.ceil(refFLexImages.current.scrollLeft / refFLexImages.current.clientWidth) + 1
      );
    }
  };
  return (
    <div className="temp2-wrapper-parent">
      <ModalRegistro
        handleClose={() => setOpenRegistroModal(false)}
        open={openRegistroModal}
        titleModal={'Regístrate antes'}
        openLogin={() => setOpenLoginModal(true)}
      />
      <ModalLogin
        handleClose={() => setOpenLoginModal(false)}
        open={openLoginModal}
        openRegister={() => setOpenRegistroModal(true)}
      />
      {loading ? (
        <DotLoading />
      ) : !showGallery ? (
        <>
          <div
            className="temp2-wrapper"
            style={mobile ? { height: `${visibleHeight - 80}px` } : null}
          >
            <div className="temp2-wrapper-div">
              {mobile && document.referrer !== '' && document.referrer !== window.location.href ? (
                <>
                  <div className="temp2-top-nav-mobile">
                    <div className="temp2-back-mobile-button">
                      <p
                        className="temp2-back-mobile-button-p"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <span>
                          <IoIosArrowBack style={{ fontSize: '1rem', marginBottom: '3px' }} />
                        </span>
                        {t('shared.back')}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: -100,
                      opacity: 0,
                      pointerEvents: 'none',
                    }}
                  >
                    <Navbar type="black" shadow />
                  </div>
                </>
              ) : (
                <Navbar type="black" shadow />
              )}
              {midSize && (
                <div style={{ position: 'relative', width: '100%' }}>
                  <div className="temp2-gall-mobile-counter-img">
                    <p>
                      {activeScrollImage} / {imagesGallery.length}
                    </p>
                  </div>

                  <div
                    className="temp2-gall-mobile-wrp"
                    ref={refFLexImages}
                    onScroll={handleScrollImage}
                  >
                    <div
                      className="temp2-gall-mobile-flex"
                      style={{ width: `${100 * imagesGallery.length}%` }}
                    >
                      {imagesGallery.map((item, idx) => (
                        <img
                          className="temp2-gall-mobile-img"
                          style={{ background: `url(${imagesGallery[idx]})` }}
                          onClick={() => !isPendingValidation && clickImage(idx)}
                          key={idx}
                          alt=""
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="temp2-content-wrp" ref={refContent}>
              <div className="temp2-title-wrp-flex">
                <div className="temp2-wrp-flex-logo-titulo">
                  {logoUrl ? (
                    <div className="temp2-logo-negocio" style={{ background: `url(${logoUrl})` }} />
                  ) : null}
                  <p className="temp2-title" style={logoUrl ? { marginLeft: '0.8rem' } : null}>
                    {infoAliado.nombre}
                    {!!infoAliado.verificado && (
                      <Paywall
                        plan={plan}
                        free={null}
                        basic={null}
                        premium={
                          <span
                            style={{
                              color: '#8c50ff',
                              marginLeft: '1rem',
                            }}
                          >
                            <GoVerified
                              style={{
                                fontSize: '1.6rem',
                              }}
                            />
                          </span>
                        }
                        pro={
                          <span
                            style={{
                              color: '#ffbc1d',
                              marginLeft: '1rem',
                            }}
                          >
                            <GoVerified
                              style={{
                                fontSize: '1.6rem',
                              }}
                            />
                          </span>
                        }
                      />
                    )}
                    {!infoAliado.isApproved && (
                      <span className="temp2-pending-title-span">
                        <Tooltip title={t('shared.pendingMsg')}>
                          <div className="temp2-pending-title-tooltip">
                            <InfoIcon style={{ fontSize: '15px' }} />
                            <p style={{ margin: '0', padding: '0px 0px 0px 5px' }}>
                              {t('shared.pending')}
                            </p>
                          </div>
                        </Tooltip>
                      </span>
                    )}
                  </p>
                </div>

                <p
                  className="temp2-subtitle"
                  style={{
                    margin: '10px 0 5px 0',
                  }}
                >
                  <span>
                    <IoLocationOutline style={{ margin: '0 3px 0.1rem 0' }} />
                  </span>
                  {infoAliado.selectedLocations.map((item) => `${item.name}`).join(' | ')}
                </p>

                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                    flexWrap: 'wrap',
                    margin: '10px 0 5px 0',
                    alignItems: 'center',
                  }}
                >
                  {infoAliado.categorias.slice(0, 3).map((item, idx) => (
                    <p className="temp2-subtitle">
                      {item[`name_${i18n.language}`]}

                      {infoAliado.categorias.slice(0, 3)[idx + 1] ? ',' : ''}
                    </p>
                  ))}
                  {infoAliado.categorias.length > 3 && (
                    <CustomTooltip
                      title={infoAliado.categorias
                        .slice(3)
                        .map((item) => item.name)
                        .join(', ')}
                      placement="bottom-start"
                      enterTouchDelay={0}
                    >
                      <p className="temp2-subtitle" style={{ fontWeight: 500, cursor: 'pointer' }}>
                        (+ {infoAliado.categorias.length - 3} {t('temp2.categories')})
                      </p>
                    </CustomTooltip>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                    flexWrap: 'wrap',
                    margin: '10px 0 5px 0',
                    alignItems: 'center',
                  }}
                >
                  <p className="temp2-subtitle">
                    {avgScore.toFixed(1)}
                    <IoStar
                      style={{
                        marginBottom: '3px',
                        color: '#8c50ff',
                        marginLeft: '3px',
                        fontSize: '0.8rem',
                      }}
                    />
                    <span className="temp2-subtitle-comments">({reviews.length})</span>
                  </p>

                  <p className="temp2-dot">
                    <BsDot />
                  </p>

                  <p className="temp2-subtitle">{infoAliado.precio ? infoAliado.precio : '$$$'}</p>
                  {tiempoEnPlanodi ? (
                    <>
                      {' '}
                      <p className="temp2-dot">
                        <BsDot />
                      </p>
                      <p className="temp2-subtitle">
                        {tiempoEnPlanodi[0]} {t(`temp2.${tiempoEnPlanodi[1]}`)}{' '}
                        {t('temp2.onPlanodi')}
                      </p>
                    </>
                  ) : null}
                </div>

                {!midSize && (
                  <div className="temp2-galeria">
                    <div
                      className={
                        bigGallery ? 'temp2-galeria-fotos-5img' : 'temp2-galeria-fotos-3img'
                      }
                    >
                      <div
                        style={{ background: `url(${imagesGallery[0]})` }}
                        className={`temp2-box temp2-box1${bigGallery ? '' : '-3img'}`}
                        onClick={() => !isPendingValidation && clickImage(0)}
                      />
                      <div
                        className={`temp2-box temp2-box2${bigGallery ? '' : '-3img'}`}
                        style={{ background: `url(${imagesGallery[1]})` }}
                        onClick={() => !isPendingValidation && clickImage(1)}
                      />
                      <div
                        className={`temp2-box temp2-box3${bigGallery ? '' : '-3img'}`}
                        style={{ background: `url(${imagesGallery[2]})` }}
                        onClick={() => !isPendingValidation && clickImage(2)}
                      />
                      {bigGallery ? (
                        <>
                          <div
                            className="temp2-box temp2-box4"
                            style={{
                              background: `url(${imagesGallery[3]})`,
                            }}
                            onClick={() => !isPendingValidation && clickImage(3)}
                          />
                          <div
                            className="temp2-box temp2-box5"
                            style={{
                              background: `url(${imagesGallery[4]})`,
                            }}
                            onClick={() => !isPendingValidation && clickImage(4)}
                          />
                        </>
                      ) : null}
                      <div
                        className="temp2-boton-galeria"
                        onClick={() => {
                          if (!isPendingValidation) {
                            setInitialMediaGallery(TIPOS_DE_MULTIMEDIA.imagenes);
                            setShowGallery(true);
                          }
                        }}
                      >
                        <p className="temp2-btn-texto">
                          <IoAppsOutline
                            style={{
                              marginRight: '5px',
                              marginBottom: '2px',
                              fontSize: '1.1rem',
                            }}
                          />
                          {t('temp2.fullGallery')}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="temp2-grid-content">
                  <div className="temp2-info-wrp" style={mobile ? { marginTop: '2rem' } : null}>
                    {Boolean(infoAliado.descuento) && (
                      <div className="temp2-general-inf-wrp" style={{ marginBottom: '12px' }}>
                        <Alert severity="success" icon={<PlanodiIcons name="discount" />}>
                          {t('shared.discount', { discount: infoAliado.descuento })}
                        </Alert>
                      </div>
                    )}
                    {infoAliado.packages.length ? (
                      <>
                        <CarouselV2
                          infoCards={infoAliado.packages}
                          activePackage={activePackage}
                          widthCard={280}
                          cardPackages
                          loading={false}
                          title={t('temp2.packages')}
                          // blurLastOne
                          pagiantionAtEnd
                          onClickPackage={(id) => {
                            if (!isPendingValidation) {
                              if (mobile) {
                                navigate(`${t('routes.packages')}/${id}`);
                              } else {
                                window.open(
                                  `${window.location.origin}${t('routes.packages')}/${id}`,
                                  '_blank'
                                );
                              }
                            }
                          }}
                        />
                        <hr style={{ marginTop: '2.5rem' }} />
                      </>
                    ) : null}
                    {isTerraza ? (
                      <>
                        <hr
                          style={{
                            marginTop: mobile ? '0rem' : '2.5rem',
                            marginBottom: '2.5rem',
                          }}
                        />
                        <div className="temp2-info-terrazas-icons-wrp">
                          <div style={{ display: 'flex', margin: '1rem 1rem' }}>
                            <IoMdPeople style={{ fontSize: '1.8rem' }} />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '0.6rem',
                              }}
                            >
                              <p
                                style={{
                                  margin: '0',
                                  fontWeight: 500,
                                  color: '#3b3b3b',
                                }}
                              >
                                {t('temp2.capacity')}
                              </p>
                              <p
                                style={{
                                  margin: '0',
                                  fontSize: '0.9rem',
                                  color: '#7a7a7a',
                                }}
                              >
                                {infoAliado.capacidad} {t('temp2.people')}
                              </p>
                            </div>
                          </div>
                          {infoAliado.estacionamiento ? (
                            <div
                              style={{
                                display: 'flex',
                                margin: '1rem 1rem',
                              }}
                            >
                              <AiOutlineCar style={{ fontSize: '1.8rem' }} />
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginLeft: '0.6rem',
                                }}
                              >
                                <p
                                  style={{
                                    margin: '0',
                                    fontWeight: 500,
                                    color: '#3b3b3b',
                                  }}
                                >
                                  {t('temp2.parking')}
                                </p>
                              </div>
                            </div>
                          ) : null}
                          <div style={{ display: 'flex', margin: '1rem 1rem' }}>
                            <BiDrink style={{ fontSize: '1.8rem' }} />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '0.6rem',
                              }}
                            >
                              <p
                                style={{
                                  margin: '0',
                                  fontWeight: 500,
                                  color: '#3b3b3b',
                                }}
                              >
                                {infoAliado.alcohol
                                  ? t('temp2.alcoholAllowed')
                                  : t('temp2.alcoholNotAllowed')}
                              </p>
                            </div>
                          </div>
                          {infoAliado.eventosDia || infoAliado.eventosNoche ? (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  margin: '1rem 1rem',
                                }}
                              >
                                {infoAliado.eventosDia && infoAliado.eventosNoche ? (
                                  <TiWeatherPartlySunny style={{ fontSize: '1.8rem' }} />
                                ) : infoAliado.eventosNoche ? (
                                  <IoMoonOutline style={{ fontSize: '1.8rem' }} />
                                ) : (
                                  <BiSun style={{ fontSize: '1.8rem' }} />
                                )}
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '0.6rem',
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: '0',
                                      fontWeight: 500,
                                      color: '#3b3b3b',
                                    }}
                                  >
                                    {infoAliado.eventosDia && infoAliado.eventosNoche
                                      ? t('temp2.dayAndNightEvents')
                                      : infoAliado.eventosNoche
                                      ? t('temp2.nightEvents')
                                      : t('temp2.dayEvents')}
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : null}
                          {infoAliado.alberca ? (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  margin: '1rem 1rem',
                                }}
                              >
                                <FaSwimmingPool style={{ fontSize: '1.8rem' }} />
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '0.6rem',
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: '0',
                                      fontWeight: 500,
                                      color: '#3b3b3b',
                                    }}
                                  >
                                    {t('temp2.swimmingPool')}
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                        <hr style={{ marginTop: '2.5rem' }} />
                      </>
                    ) : null}
                    <div className="temp2-general-inf-wrp">
                      {Boolean(infoAliado?.perfilContent) && (
                        <RichTextVisualizer content={infoAliado?.perfilContent || []} />
                      )}
                    </div>
                    {videosGallery.length ? (
                      <>
                        <hr
                          style={{
                            marginTop: '2.5rem',
                            marginBottom: '2.5rem',
                          }}
                        />
                        <div className="temp2-general-inf-wrp">
                          <p className="rich-text-editor-title1">Videos</p>
                          <div
                            className="temp2-video-section"
                            onClick={() => {
                              if (!isPendingValidation) {
                                setInitialMediaGallery(TIPOS_DE_MULTIMEDIA.videos);
                                setShowGallery(true);
                              }
                            }}
                          >
                            <div className="temp2-video-section-button">
                              <AiFillYoutube className="temp2-video-section-button-content temp2-video-section-button-icono" />
                              <p
                                className="temp2-video-section-button-content temp2-video-section-button-p"
                                style={{ marginTop: '3rem' }}
                              >
                                {t('temp2.clickVideo')}
                              </p>
                            </div>
                            <iframe
                              width="560"
                              height="315"
                              src={`https://www.youtube.com/embed/${videosGallery[0]}`}
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {isTerraza ? (
                      <>
                        <hr style={{ marginTop: '2.5rem' }} />
                        <div className="temp2-general-inf-wrp">
                          <p className="rich-text-editor-title1">{t('temp2.address')}</p>
                          <p className="rich-text-editor-text-size">{infoAliado.direccion}</p>
                          <GoogleMap
                            aliado={infoAliado.nombre}
                            address={infoAliado.direccion}
                            locale={i18n.language}
                            googleMaps={googleMaps}
                          />
                        </div>
                      </>
                    ) : null}
                    {Boolean(infoAliado?.perfilPreguntas) && (
                      <div className="temp2-general-inf-wrp">
                        <hr style={{ margin: '2.5rem 0' }} />
                        <p className="rich-text-editor-title1">{t('afiliadosSteps.faq.title')}</p>
                        <RichTextVisualizer content={infoAliado?.perfilPreguntas || []} />
                      </div>
                    )}
                  </div>

                  <div
                    className="temp2-selected-wrp"
                    ref={ref}
                    style={
                      window.scrollY >= heightBox
                        ? { alignItems: 'flex-end' }
                        : { alignItems: 'flex-start' }
                    }
                  >
                    <ContactBox
                      fixBox={fixBox}
                      width={widthBox}
                      handleWhatsAppContact={handleWhatsAppAliadoContact}
                      isPendingValidation={isPendingValidation}
                      paisIdAliado={infoAliado.paisId}
                      aliado={infoAliado.nombre}
                      email={infoAliado.email}
                      phoneNumber={infoAliado.telefono}
                      whatsAppNumber={infoAliado.whatsapp}
                      webSite={infoAliado?.webSite}
                      instagram={infoAliado.instagram}
                      facebook={infoAliado.facebook}
                      youtube={infoAliado.youtube}
                      country={countryObject.countryId}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Reviews
              isAliadoApproved={infoAliado.isApproved}
              idAliado={idAliado}
              stateUser={stateUser}
              reviews={reviews}
              avgScore={avgScore}
              reviewsPerPage={reviewsPerPage}
              setReloadInfo={setReloadInfo}
              t={t}
            />

            <Footer />
          </div>

          <div className="temp2-box-selected-mobile">
            <ContactBox
              handleWhatsAppContact={handleWhatsAppAliadoContact}
              isPendingValidation={isPendingValidation}
              paisIdAliado={infoAliado.paisId}
              aliado={infoAliado.nombre}
              email={infoAliado.email}
              phoneNumber={infoAliado.telefono}
              whatsAppNumber={infoAliado.whatsapp}
              webSite={infoAliado?.webSite}
              instagram={infoAliado.instagram}
              facebook={infoAliado.facebook}
              youtube={infoAliado.youtube}
              country={countryObject.countryId}
            />
          </div>
        </>
      ) : (
        <GalleryTemplate2
          t={t}
          showGallery={showGallery}
          setShowGallery={setShowGallery}
          imagenActualGal={imagenActualGal}
          videoActualGal={videoActualGal}
          imagenes={imagesGallery}
          videos={videosGallery}
          changeImage={changeImage}
          changeVideo={changeVideo}
          initialMedia={initialMediaGallery}
        />
      )}
    </div>
  );
}

