import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import './ModalRegistro.css';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { fade } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MyTextField from '../../../../componentes/formikInputs/MyTextField/MyTextField';
import MyDateSelect from '../../../../componentes/formikInputs/MyDateSelect/MyDateSelect';
import { userAuthError, userLoginSuccess } from '../../../../actions/userActions';
import Alert from '@material-ui/lab/Alert';
import { toast } from 'react-toastify';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { userHeaders } from '../../../../Utils/headerSetter';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../../../Utils/regexValidators';
import { useCountry } from '../../../../componentes/Hooks/CountryContext';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleButton from '../../../../componentes/GoogleButton/GoogleButton';
import Loading from '../../../../componentes/Loading/Loading';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: '1.6rem',
    marginBottom: '1rem',
    borderRadius: '10px',
    fontSize: '1rem',
    backgroundColor: '#8c50ff',
    color: 'white',
    transition: '0.2s',
    padding: '0.5rem 3rem',
    '&:hover': {
      backgroundColor: fade('#8c50ff', 0.9),
    },
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      backgroundColor: fade('#8c50ff', 0.5),
    },
  },
}));

export default function ModalRegistro({ handleClose, open, openLogin, titleModal, afterRegister }) {
  const { t } = useTranslation('global');
  const location = useLocation();
  const [error, setError] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [errorMsg, setErrorMsg] = useState('register.unknownError');
  const classes = useStyles();
  const dispatch = useDispatch();
  const mobile = useMediaQuery('(max-width:460px)');
  const { countryObject } = useCountry();
  const { languageId } = countryObject;

  const closeModal = () => {
    setLoadingGoogle(false);
    handleClose();
  };

  const handleRegistroExitoso = () => {
    toast.success(t('register.welcomeMsg'), {
      position: 'bottom-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const validationSchema = yup.object({
    nombre: yup.string().required(t('yup.required')),
    apellidos: yup.string().required(t('yup.required')),
    email: yup
      .string()
      .required(t('yup.required'))
      .test('is-valid-email', t('yup.email'), (value) => {
        return validateEmail(value);
      }),
    contrasena: yup
      .string()
      .required(t('yup.passwordRequired'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.{8,})/, t('yup.passwordRequirements')),
    contrasena2: yup
      .string()
      .required(t('yup.required'))
      .test('passwords-match', t('yup.passwordMatch'), function (value) {
        return this.parent.contrasena === value;
      }),
    fechaNacimiento: yup
      .string()
      .required(t('yup.required'))
      .test('DOB', t('yup.legalAge'), (value) => {
        return moment().diff(moment(value), 'years') >= 18;
      }),
  });

  const onFailure = (error) => {
    setLoadingGoogle(false);
    console.log('Login Failed:', error);
  };

  const googleResponse = async (response) => {
    setLoadingGoogle(false);
    // Check if a token was recieved and send it to our API:
    if (response?.access_token) {
      axios
        .post(
          `${
            process.env.REACT_APP_ENV === 'development'
              ? process.env.REACT_APP_API_LOCAL
              : process.env.REACT_APP_API_PROD
          }user/userAuth`,
          { token: response.access_token },
          userHeaders()
        )
        .then((res) => {
          const { token, user } = res.data;
          dispatch(userLoginSuccess(token, user));
          afterRegister(user);
          closeModal();
        })
        .catch((e) => {
          const { response } = e;
          if (response && response.data && response.data.msg) {
            if (response.data.msg) setErrorMsg(response.data.msg);
          }
          setError(true);
        });
    }
  };

  const isFormEmpty = (values) => {
    const keyValues = Object.values(values);
    return !keyValues.every((content) => !!content);
  };
  const continueWithGoogle = useGoogleLogin({
    onSuccess: googleResponse,
    onError: onFailure,
    onNonOAuthError: onFailure,
  });

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="sm"
      fullScreen={mobile}
      style={{ minWidth: '320px' }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={closeModal}
        style={{
          padding: '0.8rem',
          position: 'relative',
        }}
      >
        <p className="modal-reg-titulo">
          {titleModal
            ? titleModal
            : location.pathname === '/registro'
            ? t('modalSignup.title')
            : t('register.title')}
        </p>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          style={{
            position: 'absolute',
            right: '0',
            top: '0',
            marginRight: '0.5rem',
            marginTop: '0.4rem',
          }}
          className="modal-reg-titulo"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div className="modal-reg-wrp">
          {loadingGoogle && (
            <div className="model-reg-loading">
              <Loading helperText="" />
            </div>
          )}
          <div className="modal-reg-oauth-wrp">
            <div className="modal-reg-oauth-btn" onClick={() => setLoadingGoogle(true)}>
              <GoogleButton onClick={() => continueWithGoogle()} disabled={loadingGoogle} />
            </div>

            <hr style={{ width: '80%', margin: '0' }} />
            <p className="modal-reg-oauth-O" style={{ marginTop: '10px' }}>
              {t('register.optionalRegister')}
            </p>
          </div>
          <div>
            <Formik
              initialValues={{
                nombre: '',
                apellidos: '',
                email: '',
                contrasena: '',
                contrasena2: '',
                fechaNacimiento: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                axios
                  .post(
                    `${
                      process.env.REACT_APP_ENV === 'development'
                        ? process.env.REACT_APP_API_LOCAL
                        : process.env.REACT_APP_API_PROD
                    }user/agregar`,
                    {
                      infoUsuario: data,
                      languageId,
                    }
                  )
                  .then((res) => {
                    const { token, user } = res.data;
                    dispatch(userLoginSuccess(token, user));
                    setSubmitting(false);
                    handleRegistroExitoso();
                    if (!user.isVerified) {
                      setTimeout(() => {
                        toast.warn(t('register.verifyMsg'), {
                          position: 'bottom-right',
                          autoClose: false,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }, 800);
                    } else {
                      afterRegister(user);
                    }
                    closeModal();
                  })
                  .catch((e) => {
                    const { response } = e;

                    setTimeout(() => {
                      if (response && response.data && response.data.msg) {
                        if (response.data.msg) setErrorMsg(response.data.msg);
                      }
                      setSubmitting(false);
                      setError(true);
                      dispatch(userAuthError());
                    }, 1500);

                    setTimeout(() => {
                      setError(false);
                    }, 6000);
                  });
              }}
            >
              {({ isSubmitting, values, isValid }) => (
                <Form>
                  <Grid container>
                    {error ? (
                      <Grid item xs={12}>
                        <Alert severity="error" style={{ margin: '0 0.5rem 1.3rem 0.5rem' }}>
                          {t(errorMsg)}
                        </Alert>
                      </Grid>
                    ) : null}
                    <Grid item xs={12} md={6}>
                      <MyTextField name="nombre" type="input" placeholder={t('shared.names')} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MyTextField
                        name="apellidos"
                        type="input"
                        placeholder={t('shared.lastNames')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MyTextField name="email" type="input" placeholder="Email" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MyTextField
                        name="contrasena"
                        placeholder={t('shared.password')}
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MyTextField
                        name="contrasena2"
                        placeholder={t('shared.verifyPassword')}
                        type="password"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MyDateSelect name="fechaNacimiento" label={t('shared.birthday')} />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                      <Button
                        className={classes.button}
                        type="submit"
                        disabled={isSubmitting || error || isFormEmpty(values) || !isValid}
                        style={loadingGoogle ? { pointerEvents: 'none', opacity: '0.5' } : null}
                      >
                        {isSubmitting ? t('shared.loading') : t('register.title')}
                      </Button>
                      <p className="modal-reg-login-redirect">
                        {t('register.accountQuestion')}{' '}
                        <span
                          className="modal-reg-login-redirect-link"
                          onClick={() => {
                            openLogin();
                            closeModal();
                          }}
                        >
                          {t('shared.login')}
                        </span>
                      </p>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

ModalRegistro.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openLogin: PropTypes.func,
  afterRegister: PropTypes.func,
  titleModal: PropTypes.string,
};

ModalRegistro.defaultProps = {
  afterRegister: () => {},
  openLogin: () => {},
  titleModal: null,
};

