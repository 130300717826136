import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { userHeaders } from '../../Utils/headerSetter';
import Loading from '../../componentes/Loading/Loading';
import Navbar from '../HomePage/Navbar/Navbar';
import Footer from '../HomePage/Footer/Footer';
import { useTranslation } from 'react-i18next';
import './SiteDocuments.css';

const SiteDocuments = () => {
  document.title = 'Planodi | Documents';
  const [loading, setLoading] = useState(true);
  const [loadingContent, setLoadingContent] = useState(false);
  const [sections, setSections] = useState([]);
  const [data, setData] = useState({});
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { t, i18n } = useTranslation('global');
  const navigate = useNavigate();

  const language = i18n.language;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${
            process.env.REACT_APP_ENV === 'development'
              ? process.env.REACT_APP_API_LOCAL
              : process.env.REACT_APP_API_PROD
          }aliado/documents/sections`,
          {
            language: language,
          },
          userHeaders()
        );

        setSections(response?.data?.res?.siteDocuments);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, language]);

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        window.history.replaceState(
          null,
          document.title,
          `${t('routes.documents')}?id=termsAndConditions`
        );
      }
      try {
        setLoadingContent(true);
        setTimeout(async () => {
          await axios
            .post(
              `${
                process.env.REACT_APP_ENV === 'development'
                  ? process.env.REACT_APP_API_LOCAL
                  : process.env.REACT_APP_API_PROD
              }aliado/documents`,
              {
                section: id ? id : 'termsAndConditions',
                language: language,
              },
              userHeaders()
            )
            .then((data) => {
              setData(data?.data?.res?.siteDocuments);
              setLoadingContent(false);
            })
            .catch(() => {
              setLoadingContent(false);
              navigate(t('routes.home'));
            });
        }, 400);
      } catch (error) {
        setLoadingContent(false);
      }
    };

    fetchData();
  }, [id, language]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar />
      <section className="site-docs-section-class">
        <div className="site-docs-section-container">
          <ul className="site-docs-section-ul">
            {sections &&
              sections?.map((ic, index) => (
                <Link
                  to={{
                    pathname: t('routes.documents'),
                    search: `id=${ic.section}`,
                  }}
                  key={index}
                  style={{ textDecoration: 'none' }}
                >
                  <li
                    className={`site-docs-section-li ${
                      ic.section === id || (!id && ic.section === 'termsAndConditions')
                        ? 'site-docs-section-selected-link '
                        : ''
                    }`}
                  >
                    <p className="site-docs-section-text">{ic.title}</p>
                  </li>
                </Link>
              ))}
          </ul>
        </div>
      </section>

      <div className="site-docs-document-container">
        {loadingContent ? (
          <Loading />
        ) : (
          <>
            <h1
              className="site-docs-document-title-text"
              dangerouslySetInnerHTML={{
                __html: data?.title?.replace(/(?:\r\n|\r|\n)/g, '<br />'),
              }}
            />
            {data?.title ? <div className="site-docs-document-rectangle-div" /> : null}
          </>
        )}
      </div>

      <div className="site-docs-companyInfo site-docs-document-custom-container">
        {loadingContent ? (
          <Loading />
        ) : (
          <p
            dangerouslySetInnerHTML={{
              __html: data?.content?.replace(/(?:\r\n|\r|\n)/g, '<br />'),
            }}
          />
        )}
      </div>

      <Footer />
    </div>
  );
};

export default SiteDocuments;

