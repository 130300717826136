import './RichText.css';
import { useCallback, useMemo } from 'react';
import { Editable, Slate, withReact } from 'slate-react';
import { createEditor } from 'slate';
import RenderLeaf from './RenderLeaf';
import RenderElement from './RenderElement';
import PropTypes from 'prop-types';

const RichTextVisualizer = ({ content }) => {
  const renderElement = useCallback((props) => <RenderElement {...props} />, []);
  const renderLeaf = useCallback((props) => <RenderLeaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);

  return (
    <Slate editor={editor} initialValue={content}>
      <Editable
        className="rich-text-editor-styling-preview"
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly
        placeholder="-"
      />
    </Slate>
  );
};

RichTextVisualizer.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RichTextVisualizer;

