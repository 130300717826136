export default function Paywall({ basic, pro, premium, free, plan }) {
  switch (plan.tier) {
    case 'premium':
      return premium;
    case 'pro':
      return pro;
    case 'basic':
      return basic;
    default:
      return free;
  }
}

